@media (max-width: 1199px) and (min-width: 992px) {
  div.itemAuthorLatest .items > .item:nth-child(3n+1),
  div.itemRelated .items > .item:nth-child(3n+1) {
    clear: both;
  }

  .tz-social .tz-item .tz-info .tz-title {
    font-size: 36px;
  }

  div.k2ItemsBlock.tz_ziczac .tz-table-cell.tz-content {
    padding: 15px;
  }

  div.k2ItemsBlock.tz_ziczac .tz-table-cell .moduleItemReadMore {
    padding: 10px 30px;
  }

  .tz-slide-home-2.owl-carousel .owl-item img {
    max-width: 350px;
  }

  .tz-slide-home-2 {
    &.owl-carousel .owl-item img {
      max-width: 100%;

    }
    .tz-table-cell {
      &:first-child {
        width: 40%;
      }
      &:last-child {
        width: 60%;
        padding-left: 30px;
        padding-right: 50px;
      }
    }
    .tz-title {
      font-size: 40px;
    }
    .tz-image {
      padding-right: 0;
    }
    .tz-date {
      right: 50px;
      font-size: 200px;
      span {
        margin: 0;
        line-height: 210px;
      }
    }
  }

  .tz-artists {
    .tz-title {
      font-size: 24px;
    }
    .tz-tag {
      font-size: 18px;
    }
  }

  .tz_slide .module-title {
    font-size: 150px;
    line-height: 105px;
  }

  .hikashop_products_listing_main .img_title .hikashop_subcontainer {
    padding: 40px 10px 23px;
  }

  .hikashop_products_listing_main .img_title .hikashop_subcontainer .hikashop_product_name a {
    letter-spacing: 1px;
  }

}