@charset "UTF-8";
@iconPath: "../../../fonts";
@font-face {
  font-family: "linea-arrows-10";
  src: url("@{iconPath}/LineaArrow10/linea-arrows-10.eot");
  src: url("@{iconPath}/LineaArrow10/linea-arrows-10.eot?#iefix") format("embedded-opentype"),
  url("@{iconPath}/LineaArrow10/linea-arrows-10.woff") format("woff"),
  url("@{iconPath}/LineaArrow10/linea-arrows-10.ttf") format("truetype"),
  url("@{iconPath}/LineaArrow10/linea-arrows-10.svg#linea-arrows-10") format("svg");
  font-weight: normal;
  font-style: normal;

}

[linea-data-icon]:before {
  font-family: "linea-arrows-10" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="linea-icon-"]:before,
[class*=" linea-icon-"]:before {
  font-family: "linea-arrows-10" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.linea-icon-arrows-anticlockwise:before {
  content: "\e000";
}

.linea-icon-arrows-anticlockwise-dashed:before {
  content: "\e001";
}

.linea-icon-arrows-button-down:before {
  content: "\e002";
}

.linea-icon-arrows-button-off:before {
  content: "\e003";
}

.linea-icon-arrows-button-on:before {
  content: "\e004";
}

.linea-icon-arrows-button-up:before {
  content: "\e005";
}

.linea-icon-arrows-check:before {
  content: "\e006";
}

.linea-icon-arrows-circle-check:before {
  content: "\e007";
}

.linea-icon-arrows-circle-down:before {
  content: "\e008";
}

.linea-icon-arrows-circle-downleft:before {
  content: "\e009";
}

.linea-icon-arrows-circle-downright:before {
  content: "\e00a";
}

.linea-icon-arrows-circle-left:before {
  content: "\e00b";
}

.linea-icon-arrows-circle-minus:before {
  content: "\e00c";
}

.linea-icon-arrows-circle-plus:before {
  content: "\e00d";
}

.linea-icon-arrows-circle-remove:before {
  content: "\e00e";
}

.linea-icon-arrows-circle-right:before {
  content: "\e00f";
}

.linea-icon-arrows-circle-up:before {
  content: "\e010";
}

.linea-icon-arrows-circle-upleft:before {
  content: "\e011";
}

.linea-icon-arrows-circle-upright:before {
  content: "\e012";
}

.linea-icon-arrows-clockwise:before {
  content: "\e013";
}

.linea-icon-arrows-clockwise-dashed:before {
  content: "\e014";
}

.linea-icon-arrows-compress:before {
  content: "\e015";
}

.linea-icon-arrows-deny:before {
  content: "\e016";
}

.linea-icon-arrows-diagonal:before {
  content: "\e017";
}

.linea-icon-arrows-diagonal2:before {
  content: "\e018";
}

.linea-icon-arrows-down:before {
  content: "\e019";
}

.linea-icon-arrows-down-double:before {
  content: "\e01a";
}

.linea-icon-arrows-downleft:before {
  content: "\e01b";
}

.linea-icon-arrows-downright:before {
  content: "\e01c";
}

.linea-icon-arrows-drag-down:before {
  content: "\e01d";
}

.linea-icon-arrows-drag-down-dashed:before {
  content: "\e01e";
}

.linea-icon-arrows-drag-horiz:before {
  content: "\e01f";
}

.linea-icon-arrows-drag-left:before {
  content: "\e020";
}

.linea-icon-arrows-drag-left-dashed:before {
  content: "\e021";
}

.linea-icon-arrows-drag-right:before {
  content: "\e022";
}

.linea-icon-arrows-drag-right-dashed:before {
  content: "\e023";
}

.linea-icon-arrows-drag-up:before {
  content: "\e024";
}

.linea-icon-arrows-drag-up-dashed:before {
  content: "\e025";
}

.linea-icon-arrows-drag-vert:before {
  content: "\e026";
}

.linea-icon-arrows-exclamation:before {
  content: "\e027";
}

.linea-icon-arrows-expand:before {
  content: "\e028";
}

.linea-icon-arrows-expand-diagonal1:before {
  content: "\e029";
}

.linea-icon-arrows-expand-horizontal1:before {
  content: "\e02a";
}

.linea-icon-arrows-expand-vertical1:before {
  content: "\e02b";
}

.linea-icon-arrows-fit-horizontal:before {
  content: "\e02c";
}

.linea-icon-arrows-fit-vertical:before {
  content: "\e02d";
}

.linea-icon-arrows-glide:before {
  content: "\e02e";
}

.linea-icon-arrows-glide-horizontal:before {
  content: "\e02f";
}

.linea-icon-arrows-glide-vertical:before {
  content: "\e030";
}

.linea-icon-arrows-hamburger1:before {
  content: "\e031";
}

.linea-icon-arrows-hamburger-2:before {
  content: "\e032";
}

.linea-icon-arrows-horizontal:before {
  content: "\e033";
}

.linea-icon-arrows-info:before {
  content: "\e034";
}

.linea-icon-arrows-keyboard-alt:before {
  content: "\e035";
}

.linea-icon-arrows-keyboard-cmd:before {
  content: "\e036";
}

.linea-icon-arrows-keyboard-delete:before {
  content: "\e037";
}

.linea-icon-arrows-keyboard-down:before {
  content: "\e038";
}

.linea-icon-arrows-keyboard-left:before {
  content: "\e039";
}

.linea-icon-arrows-keyboard-return:before {
  content: "\e03a";
}

.linea-icon-arrows-keyboard-right:before {
  content: "\e03b";
}

.linea-icon-arrows-keyboard-shift:before {
  content: "\e03c";
}

.linea-icon-arrows-keyboard-tab:before {
  content: "\e03d";
}

.linea-icon-arrows-keyboard-up:before {
  content: "\e03e";
}

.linea-icon-arrows-left:before {
  content: "\e03f";
}

.linea-icon-arrows-left-double-32:before {
  content: "\e040";
}

.linea-icon-arrows-minus:before {
  content: "\e041";
}

.linea-icon-arrows-move:before {
  content: "\e042";
}

.linea-icon-arrows-move2:before {
  content: "\e043";
}

.linea-icon-arrows-move-bottom:before {
  content: "\e044";
}

.linea-icon-arrows-move-left:before {
  content: "\e045";
}

.linea-icon-arrows-move-right:before {
  content: "\e046";
}

.linea-icon-arrows-move-top:before {
  content: "\e047";
}

.linea-icon-arrows-plus:before {
  content: "\e048";
}

.linea-icon-arrows-question:before {
  content: "\e049";
}

.linea-icon-arrows-remove:before {
  content: "\e04a";
}

.linea-icon-arrows-right:before {
  content: "\e04b";
}

.linea-icon-arrows-right-double:before {
  content: "\e04c";
}

.linea-icon-arrows-rotate:before {
  content: "\e04d";
}

.linea-icon-arrows-rotate-anti:before {
  content: "\e04e";
}

.linea-icon-arrows-rotate-anti-dashed:before {
  content: "\e04f";
}

.linea-icon-arrows-rotate-dashed:before {
  content: "\e050";
}

.linea-icon-arrows-shrink:before {
  content: "\e051";
}

.linea-icon-arrows-shrink-diagonal1:before {
  content: "\e052";
}

.linea-icon-arrows-shrink-diagonal2:before {
  content: "\e053";
}

.linea-icon-arrows-shrink-horizonal2:before {
  content: "\e054";
}

.linea-icon-arrows-shrink-horizontal1:before {
  content: "\e055";
}

.linea-icon-arrows-shrink-vertical1:before {
  content: "\e056";
}

.linea-icon-arrows-shrink-vertical2:before {
  content: "\e057";
}

.linea-icon-arrows-sign-down:before {
  content: "\e058";
}

.linea-icon-arrows-sign-left:before {
  content: "\e059";
}

.linea-icon-arrows-sign-right:before {
  content: "\e05a";
}

.linea-icon-arrows-sign-up:before {
  content: "\e05b";
}

.linea-icon-arrows-slide-down1:before {
  content: "\e05c";
}

.linea-icon-arrows-slide-down2:before {
  content: "\e05d";
}

.linea-icon-arrows-slide-left1:before {
  content: "\e05e";
}

.linea-icon-arrows-slide-left2:before {
  content: "\e05f";
}

.linea-icon-arrows-slide-right1:before {
  content: "\e060";
}

.linea-icon-arrows-slide-right2:before {
  content: "\e061";
}

.linea-icon-arrows-slide-up1:before {
  content: "\e062";
}

.linea-icon-arrows-slide-up2:before {
  content: "\e063";
}

.linea-icon-arrows-slim-down:before {
  content: "\e064";
}

.linea-icon-arrows-slim-down-dashed:before {
  content: "\e065";
}

.linea-icon-arrows-slim-left:before {
  content: "\e066";
}

.linea-icon-arrows-slim-left-dashed:before {
  content: "\e067";
}

.linea-icon-arrows-slim-right:before {
  content: "\e068";
}

.linea-icon-arrows-slim-right-dashed:before {
  content: "\e069";
}

.linea-icon-arrows-slim-up:before {
  content: "\e06a";
}

.linea-icon-arrows-slim-up-dashed:before {
  content: "\e06b";
}

.linea-icon-arrows-square-check:before {
  content: "\e06c";
}

.linea-icon-arrows-square-down:before {
  content: "\e06d";
}

.linea-icon-arrows-square-downleft:before {
  content: "\e06e";
}

.linea-icon-arrows-square-downright:before {
  content: "\e06f";
}

.linea-icon-arrows-square-left:before {
  content: "\e070";
}

.linea-icon-arrows-square-minus:before {
  content: "\e071";
}

.linea-icon-arrows-square-plus:before {
  content: "\e072";
}

.linea-icon-arrows-square-remove:before {
  content: "\e073";
}

.linea-icon-arrows-square-right:before {
  content: "\e074";
}

.linea-icon-arrows-square-up:before {
  content: "\e075";
}

.linea-icon-arrows-square-upleft:before {
  content: "\e076";
}

.linea-icon-arrows-square-upright:before {
  content: "\e077";
}

.linea-icon-arrows-squares:before {
  content: "\e078";
}

.linea-icon-arrows-stretch-diagonal1:before {
  content: "\e079";
}

.linea-icon-arrows-stretch-diagonal2:before {
  content: "\e07a";
}

.linea-icon-arrows-stretch-diagonal3:before {
  content: "\e07b";
}

.linea-icon-arrows-stretch-diagonal4:before {
  content: "\e07c";
}

.linea-icon-arrows-stretch-horizontal1:before {
  content: "\e07d";
}

.linea-icon-arrows-stretch-horizontal2:before {
  content: "\e07e";
}

.linea-icon-arrows-stretch-vertical1:before {
  content: "\e07f";
}

.linea-icon-arrows-stretch-vertical2:before {
  content: "\e080";
}

.linea-icon-arrows-switch-horizontal:before {
  content: "\e081";
}

.linea-icon-arrows-switch-vertical:before {
  content: "\e082";
}

.linea-icon-arrows-up:before {
  content: "\e083";
}

.linea-icon-arrows-up-double-33:before {
  content: "\e084";
}

.linea-icon-arrows-upleft:before {
  content: "\e085";
}

.linea-icon-arrows-upright:before {
  content: "\e086";
}

.linea-icon-arrows-vertical:before {
  content: "\e087";
}


@font-face {
  font-family: "linea-basic-10";
  src:url("@{iconPath}/LineaBasic10/linea-basic-10.eot");
  src:url("@{iconPath}/LineaBasic10/linea-basic-10.eot?#iefix") format("embedded-opentype"),
  url("@{iconPath}/LineaBasic10/linea-basic-10.woff") format("woff"),
  url("@{iconPath}/LineaBasic10/linea-basic-10.ttf") format("truetype"),
  url("@{iconPath}/LineaBasic10/linea-basic-10.svg#linea-basic-10") format("svg");
  font-weight: normal;
  font-style: normal;

}

[linea-data-icon-basic]:before {
  font-family: "linea-basic-10" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="linea-icon-basic-"]:before,
[class*=" linea-icon-basic-"]:before {
  font-family: "linea-basic-10" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.linea-icon-basic-accelerator:before {
  content: "a";
}
.linea-icon-basic-alarm:before {
  content: "b";
}
.linea-icon-basic-anchor:before {
  content: "c";
}
.linea-icon-basic-anticlockwise:before {
  content: "d";
}
.linea-icon-basic-archive:before {
  content: "e";
}
.linea-icon-basic-archive-full:before {
  content: "f";
}
.linea-icon-basic-ban:before {
  content: "g";
}
.linea-icon-basic-battery-charge:before {
  content: "h";
}
.linea-icon-basic-battery-empty:before {
  content: "i";
}
.linea-icon-basic-battery-full:before {
  content: "j";
}
.linea-icon-basic-battery-half:before {
  content: "k";
}
.linea-icon-basic-bolt:before {
  content: "l";
}
.linea-icon-basic-book:before {
  content: "m";
}
.linea-icon-basic-book-pen:before {
  content: "n";
}
.linea-icon-basic-book-pencil:before {
  content: "o";
}
.linea-icon-basic-bookmark:before {
  content: "p";
}
.linea-icon-basic-calculator:before {
  content: "q";
}
.linea-icon-basic-calendar:before {
  content: "r";
}
.linea-icon-basic-cards-diamonds:before {
  content: "s";
}
.linea-icon-basic-cards-hearts:before {
  content: "t";
}
.linea-icon-basic-case:before {
  content: "u";
}
.linea-icon-basic-chronometer:before {
  content: "v";
}
.linea-icon-basic-clessidre:before {
  content: "w";
}
.linea-icon-basic-clock:before {
  content: "x";
}
.linea-icon-basic-clockwise:before {
  content: "y";
}
.linea-icon-basic-cloud:before {
  content: "z";
}
.linea-icon-basic-clubs:before {
  content: "A";
}
.linea-icon-basic-compass:before {
  content: "B";
}
.linea-icon-basic-cup:before {
  content: "C";
}
.linea-icon-basic-diamonds:before {
  content: "D";
}
.linea-icon-basic-display:before {
  content: "E";
}
.linea-icon-basic-download:before {
  content: "F";
}
.linea-icon-basic-exclamation:before {
  content: "G";
}
.linea-icon-basic-eye:before {
  content: "H";
}
.linea-icon-basic-eye-closed:before {
  content: "I";
}
.linea-icon-basic-female:before {
  content: "J";
}
.linea-icon-basic-flag1:before {
  content: "K";
}
.linea-icon-basic-flag2:before {
  content: "L";
}
.linea-icon-basic-floppydisk:before {
  content: "M";
}
.linea-icon-basic-folder:before {
  content: "N";
}
.linea-icon-basic-folder-multiple:before {
  content: "O";
}
.linea-icon-basic-gear:before {
  content: "P";
}
.linea-icon-basic-geolocalize-01:before {
  content: "Q";
}
.linea-icon-basic-geolocalize-05:before {
  content: "R";
}
.linea-icon-basic-globe:before {
  content: "S";
}
.linea-icon-basic-gunsight:before {
  content: "T";
}
.linea-icon-basic-hammer:before {
  content: "U";
}
.linea-icon-basic-headset:before {
  content: "V";
}
.linea-icon-basic-heart:before {
  content: "W";
}
.linea-icon-basic-heart-broken:before {
  content: "X";
}
.linea-icon-basic-helm:before {
  content: "Y";
}
.linea-icon-basic-home:before {
  content: "Z";
}
.linea-icon-basic-info:before {
  content: "0";
}
.linea-icon-basic-ipod:before {
  content: "1";
}
.linea-icon-basic-joypad:before {
  content: "2";
}
.linea-icon-basic-key:before {
  content: "3";
}
.linea-icon-basic-keyboard:before {
  content: "4";
}
.linea-icon-basic-laptop:before {
  content: "5";
}
.linea-icon-basic-life-buoy:before {
  content: "6";
}
.linea-icon-basic-lightbulb:before {
  content: "7";
}
.linea-icon-basic-link:before {
  content: "8";
}
.linea-icon-basic-lock:before {
  content: "9";
}
.linea-icon-basic-lock-open:before {
  content: "!";
}
.linea-icon-basic-magic-mouse:before {
  content: "\"";
}
.linea-icon-basic-magnifier:before {
  content: "#";
}
.linea-icon-basic-magnifier-minus:before {
  content: "$";
}
.linea-icon-basic-magnifier-plus:before {
  content: "%";
}
.linea-icon-basic-mail:before {
  content: "&";
}
.linea-icon-basic-mail-multiple:before {
  content: "'";
}
.linea-icon-basic-mail-open:before {
  content: "(";
}
.linea-icon-basic-mail-open-text:before {
  content: ")";
}
.linea-icon-basic-male:before {
  content: "*";
}
.linea-icon-basic-map:before {
  content: "+";
}
.linea-icon-basic-message:before {
  content: ",";
}
.linea-icon-basic-message-multiple:before {
  content: "-";
}
.linea-icon-basic-message-txt:before {
  content: ".";
}
.linea-icon-basic-mixer2:before {
  content: "/";
}
.linea-icon-basic-mouse:before {
  content: ":";
}
.linea-icon-basic-notebook:before {
  content: ";";
}
.linea-icon-basic-notebook-pen:before {
  content: "<";
}
.linea-icon-basic-notebook-pencil:before {
  content: "=";
}
.linea-icon-basic-paperplane:before {
  content: ">";
}
.linea-icon-basic-pencil-ruler:before {
  content: "?";
}
.linea-icon-basic-pencil-ruler-pen:before {
  content: "@";
}
.linea-icon-basic-photo:before {
  content: "[";
}
.linea-icon-basic-picture:before {
  content: "]";
}
.linea-icon-basic-picture-multiple:before {
  content: "^";
}
.linea-icon-basic-pin1:before {
  content: "_";
}
.linea-icon-basic-pin2:before {
  content: "`";
}
.linea-icon-basic-postcard:before {
  content: "{";
}
.linea-icon-basic-postcard-multiple:before {
  content: "|";
}
.linea-icon-basic-printer:before {
  content: "}";
}
.linea-icon-basic-question:before {
  content: "~";
}
.linea-icon-basic-rss:before {
  content: "\\";
}
.linea-icon-basic-server:before {
  content: "\e000";
}
.linea-icon-basic-server2:before {
  content: "\e001";
}
.linea-icon-basic-server-cloud:before {
  content: "\e002";
}
.linea-icon-basic-server-download:before {
  content: "\e003";
}
.linea-icon-basic-server-upload:before {
  content: "\e004";
}
.linea-icon-basic-settings:before {
  content: "\e005";
}
.linea-icon-basic-share:before {
  content: "\e006";
}
.linea-icon-basic-sheet:before {
  content: "\e007";
}
.linea-icon-basic-sheet-multiple:before {
  content: "\e008";
}
.linea-icon-basic-sheet-pen:before {
  content: "\e009";
}
.linea-icon-basic-sheet-pencil:before {
  content: "\e00a";
}
.linea-icon-basic-sheet-txt:before {
  content: "\e00b";
}
.linea-icon-basic-signs:before {
  content: "\e00c";
}
.linea-icon-basic-smartphone:before {
  content: "\e00d";
}
.linea-icon-basic-spades:before {
  content: "\e00e";
}
.linea-icon-basic-spread:before {
  content: "\e00f";
}
.linea-icon-basic-spread-bookmark:before {
  content: "\e010";
}
.linea-icon-basic-spread-text:before {
  content: "\e011";
}
.linea-icon-basic-spread-text-bookmark:before {
  content: "\e012";
}
.linea-icon-basic-star:before {
  content: "\e013";
}
.linea-icon-basic-tablet:before {
  content: "\e014";
}
.linea-icon-basic-target:before {
  content: "\e015";
}
.linea-icon-basic-todo:before {
  content: "\e016";
}
.linea-icon-basic-todo-pen:before {
  content: "\e017";
}
.linea-icon-basic-todo-pencil:before {
  content: "\e018";
}
.linea-icon-basic-todo-txt:before {
  content: "\e019";
}
.linea-icon-basic-todolist-pen:before {
  content: "\e01a";
}
.linea-icon-basic-todolist-pencil:before {
  content: "\e01b";
}
.linea-icon-basic-trashcan:before {
  content: "\e01c";
}
.linea-icon-basic-trashcan-full:before {
  content: "\e01d";
}
.linea-icon-basic-trashcan-refresh:before {
  content: "\e01e";
}
.linea-icon-basic-trashcan-remove:before {
  content: "\e01f";
}
.linea-icon-basic-upload:before {
  content: "\e020";
}
.linea-icon-basic-usb:before {
  content: "\e021";
}
.linea-icon-basic-video:before {
  content: "\e022";
}
.linea-icon-basic-watch:before {
  content: "\e023";
}
.linea-icon-basic-webpage:before {
  content: "\e024";
}
.linea-icon-basic-webpage-img-txt:before {
  content: "\e025";
}
.linea-icon-basic-webpage-multiple:before {
  content: "\e026";
}
.linea-icon-basic-webpage-txt:before {
  content: "\e027";
}
.linea-icon-basic-world:before {
  content: "\e028";
}



@charset "UTF-8";

@font-face {
  font-family: "linea-music-10";
  src:url("@{iconPath}/LineaMusic10/linea-music-10.eot");
  src:url("@{iconPath}/LineaMusic10/linea-music-10.eot?#iefix") format("embedded-opentype"),
  url("@{iconPath}/LineaMusic10/linea-music-10.woff") format("woff"),
  url("@{iconPath}/LineaMusic10/linea-music-10.ttf") format("truetype"),
  url("@{iconPath}/LineaMusic10/linea-music-10.svg#linea-music-10") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "linea-music-10" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="linea-music-icon"]:before,
[class*=" linea-music-icon"]:before {
  font-family: "linea-music-10" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.linea-music-icon-music-beginning-button:before {
  content: "a";
}
.linea-music-icon-music-bell:before {
  content: "b";
}
.linea-music-icon-music-cd:before {
  content: "c";
}
.linea-music-icon-music-diapason:before {
  content: "d";
}
.linea-music-icon-music-eject-button:before {
  content: "e";
}
.linea-music-icon-music-end-button:before {
  content: "f";
}
.linea-music-icon-music-fastforward-button:before {
  content: "g";
}
.linea-music-icon-music-headphones:before {
  content: "h";
}
.linea-music-icon-music-ipod:before {
  content: "i";
}
.linea-music-icon-music-loudspeaker:before {
  content: "j";
}
.linea-music-icon-music-microphone:before {
  content: "k";
}
.linea-music-icon-music-microphone-old:before {
  content: "l";
}
.linea-music-icon-music-mixer:before {
  content: "m";
}
.linea-music-icon-music-mute:before {
  content: "n";
}
.linea-music-icon-music-note-multiple:before {
  content: "o";
}
.linea-music-icon-music-note-single:before {
  content: "p";
}
.linea-music-icon-music-pause-button:before {
  content: "q";
}
.linea-music-icon-music-play-button:before {
  content: "r";
}
.linea-music-icon-music-playlist:before {
  content: "s";
}
.linea-music-icon-music-radio-ghettoblaster:before {
  content: "t";
}
.linea-music-icon-music-radio-portable:before {
  content: "u";
}
.linea-music-icon-music-record:before {
  content: "v";
}
.linea-music-icon-music-recordplayer:before {
  content: "w";
}
.linea-music-icon-music-repeat-button:before {
  content: "x";
}
.linea-music-icon-music-rewind-button:before {
  content: "y";
}
.linea-music-icon-music-shuffle-button:before {
  content: "z";
}
.linea-music-icon-music-stop-button:before {
  content: "A";
}
.linea-music-icon-music-tape:before {
  content: "B";
}
.linea-music-icon-music-volume-down:before {
  content: "C";
}
.linea-music-icon-music-volume-up:before {
  content: "D";
}

@font-face {
  font-family: "linea-ecommerce-10";
  src:url("@{iconPath}/LineaEcommerce/linea-ecommerce-10.eot");
  src:url("@{iconPath}/LineaEcommerce/linea-ecommerce-10.eot?#iefix") format("embedded-opentype"),
  url("@{iconPath}/LineaEcommerce/linea-ecommerce-10.woff") format("woff"),
  url("@{iconPath}/LineaEcommerce/linea-ecommerce-10.ttf") format("truetype"),
  url("@{iconPath}/LineaEcommerce/linea-ecommerce-10.svg#linea-ecommerce-10") format("svg");
  font-weight: normal;
  font-style: normal;

}

[class^="linea-icon-ecommerce-"]:before,
[class*=" linea-icon-ecommerce-"]:before {
  font-family: "linea-ecommerce-10" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.linea-icon-ecommerce-bag:before {
  content: "a";
}
.linea-icon-ecommerce-bag-check:before {
  content: "b";
}
.linea-icon-ecommerce-bag-cloud:before {
  content: "c";
}
.linea-icon-ecommerce-bag-download:before {
  content: "d";
}
.linea-icon-ecommerce-bag-minus:before {
  content: "e";
}
.linea-icon-ecommerce-bag-plus:before {
  content: "f";
}
.linea-icon-ecommerce-bag-refresh:before {
  content: "g";
}
.linea-icon-ecommerce-bag-remove:before {
  content: "h";
}
.linea-icon-ecommerce-bag-search:before {
  content: "i";
}
.linea-icon-ecommerce-bag-upload:before {
  content: "j";
}
.linea-icon-ecommerce-banknote:before {
  content: "k";
}
.linea-icon-ecommerce-banknotes:before {
  content: "l";
}
.linea-icon-ecommerce-basket:before {
  content: "m";
}
.linea-icon-ecommerce-basket-check:before {
  content: "n";
}
.linea-icon-ecommerce-basket-cloud:before {
  content: "o";
}
.linea-icon-ecommerce-basket-download:before {
  content: "p";
}
.linea-icon-ecommerce-basket-minus:before {
  content: "q";
}
.linea-icon-ecommerce-basket-plus:before {
  content: "r";
}
.linea-icon-ecommerce-basket-refresh:before {
  content: "s";
}
.linea-icon-ecommerce-basket-remove:before {
  content: "t";
}
.linea-icon-ecommerce-basket-search:before {
  content: "u";
}
.linea-icon-ecommerce-basket-upload:before {
  content: "v";
}
.linea-icon-ecommerce-bath:before {
  content: "w";
}
.linea-icon-ecommerce-cart:before {
  content: "x";
}
.linea-icon-ecommerce-cart-check:before {
  content: "y";
}
.linea-icon-ecommerce-cart-cloud:before {
  content: "z";
}
.linea-icon-ecommerce-cart-content:before {
  content: "A";
}
.linea-icon-ecommerce-cart-download:before {
  content: "B";
}
.linea-icon-ecommerce-cart-minus:before {
  content: "C";
}
.linea-icon-ecommerce-cart-plus:before {
  content: "D";
}
.linea-icon-ecommerce-cart-refresh:before {
  content: "E";
}
.linea-icon-ecommerce-cart-remove:before {
  content: "F";
}
.linea-icon-ecommerce-cart-search:before {
  content: "G";
}
.linea-icon-ecommerce-cart-upload:before {
  content: "H";
}
.linea-icon-ecommerce-cent:before {
  content: "I";
}
.linea-icon-ecommerce-colon:before {
  content: "J";
}
.linea-icon-ecommerce-creditcard:before {
  content: "K";
}
.linea-icon-ecommerce-diamond:before {
  content: "L";
}
.linea-icon-ecommerce-dollar:before {
  content: "M";
}
.linea-icon-ecommerce-euro:before {
  content: "N";
}
.linea-icon-ecommerce-franc:before {
  content: "O";
}
.linea-icon-ecommerce-gift:before {
  content: "P";
}
.linea-icon-ecommerce-graph1:before {
  content: "Q";
}
.linea-icon-ecommerce-graph2:before {
  content: "R";
}
.linea-icon-ecommerce-graph3:before {
  content: "S";
}
.linea-icon-ecommerce-graph-decrease:before {
  content: "T";
}
.linea-icon-ecommerce-graph-increase:before {
  content: "U";
}
.linea-icon-ecommerce-guarani:before {
  content: "V";
}
.linea-icon-ecommerce-kips:before {
  content: "W";
}
.linea-icon-ecommerce-lira:before {
  content: "X";
}
.linea-icon-ecommerce-megaphone:before {
  content: "Y";
}
.linea-icon-ecommerce-money:before {
  content: "Z";
}
.linea-icon-ecommerce-naira:before {
  content: "0";
}
.linea-icon-ecommerce-pesos:before {
  content: "1";
}
.linea-icon-ecommerce-pound:before {
  content: "2";
}
.linea-icon-ecommerce-receipt:before {
  content: "3";
}
.linea-icon-ecommerce-receipt-bath:before {
  content: "4";
}
.linea-icon-ecommerce-receipt-cent:before {
  content: "5";
}
.linea-icon-ecommerce-receipt-dollar:before {
  content: "6";
}
.linea-icon-ecommerce-receipt-euro:before {
  content: "7";
}
.linea-icon-ecommerce-receipt-franc:before {
  content: "8";
}
.linea-icon-ecommerce-receipt-guarani:before {
  content: "9";
}
.linea-icon-ecommerce-receipt-kips:before {
  content: "!";
}
.linea-icon-ecommerce-receipt-lira:before {
  content: "\"";
}
.linea-icon-ecommerce-receipt-naira:before {
  content: "#";
}
.linea-icon-ecommerce-receipt-pesos:before {
  content: "$";
}
.linea-icon-ecommerce-receipt-pound:before {
  content: "%";
}
.linea-icon-ecommerce-receipt-rublo:before {
  content: "&";
}
.linea-icon-ecommerce-receipt-rupee:before {
  content: "'";
}
.linea-icon-ecommerce-receipt-tugrik:before {
  content: "(";
}
.linea-icon-ecommerce-receipt-won:before {
  content: ")";
}
.linea-icon-ecommerce-receipt-yen:before {
  content: "*";
}
.linea-icon-ecommerce-receipt-yen2:before {
  content: "+";
}
.linea-icon-ecommerce-recept-colon:before {
  content: ",";
}
.linea-icon-ecommerce-rublo:before {
  content: "-";
}
.linea-icon-ecommerce-rupee:before {
  content: ".";
}
.linea-icon-ecommerce-safe:before {
  content: "/";
}
.linea-icon-ecommerce-sale:before {
  content: ":";
}
.linea-icon-ecommerce-sales:before {
  content: ";";
}
.linea-icon-ecommerce-ticket:before {
  content: "<";
}
.linea-icon-ecommerce-tugriks:before {
  content: "=";
}
.linea-icon-ecommerce-wallet:before {
  content: ">";
}
.linea-icon-ecommerce-won:before {
  content: "?";
}
.linea-icon-ecommerce-yen:before {
  content: "@";
}
.linea-icon-ecommerce-yen2:before {
  content: "[";
}
