@media (max-width: 767px) {
  .tz-next-show .tz-left,
  .tz-next-show .tz-right {
    display: block;
    text-align: left;
  }

  .tz-ticket-event .tz-table-cell {
    padding: 0 5px !important;
  }

  .tz-ticket-event .tz-title {
    font-size: 36px;
  }

  .tz-ticket-event .tz_event {
    font-size: 14px;
  }

  div.k2ItemsBlock.tz_ziczac ul li {
    width: 100%;
    padding: 0 !important;
  }

  div.k2ItemsBlock.tz_ziczac .tz-table-cell .moduleItemTitle {
    font-size: 18px;
  }

  .intro-block .tz-location,
  div.k2ItemsBlock.tz_ziczac .tz-table-cell .tz-information * {
    font-size: 14px;
  }

  .intro-block {
    padding-left: 15px;
    padding-right: 15px;
    .tz-title {
      font-size: 36px;
    }
  }

  .tz-module.tz_ziczac .module-title {
    font-size: 48px !important;
  }

  .parallax .title {
    font-size: 60px;
    margin-top: -30px;
  }

  .tzcountdown .tzdivide span, .tzcountdown .tzcountdownitem span {
    font-size: 48px;
  }

  .tzcountdown .tzdivide {
    margin: 0 20px;
    top: -25px;
  }

  .tz-live-video .owl-carousel {
    padding: 0;
  }

  /* Menu Style 2 */
  .tz_menu_style2 {
    #plazart-mainnav {
      .plazart-megamenu ul.level0 > li > a {
        padding: 5px;
      }
      .navbar-inner .btn-menu-custom {
        left: 15px;
        padding: 15px 0;
      }
    }
    .tz-close-menu {
      top: 8px;
      right: 20px;
    }
  }

  /* End Menu Style 2 */
}