//@media (max-width: 1500px) and (min-width: 1200px) {
//  .tz_menu_style1 #plazart-mainnav .level0 > li > a {
//    padding: 34px 10px;
//  }
//}

@media (max-width: 1400px) {
  .tz-slide-home-2.owl-carousel .owl-item img {
    max-width: 400px;
  }

  .tz-slide-home-2 {
    .tz-image {
      padding-right: 90px;
    }
    .tz-date {
      right: 120px;
    }
    .tz-table-cell:last-child {
      padding-left: 0;
      padding-right: 120px;
    }
  }

  .tz-section-contact {
    padding-right: 200px;
    .module-title {
      font-size: 80px;
    }
  }

  .tz_slide .module-title {
    font-size: 150px;
    line-height: 105px;
    margin-left: -6px;
  }

  .tz-artists-padding .module-title {
    font-size: 110px;
    line-height: 80px;
  }

  .tz_slide_new {
    padding-left: 100px;
  }

  .tz-section-contact,
  .tz-artists-padding {
    padding-left: 100px;
    padding-right: 200px;
  }

  .tz-slide-live-show,
  .tz-album-featured-padding {
    padding-right: 100px;
    padding-left: 100px;
  }

  .tz-albums {
    .item-album {
      padding: 30px 0;
    }
    .tz-category-fixed {
      max-width: 100%;
      right: 105px;
      font-size: 200px;
    }
    .tz-content {
      padding-right: 120px;
    }
  }

  .tz-slide-video .module-title {
    font-size: 100px;
    line-height: 60px;
    margin: 0 0 -115px;
  }
}

@media (max-width: 1400px) and (min-width: 1200px) {

  .tz-albums .item-album .tz_portfolio_plus_image img.tz_image_hover {
    max-width: 100%;
    right: -130px;
  }

  .tz-albums .tz-media a {
    position: relative;
  }

  .tz_menu_style1 #plazart-mainnav .level0 > li > a {
    padding: 35px 15px;
  }

  .tz_menu_style1 .tz-header.tz-fixed #plazart-mainnav .plazart-megamenu ul.level0 > li > a {
    padding: 29px 15px !important;
  }

  /* Home 5*/
  .tz_newsletter .tz-form .tz-size-lagre {
    font-size: 220px;
  }

  .tz_newsletter .tz-form .text-header {
    margin: -140px 0 -80px;
  }

  .tz_portfolio_plus_article .tz-content,
  .tz_newsletter .tz-form .mc-field-group {
    width: 50%;
  }

  .tz_portfolio_plus_article .tz-category-fixed {
    font-size: 230px;
  }

  /* ENd Home 5*/
}

@media (min-width: 1200px) {
  div.itemAuthorLatest .items > .item:nth-child(3n+1),
  div.itemRelated .items > .item:nth-child(3n+1) {
    clear: both;
  }
}

@media (max-width: 1199px) {
  /*Home 5*/
  .tz_portfolio_plus_article .tz-category-fixed {
    font-size: 225px;
    max-width: 100%;
  }

  .tz_portfolio_plus_article .tz-content {
    width: 80%;
  }

  .tz_newsletter .tz-form .tz-size-lagre {
    font-size: 200px;
  }

  .tz_newsletter .tz-form .mc-field-group {
    width: 60%;
  }

  .tz_newsletter .tz-form .text-header {
    margin: -130px 0 -80px;
  }

  .tz-block-footer-home5 {
    padding: 25px 80px;
  }

  /*End Home 5*/
  /* Home 3*/
  .ei-slider .text-style-2 {
    padding: 0;
  }

  .ei-slider .tz-info {
    padding: 0 10%;
  }

  .tz-body-fixed .tz-thumb {
    bottom: 70px;
  }

  /*End Home 3*/
  /* Contact */
  .view-contact .contact .tz-left-information {
    padding: 0 30px;
  }

  .view-contact .contact .tz-right .tz-form .tz-form-title {
    font-size: 40px;
  }

  .view-contact .contact .tz-right .tz-form {
    padding: 0 15px;
  }

  .view-contact .contact .contact-miscinfo {
    padding-bottom: 30px;
  }

  .view-contact .contact .tz-right .tz-form .form-group {
    margin-bottom: 20px;
    input {
      padding: 5px 20px;
    }
  }

  /* End Contact */
  /* menu style 2 */
  .tz-menu-fixed.tz-full {
    .menu__level {
      text-align: left;
      li {
        text-align: center;
        width: 33.33%;
        padding: 10px 0;
      }
    }
  }

  /* end menu style 2 */
  /* Menu Style 1 */
  .tz_menu_style1 {
    .tz-header {
      //padding: 0 5px;
      &.tz-fixed {
        #plazart-mainnav .plazart-megamenu ul.level0 > li > a {
          padding: 29px 10px !important;
        }
      }
    }
    #plazart-mainnav {
      .level0 > li > a {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }

  /* End Menu Style 1 */
  //blog
  .blog-full-width .tz-main-body {
    padding: 70px 0;
  }

  .itemListView .itemList div.catItemBody {
    margin: -80px 70px 0;
  }

  //end blog
  /* blog zic zac */
  div.itemList {
    margin: 0 -20px;
  }

  div.itemContainer {
    width: 50% !important;
    //float: none;
    padding: 0 20px 30px 20px !important;
  }

  .itemList .clr {
    display: none;
  }

  .itemListView .itemList div.catItemView.blog-zic-zac .catItemBody-ziczac, .itemListView .itemList div.catItemView.blog-zic-zac .catItemHeader-ziczac {
    width: 100%;
    display: block;
  }

  .itemListView .itemList div.catItemView.blog-zic-zac .catItemBody-ziczac {
    position: relative;
    top: 0;
    .translate(0, 0);
  }

  /* end blog zic zac */
  .tz-ticket-event {
    .tz-btn {
      padding: 10px 35px;
    }
    p {
      margin-bottom: 17px;
    }
    .tz-table-cell:last-child {
      padding-left: 30px;
    }

  }

  div.k2ItemsBlock.tz_ziczac .tz-table-cell.tz-media {
    cursor: pointer;
  }

  div.k2ItemsBlock.tz_ziczac ul li,
  .tz-social .tz-item {
    cursor: pointer;
  }

  .footer_page_home .tz-footer-title {
    font-size: 160px;
  }

  .tz-slide-video {
    .module-title {
      margin: 0;
      font-size: 180px;
      top: -50px;
      position: absolute;
      line-height: 1.2em;
    }
    .owl-carousel .owl-stage-outer {
      padding: 115px 0 90px;
    }
  }

  .tz-artists-padding,
  .tz-album-featured-padding {
    padding: 0 150px 0 80px;
  }

  .tz-albums .tz-content,
  .tz-albums .tz-media {
    width: 50%;
  }

  .tz-albums {
    .tz-content {
      padding-left: 40px;
      padding-right: 15px;
    }
    .tz-category-fixed {
      font-size: 150px;
      right: 0;
    }
  }

  .tz-albums .tz-title {
    font-size: 50px;
  }

  .tz-albums .tz-media a {
    margin-right: 40px;
    .tz_image_hover {
      max-width: 100%;
    }
  }

  .tz-section-contact,
  .tz_slide_new {
    padding-left: 80px;
  }

  .tz-slide-live-show, .tz-album-featured-padding, .tz-artists-padding {
    padding-left: 50px;
  }

  /* Tour */
  #jevents_body .new-navigation {
    .nav-items > div {
      > a {
        font-size: 12px;
        letter-spacing: 0;
        margin: 0 5px 0 0;
      }
    }
    #nav-search {
      padding-right: 0;
    }
  }

  /* End Tour */
  /*---- List Product ----*/
  .hikashop_products_listing_main .list-footer .pagination {
    width: 75%;
  }

  .hikashop_products_listing_main .list-footer .limit {
    width: 25%;
  }

  /*---- End  List Product ----*/
  /*---- Product Detail ----*/
  #hikashop_product_left_part {
    margin-bottom: 30px;
  }

  /*---- End Product Detail ----*/
}

@media (min-width: 992px) {
  .tz-clear-both {
    clear: both;
  }
}

@import "../../import/default/1199to992";
@import "../../import/default/991";
@import "../../import/default/991to768";
@import "../../import/default/767";
@import "../../import/default/480";
@import "../../import/default/320";

