/*---------------*/
/***** Zoe *****/
/*---------------*/
.effect-zoe {
  overflow: hidden;
  position: relative;
}

.effect-zoe .tz-body {
  bottom: 0;
  //padding: 1em;
  //height: 3.75em;
  background: transparent;
  color: #3c4a50;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  height: auto;
  left: 0;
  position: absolute;
  width: 100%;
}

.effect-zoe h2 {
  float: left;
}

.effect-zoe p.icon-links a {
  float: right;
  color: #3c4a50;
  font-size: 1.4em;
}

.effect-zoe:hover p.icon-links a:hover,
.effect-zoe:hover p.icon-links a:focus {
  color: #252d31;
}

.effect-zoe .tz-actions {
  position: absolute;
  bottom: 10em;
  width: 100%;
  text-transform: none;
  opacity: 0;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
  -webkit-backface-visibility: hidden; /* Fix for Chrome 37.0.2062.120 (Mac) */
}

.effect-zoe h2,
.effect-zoe p.icon-links a {
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0, 200%, 0);
  transform: translate3d(0, 200%, 0);
}

.effect-zoe p.icon-links a span::before {
  display: inline-block;
  padding: 8px 10px;
  font-family: 'feathericons';
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hikashop_subcontainer:hover .effect-zoe .tz-actions {
  opacity: 1;
}

.hikashop_subcontainer:hover .effect-zoe .tz-body,
.hikashop_subcontainer:hover .effect-zoe h2,
.hikashop_subcontainer:hover .effect-zoe p.icon-links a {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.hikashop_subcontainer:hover .effect-zoe h2 {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

.effect-zoe:hover p.icon-links a:nth-child(3) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.effect-zoe:hover p.icon-links a:nth-child(2) {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}

.effect-zoe:hover p.icon-links a:first-child {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

/*---------------*/
/***** End Zoe *****/
/*---------------*/