
a {
  &:hover, &:focus, &:active {
    text-decoration: none !important;
    outline: none !important;
  }
}

.btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
  text-decoration: none !important;
  outline: none !important;
}

p {
  font-size: 14px;
  font-weight: 300;
  color: #7c7c7c;
  line-height: 1.7em;
  letter-spacing: 0.3px;
}

.tz-table-cell {
  display: table-cell;
  &.middle {
    vertical-align: middle;
  }
}

blockquote {
  background: #252525;
  color: @white;
  font-size: 18px;
  padding: 30px 35px;
  border: none;
  font-style: italic;
  line-height: 1.65em;
  margin: 35px 0;
  em {
    display: block;
    font-size: 13px;
    line-height: 1.3em;
    margin-top: 10px;
    letter-spacing: 1px;
    font-style: normal;
  }
  &:after, &:before {
    font-family: FontAwesome;
    display: block;
    color: #daae81;
    font-size: 26px;
    font-style: normal;
    line-height: 1.6em;
  }
  &:before {
    content: '\f10d';
    margin-bottom: 10px;
  }
  &:after {
    content: '\f10e';
    text-align: right;
    margin-top: 10px;
  }
}

.form-group {
  margin-bottom: 20px;
}

#login-form ul.unstyled {
  margin-top: 20px;
  padding-left: 20px;
}

.framework-logo {
  padding: 10px 0 30px;
}

/* Logo */
.tz-logo-fixed {
  position: fixed;
  top: 0;
  left: 50%;
  .translate(-50%, 0);
  z-index: 4;
  #tzlogo {
    text-align: center;
    display: block;
    img {
      .transition(all 0.3s ease 0s);
      margin: auto;
      top: 0;
      position: relative;
    }
  }
  &.tz-fixed {
    #tzlogo img {
      .scale(0.8);
      top: -10px;
    }
  }
}

/*End Logo*/

.tz-header-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.tz-header {
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;

  background: transparent;
  &.tz-fixed {
    background: #120d14;
    .btn-menu-custom {
      padding: 18px 10px;
    }
    .k2SearchBlock.tz_search_top {
      padding: 22px 0;
    }

  }
}

.block-button-action {
  position: fixed;
  top: 20px;
  right: 20px;
  a {
    font-size: 24px;
    color: @white;
    &:hover {
      color: #ed6a5a;
    }
  }
}

.btn-menu-custom {
  display: inline-block;
  color: @white;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 10px;
  padding: 28px 10px;
  .transition(all 0.3s ease 0s);
  &:focus,
  &:hover {
    color: #ed6a5a;
    outline-style: none;
    .bar1, .bar2, .bar3 {
      background: #ed6a5a;
    }
    .bar1 {
      width: 29px;
    }
    .bar2 {
      width: 25px;
    }
    .bar3 {
      width: 33px;
    }
  }
  .bar1, .bar2, .bar3 {
    height: 1px;
    background: @white;
    display: block;
    margin: 0 0 8px 0;
    .transition(all 0.3s linear 0s);
  }
  .bar1 {
    width: 21px;
  }
  .bar2 {
    width: 33px;
  }
  .bar3 {
    width: 25px;
  }
  &.active {
    cursor: default;
  }
}

.tz-menu-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  z-index: 4;
  visibility: hidden;
  background: #120d14;
  .transition(all 0.3s linear 0s);
  &.tz-fixed {
    #plazart-mainnav .plazart-megamenu ul.level0 > li > a {
      padding: 21px 25px !important;
    }
  }
  > div {
    width: 100%;
    padding: 0;
  }
  &.active {
    visibility: visible;
    opacity: 1;
  }
  &.tz-full {
    bottom: 0;
    .menu__wrap {
      li {
        padding: 0 35px;
        a {
          .border-radius(0);
          font-size: 36px;
          padding: 0;
          letter-spacing: 6px;
          color: @white;
          display: inline-block;
        }
        &:hover, &:focus,
        &.current, &.active {
          a {
            color: #ed6a5a;
          }
        }
      }
    }
  }
}

.btn-close-menu-custom {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 2;
  color: @white;
  font-size: 60px;
  &:hover {
    color: #eed5b2;
  }
}

/* Menu styles 3 */

.menu {
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  .translate(0, -50%);
  text-align: center;
}

.menu__wrap {
  position: relative;
}

.menu__level {
  position: absolute;
  top: 50%;
  left: 0;
  visibility: hidden;
  overflow: hidden;
  right: 0;
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-align: center;
  .translate(0, -50%);
  &.main-menu {
    position: relative;
    top: 0;
    .translate(0, 0);
  }
  li {
    display: inline-block;

  }
}

.active .menu__level--current {
  visibility: visible;
}

.menu__link {
  position: relative;
  display: block;
  -webkit-transition: color 0.1s;
  transition: color 0.1s;
}

[class^='animate-'],
[class*=' animate-'] {
  visibility: visible;
}

.animate-outToRight .menu__item {
  -webkit-animation: outToRight 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
  animation: outToRight 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
}

@-webkit-keyframes outToRight {
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes outToRight {
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.animate-outToLeft .menu__item {
  -webkit-animation: outToLeft 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
  animation: outToLeft 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
}

@-webkit-keyframes outToLeft {
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes outToLeft {
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.animate-inFromLeft .menu__item {
  -webkit-animation: inFromLeft 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
  animation: inFromLeft 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
}

@-webkit-keyframes inFromLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes inFromLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.animate-inFromRight .menu__item {
  -webkit-animation: inFromRight 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
  animation: inFromRight 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
}

@-webkit-keyframes inFromRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes inFromRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.menu__breadcrumbs {
  font-size: 14px;
  position: relative;
  padding: 0 0 35px 0;
  font-weight: 700;
  a {
    display: inline-block;
    cursor: pointer;
    vertical-align: middle;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: @white;
    &:last-child {
      pointer-events: none;
    }
    &:hover {
      color: #daae81;
    }
    &:not(:last-child)::after {
      content: '\e068';
      font-family: "linea-arrows-10";
      display: inline-block;
      padding: 0 10px;
      color: #868686;
      line-height: 1;
      position: relative;
      top: 2px;
    }
  }
}

.menu__back {
  font-size: 1.05em;
  position: absolute;
  z-index: 100;
  top: 0;
  right: 2.25em;
  margin: 0;
  padding: 1.365em 0.65em 0 0;
  cursor: pointer;
  color: #2a2b30;
  border: none;
  background: none;
}

.menu__back--hidden {
  pointer-events: none;
  opacity: 0;
}

.menu__back:hover,
.menu__back:focus {
  color: #fff;
  outline: none;
}

/* End Menu Style 3*/

/* Menu Style 2*/

.tz_menu_style2 {
  .tz-menu-fixed {
    z-index: 10000;
  }
  #plazart-mainnav {
    display: block;
    margin: auto 0;
    height: auto;
    padding: 0;
    background: #120d14;
    border: none;
    min-height: 0;
    .border-radius(0);
    a {
      color: @white;
      font-size: 24px;
      letter-spacing: 2px;
    }

    .btn-menu-custom {
      position: absolute;
      left: 25px;
      top: 50%;
      padding: 0;
      .translate(0, -50%);
      font-size: 10px;
      text-align: left;
      letter-spacing: 0;
      &:hover, &:focus {
        color: #ed6a5a;
      }
    }
    .navbar-inner {
      .btn-menu-custom {
        display: none;
      }
    }
    .tz-close-menu {
      position: absolute;
      top: 50%;
      .translate(0, -50%);
      font-size: 36px;
      line-height: 15px;
      right: 25px;
      &:hover, &:focus {
        color: #ed6a5a;
      }
    }
    .nav-collapse {
      padding: 0;
    }
    .plazart-megamenu {
      .caret {
        display: none;
      }
      &:after {
        display: block;
        content: '';
        clear: both;
      }
      ul {
        &.level0 {
          width: 100%;
          text-align: center;
          > li {
            float: none;
            display: inline-block;
          }
        }
        > li {
          > a:hover,
          > a:focus,
          &.current > a,
          &.active > a,
          &.open > a {
            color: #ed6a5a;
            background: transparent;
          }
        }
        &.level0 {
          > li {
            &:last-child {
              > a {
                padding-right: 20px;
              }
            }
            > a {
              padding-right: 25px;
              padding-left: 20px;
              padding-top: 30px;
              padding-bottom: 30px;
              line-height: 1.5em;
            }
          }
        }
      }
    }
  }
  .tz-close-menu,
  .btn-menu-custom {
    display: block;
  }
}

/* End Menu Style 2 */

/*--------------------*/

/* Menu Style 1 */

.tz_menu_style1 {
  .tz-header {
    padding: 0 20px;
    &.tz-fixed {
      #tzlogo {
        padding: 28px 0;
      }
      #plazart-mainnav .plazart-megamenu ul.level0 > li > a {
        padding: 29px 18px !important;
      }
    }
  }
  .k2SearchBlock.tz_search_top {
    padding: 28px 0;
  }
  #tzlogo {
    padding: 33px 0;
    display: inline-block;
    .transition(all 0.3s ease 0s);
    img {
      max-width: 100%;
    }
  }
  #plazart-mainnav {
    margin: 0;
    border: none;
    .navbar-collapse {
      padding: 0;
    }
    a {
      font-size: 20px;
      background: transparent !important;
      color: @white;
      letter-spacing: 1px;
    }
    .level0 {
      display: block;
      float: none;
      > li {
        display: inline-block;
        float: none;
        &.active > a, &.current > a {
          color: #ed6a5a;
        }
        > a {
          padding: 35px 18px;
          .transition(all 0.3s ease 0s);
        }
      }
    }
    .caret {
      display: none;
    }
  }
}

/* End Menu Style 1*/

.tz_menu_style2,
.tz_menu_style3 {
  #plazart-mainnav {
    .tz-close-menu,
    .btn-menu-custom {
      display: block;
    }
  }
}

#plazart-mainnav {
  .tz-close-menu,
  .btn-menu-custom {
    display: none;
  }
  .dropdown-menu {
    a {
      font-size: 18px;
      padding: 18px 0 4px;
      &:after {
        content: none;
      }
    }
  }
  .mega-inner {
    padding: 0 15px 14px;
  }

  .dropdown-menu {
    background: #120d14;
    border: none;
    .border-radius(0);
    .box-shadow(none);
    padding: 0;
  }
  ul {
    > li {
      > a:hover,
      > a:focus,
      &.current > a,
      &.active > a,
      &.open > a {
        color: #ed6a5a;
        background: transparent;
      }
    }
  }
}

.tz_menu_style2 #plazart-mainnav .plazart-megamenu ul.level0 > li,
.tz_menu_style1 #plazart-mainnav .level0 > li {
  display: inline-block !important;
}

/* Mod K2 Search */
.tz_search_top {
  display: inline-block;
}

.k2SearchBlock.tz_search_top {
  padding-top: 28px;
  position: relative;
  margin-right: 10px;
  padding-bottom: 34px;
  .transition(all 0.3s ease 0s);
  .tz_icon_search {
    display: block;
    i {
      color: @white;
      font-size: 24px;
    }
  }
  .k2SearchBlockForm {
    position: absolute;
    top: 120%;
    opacity: 0;
    visibility: hidden;
    min-width: 300px;
    right: 0;
    left: auto;
    .transition(all 0.5s linear 0s);
    border-top: 3px solid #ed6a5a;
    input[type="text"] {
      width: 100%;
      padding: 10px 20px;
      border: none;
      color: #888888;
    }
    &.active {
      opacity: 1;
      visibility: visible;
      top: 100%;
    }
  }
}

/* End Mod K2 Search */
.btn-action-fixed {
  //position: fixed;
  z-index: 2;
}

.tz-close-menu {
  right: 30px;
  top: 26px;
  font-size: 36px;
  color: @white;
  cursor: pointer;
  position: fixed;
  &:hover {
    color: #daae81;
  }
}

/* Breadcrumbs */
.tz-block-breadcrumbs {
  padding: 168px 0 127px;
}

.tz-breadcrumbs {
  h2.tz-last-item {
    font-size: 60px;
    color: @white;
    letter-spacing: 10px;
    margin: 0 0 25px;
    span {
      position: relative;
      padding-bottom: 10px;
      display: inline-block;
      &:after {
        content: '';
        height: 5px;
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        background: #ed6a5a;
      }
    }
  }
  ul {
    padding: 0;
    margin: 0;
    li {
      display: inline-block;
      position: relative;
      &:after {
        content: '//';
        color: #e2bc93;
        font-weight: 300;
      }
      &:last-child {
        &:after {
          content: none;
        }
      }
      a, span {
        color: rgba(255, 255, 255, 0.7);
        font-size: 16px;
        font-weight: 300;
        margin: 0 1px;
      }
    }
  }
}

/* End Breadcrumbs */
//
.tz-body-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  > * {
    height: 100% !important;
    max-width: 100% !important;
  }
  .tz-thumb {
    position: absolute;
    bottom: 25px;
    z-index: 1;
    width: 100%;
  }
  ul {
    padding: 0;
    list-style: none;
  }
}

.tz-thumb {
  .ei-slider-thumbs {
    height: auto;
    &:after {
      clear: both;
      display: block;
      content: '';
    }
    li {
      height: auto !important;
      position: relative;
      &:last-child:after {
        content: none;
      }
      &:after {
        content: '.............................................................................';
        position: absolute;
        top: -7px;
        left: 0;
        width: 100%;
        height: 20px;
        color: @white;
        overflow: hidden;
        letter-spacing: 12px;
        font-size: 18px;
        z-index: -1;
      }
      &.ei-slider-element {
        max-width: none !important;
        text-indent: 0;
        height: 5px !important;
        background: #daae81;
        top: 8px;
        left: 5px !important;
        z-index: 0;
        &:after {
          content: none;
        }
      }
      &:hover {
        img {
          bottom: 25px;
          visibility: visible;
          opacity: 1;
        }
      }
      a {
        .box-shadow(none);
        text-indent: 0;
        width: 10px;
        height: 9px;
        .border-radius(50%);
        margin: auto;
        display: inline-block;
        background: @white;
        &.tz_ei-background {
          background: #daae81;
        }
      }
      img {
        left: 0;
        width: 200px;
        margin-left: -100px;
        max-width: none;
        border: 1px solid #eed5b2;
        visibility: hidden;
        opacity: 0;
        z-index: 1;
      }
    }
  }
}

.ei-slider {
  .ei-slider-large {
    z-index: 1;
    li {
      > * {
        z-index: 1;
      }
      &:after {
        background: rgba(18, 13, 20, 0.7);
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        //z-index: 11;
      }
    }
  }
  .tz-info {
    position: absolute;
    top: 50%;
    left: 0;
    .translate(0, -50%);
    padding: 0 20%;
  }
  .tz-title {
    color: @white;
    font-size: 16px;
    position: relative;
    display: inline-block;
    padding-bottom: 16px;
    letter-spacing: 3px;
    text-transform: uppercase;
    &:after {
      content: '';
      position: absolute;
      bottom: 2px;
      width: 100%;
      left: 0;
      height: 5px;
      background: #ed6a5a;
    }
  }
  .text-style-1 {
    color: @white;
    font-size: 72px;
    letter-spacing: 15px;
    line-height: 1.5em;
    padding-top: 4px;
    margin-bottom: 0;
  }
  .text-style-2 {
    color: @white;
    padding: 0 18%;
    line-height: 1.9em;
    margin-bottom: 50px;
  }
  .tz-btn {
    text-transform: uppercase;
    font-size: 16px;
    padding: 12px 40px 9px;
    .transition(all 0.3s ease 0s);
    &.tz-style-2 {
      &:hover {
        background: #eed5b2;
        color: #222222;
      }
    }
    &.tz-style-1 {
      &:hover {
        border-color: #ed6a5a;
        color: @white;
        background: #ed6a5a;
      }
    }
  }
}

//footer
.tz-footer-fixed {
  .tz-copyright {
    position: fixed;
    bottom: 22px;
  }

  .tz-social-network {
    position: fixed;
    bottom: 20px;
  }
  .tz-copyright {
    left: 30px;
    font-size: 17px;
    font-weight: 300;
    text-transform: uppercase;
  }
  .tz-social-network {
    right: 30px;
  }
}

.tz-copyright,
.tz-social-network {
  margin: 0;
}

.tz-copyright {
  color: #e1e1e1;
  font-family: BebasNeueLight, Arial, sans-serif;
  letter-spacing: 2px;
}

.tz-social-network {
  a {
    color: @white;
    margin: 0 10px;
    font-size: 16px;
    &:hover {
      color: #daae81;
    }
  }
}

//typo
.tz-btn {
  padding: 12px 40px;
  letter-spacing: 3px;
  line-height: 1.5em;
  display: inline-block;
  &.tz-style-1 {
    background: #eed5b2;
    color: #100913;
    border: 2px solid #eed5b2;
  }
  &.tz-style-2 {
    background: transparent;
    color: #eed5b2;
    border: 2px solid #eed5b2;
  }
  &.tz-radius {
    .border-radius(30px);
  }
  &.tz-radius-left {
    .border-radius(30px 0 0 30px);
  }
  &.tz-radius-right {
    .border-radius(0 30px 30px 0);
  }
}

//events
#jumpto {
  .chzn-container {
    width: auto !important;
  }
}

#jevents_body #jumpto form button {
  padding: 2px 10px;
  background: #daae81;
  color: #222222;
  font-weight: 300;
  border: 1px solid #daae81;
}

.ev_fieldset {
  font-size: 100%;
  font-weight: normal;
  padding-bottom: 15px;
  margin: 0;
}

.jev_catdesc {
  padding-bottom: 15px;
}

#jevents_header {
  //padding-top: 120px;
}

.jev_pagination {
  padding-top: 30px;
  padding-bottom: 30px;
  form {
    br {
      display: none;
    }
    input {
      border: 1px solid #ededed;
      color: #666666;
      padding: 5px 10px;
      &.button {
        background: #daae81;
        color: @white;
        border: 1px solid #daae81;
        .border-radius(2px);
        &:hover {
          background: #ed6a5a;
          border-color: #daae81;
        }
      }
      &.inputbox {
        width: 100%;
        margin-bottom: 15px;
      }
    }
    input, label {
      margin: 0 3px;
    }
  }
}

.ev_adminpanel {
  .nav_bar_cell {
    padding: 15px;
  }
  form > div {
    float: none !important;
    display: block;
  }
  .chzn-container {
    margin-left: 15px;
    display: inline-block;
    float: none !important;
    padding: 0;
    background: @white;
    .border-radius(5px);
  }
  input[type="button"] {
    background: #daae81;
    color: @white;
    border: 1px solid #daae81;
    .border-radius(2px);
    padding: 5px 10px;
    &:hover {
      background: #ed6a5a;
      border-color: #daae81;
    }
  }
}

#jevents_body #jev_maincal.jev_listview .jev_daysnames {
  float: none;
  border: none;
  padding: 0;
  letter-spacing: 0.3px;
  .chzn-container {
    background: @white !important;
    .chzn-results {
      li {
        border: none;
        padding: 0 0 5px;
        //margin: 0;
        &.highlighted,
        &:hover {
          color: #ed6a5a;
        }
      }
    }
  }
}

.jeventpage {
  .tz_image {
    min-height: 60px;
  }
  .tz_main_content {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    background: @white;
    .box-shadow(0 0 6px rgba(0, 0, 0, 0.09));
    .tz_header_content {
      border: 5px solid #ed6a5a;
      background: @white;
      margin-top: -60px;
      position: relative;
      display: block;
      padding: 26px 25px 28px;
    }
    .tz_muted {
      color: #daae81;
      span, a {
        color: #c2c2c2;
      }
      &:after {
        content: '';
        height: 5px;
        width: 5px;
        .border-radius(50%);
        background: #c2c2c2;
        display: inline-block;
        margin: 2px 10px;
      }
      &:last-child {
        &:after {
          content: none;
        }
      }
    }
  }
  .tz_body_content {
    padding-top: 25px;
    .jev_evdt_desc {
      color: #505050;
      line-height: 1.9em;
      font-weight: 300;
    }
  }
  .jev_evdt_location {
    color: #ed6a5a;
    margin-bottom: 12px;
  }
  .tz_image {
    img {
      width: 100%;
    }
  }
  .jev_evdt_title {
    h3 {
      color: #120d14;
      margin: 0 0 13px;
      letter-spacing: 3px;
    }
  }
  .box-detail {
    margin-bottom: 55px;
    .tz-content-box {
      background: @white;
      padding: 22px 30px;
    }
    .box-shadow(0 0 6px rgba(0, 0, 0, 0.09));
    .tz-title-box {
      background: #ed6a5a;
      display: block;
      color: @white;
      margin: 0;
      padding: 12px 30px;
      letter-spacing: 1px;
    }
    .tz-row {
      color: #505050;
      font-weight: 300;
      margin-bottom: 23px;
      &:last-child {
        margin-bottom: 0;
      }
      a {
        color: #daae81;
      }
      .tz-label {
        display: block;
        font-size: 18px;
        color: #888888;
        letter-spacing: 2px;
        margin-bottom: 2px;
      }
    }
  }
}

#jevents {
  ul.actions {
    margin-top: 20px !important;
    a {
      display: block;
      span {
        background: #505050;
        color: @white;
        .border-radius(30px);
        font-size: 16px;
        padding: 10px 20px;
        display: block;
      }
    }
  }
  #jevents_header {
    .contentheading {
      display: inline-block;
    }
  }
  .tz-jev-header {
    margin-bottom: 41px;
    .time {
      display: block;
      font-family: BebasNeueBold, Arial, sans-serif;
      font-weight: normal;
      color: #959595;
      font-size: 18px;
      letter-spacing: 2px;
    }
    .ev_fieldset {
      font-size: 48px;
      color: #464646;
      font-family: BebasNeueBold, Arial, sans-serif;
      font-weight: normal;
      letter-spacing: 5px;
      margin-bottom: 5px;
    }
  }
}

#jevents_body {
  border: none;
  .border-radius(0);
  padding-top: 20px;
  .jev_header2 {
    height: auto;
    > * {
      display: table-cell;
      vertical-align: middle;
      height: auto;
      float: none;
    }
    &:after {
      display: block;
      clear: both;
      content: '';
    }
  }
  .jev_toprow {
    //font-family: BebasNeueBold, Arial, sans-serif;
    margin-bottom: 35px;
    div.previousmonth, div.nextmonth {
      background: transparent !important;
      padding: 15px 0;
      min-width: 250px;
      a {
        background: none !important;
        color: #c2c2c2 !important;
        font-size: 17px;
        padding: 0;
        letter-spacing: 1px;
        &:hover {
          color: #daae81 !important;
        }
        &:before, &:after {
          font-family: linea-arrows-10;
          position: relative;
          top: 7px;
          font-size: 26px;
        }
      }
    }
    div.currentmonth {
      color: #464646 !important;
      font-size: 48px;
      width: 100%;
      font-weight: normal;
      letter-spacing: 5px;
    }
    div.previousmonth {
      text-align: left !important;
      a {
        &:before {
          content: '\e00f';
          margin-right: 10px;
        }
      }
    }
    div.nextmonth {
      text-align: right !important;
      a {
        &:after {
          content: '\e00b';
          margin-left: 10px;
        }
      }
    }
    div.previousmonth, div.nextmonth, div.currentmonth {
      border: none;
      line-height: 1.5em;
      margin: 0;
    }

  }
  .cal_top_day_names {
    .cal_daysnames {
      background: #daae81;
      font-size: 18px;
      color: @white;
      //font-family: BebasNeueBold, Arial, sans-serif;
      letter-spacing: 1px;
      font-weight: normal;
      line-height: 1.65em;
      padding: 15px 20px;
      border-right: 1px solid @white;
      * {
        color: @white;
      }
      &:last-child {
        border: none;
      }
    }
  }
  .cal_table {
    border: none;
    margin: 0;
    padding: 0;
    background: @white;
    .cal_cell_rows {
      td {
        padding: 0;
        border-right: 1px solid #F9F9F9;
        height: auto;
        position: relative;
        background: transparent;
        &:last-child {
          border: none;
        }
        &.cal_daysoutofmonth {
          background: #f8f8f8;
          .cal_daylink {
            color: #e1e1e1;
          }
        }
        .cal_daylink {
          display: block;
          background: #f4f4f4;
          border: none;
          .border-radius(0);
          //font-family: BebasNeueBold, Arial, sans-serif;
          font-weight: normal;
          font-size: 14px;
          letter-spacing: 1px;
          line-height: 1.4em;
          padding: 5px 20px;
          margin: 0;
          .transition(all 0.3s ease 0s);
        }
        &.showjevadd,
        &.cal_today {
          .cal_daylink {
            color: @white;
            background: #daae81;
          }
          .event_body {
            border-color: #daae81;
          }
          .line-bottom,
          &:before,
          &:after {
            background: #daae81;
          }
        }
        .event_body {
          min-height: 125px;
          //border: 1px solid transparent;
          padding: 20px;
          > * {
            margin: 0 0 5px;
          }
        }
        &:after {
          content: '';
          width: 1px;
          position: absolute;
          right: 0;
          bottom: 0;
          top: 0;
          background: transparent;
          .transition(all 0.3s ease 0s);
        }
        &:before {
          content: '';
          width: 1px;
          position: absolute;
          left: 0;
          bottom: 0;
          top: 0;
          background: transparent;
          .transition(all 0.3s ease 0s);
        }
        .line-bottom {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background: transparent;
          height: 1px;
          .transition(all 0.3s ease 0s);
        }
      }
    }
  }
  .month_cell_st {
    border-width: 0 0 1px 0 !important;
    border-color: #f4f4f4 !important;
    border-style: solid;
    background: transparent;
    a.cal_titlelink {
      color: #7c7c7c;
      font-size: 14px;
      font-weight: 300;
      padding: 0;
      margin-bottom: 7px;
      letter-spacing: 0.3px;
      &:hover {
        background: transparent;
      }
    }
  }
  .new-navigation {
    width: 100%;
    .nav-items {
      width: auto;
      > div {
        line-height: 1.5em;
        margin: 0;
        padding: 0;
        border: none;
        .border-radius(30px);
        &.active > a {
          border: 1px solid #daae81;
          background: transparent;
          color: #daae81;
        }
        > a {
          display: block;
          .border-radius(30px);
          border: 1px solid #daae81;
          background: #daae81;
          font-size: 13px;
          font-weight: normal;
          padding: 10px 20px;
          letter-spacing: 1px;
          margin: 0 5px;
          color: @white;
          text-transform: uppercase;
          &:hover {
            border: 1px solid #daae81;
            background: transparent;
            color: #daae81;
          }
        }
      }
    }
    #nav-search {
      float: none;
      a {
        padding: 10px 20px;
        display: block;
        .border-radius(30px);
        background: #daae81;
        color: @white;

        border: 1px solid #daae81;
        &:after {
          content: '\f002';
          font-family: FontAwesome;

        }
        &:hover {
          &:after {
            border: 1px solid #daae81;
            background: transparent;
            color: #daae81;
          }
        }
        img {
          display: none;
        }
      }
    }
  }
  .month_date {
    min-width: 250px;
    width: auto;
    padding-left: 0;
  }
  .new-navigation,
  .month_date {
    display: block;
    padding-bottom: 20px;
  }
  .popover {
    border-width: 0 0 3px 0;
    border-color: #daae81;
    .border-radius(0);
    padding: 20px;
    max-width: 600px;
    min-width: 330px;
    .popover-title {
      .border-radius(0);
      background: transparent;
      border: none;
    }
    .jevtt_title {
      .border-radius(0);
      background: transparent !important;
      font-weight: normal;
      color: #636363 !important;
      font-size: 21px;
      padding: 0;
      //font-family: BebasNeueBold, Arial, sans-serif;
      letter-spacing: 3px;
      margin-bottom: 10px;
    }
    .popover-content {
      padding: 0;

    }
    .jevtt_text {
      .fwb {
        display: block;
      }
      p {
        color: #959595;
      }
    }
    .infoTop {
      font-size: 13px;
      color: #daae81;
      font-weight: 300;
      margin-bottom: 10px;
      em {
        color: #c2c2c2;
        font-style: normal;
      }
      > span:first-child {
        &:after {
          content: '\f111';
          display: inline-block;
          font-family: FontAwesome;
          font-size: 5px;
          position: relative;
          margin: 0 7px;
          top: -3px;
        }
      }
    }
    &.top {
      margin-top: -15px;
      > .arrow {
        border-top-color: #daae81;
        &:after {
          border-top-color: #daae81;
        }
      }
    }
  }
  .nav-tabs {
    background: @white;
    border-bottom: 5px solid #daae81;
    > li {
      margin: 0;
      &:hover,
      &.open {
        a {
          background: #f9f9f9;
          &:before {
            background: #daae81;
          }
        }
      }
      &.active {
        a {
          background: #daae81;
          color: @white;
          .event, .events {
            color: @white;
          }
        }
        h4 {
          color: @white;
        }
        em {
          color: @white;
        }
      }
      > a {
        border: none;
        .border-radius(0);
        margin: 0;
        position: relative;
        font-weight: 300;
        padding: 17px;
        .transition(all 0.3s ease 0s);
        &:before {
          position: absolute;
          height: 3px;
          width: 100%;
          left: 0;
          top: -3px;
          content: '';
          background: transparent;
          .transition(all 0.3s ease 0s);
        }
        .event {
          color: #c2c2c2;
        }
        .events {
          color: #daae81;
        }
        h4 {
          letter-spacing: 2px;
          color: #888888;
          margin-top: 5px;
          margin-bottom: 5px;
        }
        em {
          font-weight: 300;
          font-style: normal;
          color: #959595;
        }
      }
    }
  }
  .jev_listview,
  .tab-content {
    ul.ev_ul {
      margin: 0;
      li {
        background: @white;
      }
      .b0 {
        padding: 25px;
        color: #959595;
      }
      li.ev_td_li {
        padding: 23px 0 28px;
        border-width: 0 0 1px 0 !important;
        border-style: solid;
        border-color: #ebebeb !important;
        margin: 0;
        &:hover {
          .text-style-1 {
            color: #daae81;
          }
        }
        .ev_link_cat {
          &:hover {
            color: #ed6a5a;
          }
        }
        .text-style-1 {
          font-family: BebasNeueBold, Arial, sans-serif;
          color: #888888;
          font-size: 21px;
          letter-spacing: 3px;
          font-weight: normal;
          a {
            font-size: 21px;
            color: #888888;
            &:hover {
              color: #ed6a5a;
            }
          }
        }
        p {
          margin-bottom: 0;
          margin-top: 6px;
          line-height: 1.6em;
          color: #505050;
          letter-spacing: 0.3px;
          font-size: 13px;
        }
        .time {
          display: block;
          padding-left: 20px;
          font-size: 12px;
          color: #888888;
          text-align: left;
          font-weight: 300;
          letter-spacing: 0.3px;
          em {
            font-style: normal;
            color: #ed6a5a;
            text-transform: lowercase;
          }
        }
        i {
          color: #c2c2c2;
          font-size: 20px;
        }
      }
      .tz-table-cell {
        vertical-align: middle;
        &.col_left {
          min-width: 170px;
          padding-left: 20px;
        }
        &.col_center {
          min-width: 100%;
        }
        &.col_right {
          min-width: 200px;
        }
      }
    }

  }
  #jev_maincal {
    background: none;
    border: none;
    margin: 0;
    .jev_listrow {
      border: none;
      float: none;
      margin: 0;
    }
  }
}

.view-day {
  #jevents_body .jev_toprow div.currentmonth {
    font-size: 42px;
  }
  #jevents_body {
    .jev_toprow {
      margin-bottom: 20px;
    }
    .new-navigation {
      display: block;
      margin: 0;
      text-align: center;
    }
  }

}

//filter events

.jevfilterlist {
  padding: 0;
  .box-shadow(0 0 6px rgba(0, 0, 0, 0.09));
  li {
    background: @white;
    .tz-title, label {
      background: #edd5b2;
      color: @white;
      font-size: 21px;
      font-weight: normal;
      margin: 0;
      letter-spacing: 2px;
    }
    .tz-title {
      padding: 8px 10px 8px 25px;
    }
    .jevfilterinput {
      padding: 30px;
      .evsearch {
        .border-radius(30px);
        width: 100%;
        padding: 10px 20px;
        font-size: 12px;
        color: #888888;
        .box-shadow(none);
        font-weight: 300;
        border: 1px solid #daae81;
      }
    }
    &.action {
      .jevfilterinput {
        padding: 0;
        &:first-child {
          margin-bottom: 1px;
        }
        input {
          line-height: 1.5em;
          .box-shadow(none);
          border: 1px solid #c7c7c7;
          background: #c7c7c7;
          width: 100%;
          text-align: left;
          font-weight: normal;
          font-size: 24px;
          color: @white;
          padding: 10px 30px;
        }
      }
    }
  }
  .chzn-container {
    width: 100% !important;
  }
}

.chzn-container {
  //width: 100% !important;
  &.chzn-container-single {
    .chzn-single {
      background: none;
      .box-shadow(none);
      height: auto;
      border-color: #daae81;
      //.border-radius(30px);
      font-weight: 300;
      padding-left: 15px;
    }
  }
  &.chzn-container-active.chzn-with-drop {
    .chzn-single {
      background: none;
      border-color: #daae81;
    }
  }
  div.chzn-drop {
    border-color: #daae81;
    .chzn-results {
      padding: 0 10px;
      li.highlighted {
        background: #daae81;
        color: @white;
      }
    }
  }
}

//footer

.social_footer {
  padding-bottom: 20px;
  padding-top: 30px;
  a {
    margin: 0 12px;
    display: inline-block;
  }
  i {
    color: @white;
    font-size: 30px;
  }
}

.copyright_footer {
  color: #e7e7e7;
  font-size: 17px;
  letter-spacing: 3px;
  padding-top: 27px;
  padding-bottom: 20px;
  line-height: 1.8em;
  font-weight: 300;
  text-transform: uppercase;
  i {
    color: #e2bc93;
  }
  &.homepage {
    padding: 11px 0 12px;
  }
}

.logo_footer {
  padding-bottom: 20px;
}

.term_footer {
  padding-top: 25px;
  padding-bottom: 30px;
  &.homepage {
    padding: 16px 0 25px;
  }
  a {
    color: #e7e7e7;
    font-size: 17px;
    letter-spacing: 2px;
    margin: 0 12px;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 1em;
  }
}

.page-default {
  .copyright_footer {
    line-height: 1.5em;
  }
  .social_footer {
    padding-top: 32px;
  }
}

.footer_page_home {
  .tz-footer-title {
    font-size: 250px;
    color: rgba(255, 255, 255, 0.18);
    letter-spacing: 25px;
  }
}

.social_footer_home_page {
  margin-top: 100px;
  position: relative;
  display: inline-block;
  padding: 0 15px;
  &:after, &:before {
    content: '';
    height: 1px;
    position: absolute;
    width: 1000%;
    background: rgba(255, 255, 255, 0.1);
    top: 50%;
    margin-top: -1px;
  }
  &:after {
    right: -1000%;
  }
  &:before {
    left: -1000%;
  }
  a {
    margin: 0 7px;
  }
  i {
    font-size: 18px;
    color: @white;
  }
}

.tz-overflow {
  overflow: hidden;
}

//page contact
.view-contact {
  .contact {
    .tz-btn-contact {
      border: medium none;
      .border-radius(30px);
      color: #120d14;
      padding: 10px 25px;
      text-transform: uppercase;
      letter-spacing: 2px;
      background: #eed5b2;
      font-size: 17px;
      &:hover {
        background: #ed6a5a;
      }
      i {
        margin-right: 10px;
      }
    }
    .thumbnail {
      max-width: 100px;
    }
    .tz-right, .tz-left {
      display: table-cell;
      float: none;
      vertical-align: middle;
    }
    .contact-miscinfo {
      padding-bottom: 45px;
    }
    .tz-right {
      background: #120d14;
      position: relative;
      .tz-form {
        padding-left: 85px;
        padding-right: 85px;
        .tz-form-title {
          font-size: 48px;
          color: #e1e1e1;
          margin-bottom: 15px;
        }
        .contact-misc {
          color: #e1e1e1;
        }
        .form-group {
          margin-bottom: 35px;
          textarea,
          input {
            margin: 0;
            position: relative;
            .border-radius(30px);
            background: rgba(238, 213, 178, 0.1);
            border: none;
            color: #eed5b2;
            padding: 10px 20px;
            display: block;
            width: 100%;
            line-height: 1.7em;
            &:focus {
              -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgb(238, 213, 178);
              -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgb(238, 213, 178);
              box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgb(238, 213, 178);
            }
          }
          textarea {
            padding: 20px;
            height: 185px;
          }
          &.email_copy {
            input {
              width: auto;
              display: inline-block;
              margin-right: 5px;;
            }
            label {
              font-weight: 300;
              margin: 0;
              color: #e1e1e1;
            }
          }
        }
      }
    }
    .tz-left {
      position: relative;
      &:after {
        background: rgba(18, 13, 20, 0.83);
        content: '';
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        position: absolute;
      }
      > * {
        position: relative;
        z-index: 1;
      }
    }
    .custom {
      position: unset !important;
      iframe {
        position: absolute;
        top: 0;
        bottom: 5px;
        left: 0;
        right: 0;
        z-index: 0;
      }
    }
    .tz-left-information {
      font-weight: 300;
      color: #c3c3c3;
      padding: 0 25%;
      a {
        color: #c3c3c3;
      }
      .jicons-icons {
        padding-right: 10px;
        width: 25px;
      }
      ul {
        list-style: none;
      }
      .contact-webpage,
      .contact-emailto,
      .contact-country,
      .contact-postcode,
      .contact-state,
      .contact-suburb,
      .contact-street,
      .contact-mobile,
      .contact-fax,
      .contact-telephone {
        font-size: 14px;
        line-height: 1.8em;
        position: relative;
      }
    }
    .contact-address {
      padding: 0;
      margin: 0;
      li {
        padding-bottom: 35px;
        h4 {
          font-size: 20px;
          letter-spacing: 1px;
          margin-top: 8px;
        }
      }
    }
  }
}

//Page Home

.padding-latest-new {
  padding: 60px 0 155px;
}

.padding-lr-0 {
  padding-left: 0;
  padding-right: 0;
}

.image_black_white {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
}

//home 2

.block-intro {
  padding: 0 155px;
  color: @white;
  letter-spacing: 15px;
  h3 {
    font-size: 72px;
    margin: 0 0 6px 0;
  }
  a {
    color: #ed6a5a;
  }
}

.fullpage-footer {
  .tz_copyright {
    position: fixed;
    left: 30px;
    bottom: 23px;
    color: #e1e1e1;
    letter-spacing: 3px;
    font-size: 17px;
    text-transform: uppercase;
    font-weight: 300;
  }
  .tz_social {
    position: fixed;
    right: 30px;
    bottom: 23px;
    font-size: 16px;
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      float: none;
      margin: 0 10px;
      &:last-child {
        margin-right: 0;
      }
    }
    a {
      color: @white;
      &:hover {
        color: #daae81;
      }
    }
  }
}

//

body {
  #fp-nav.right {
    right: 35px;
  }
  #fp-nav {
    margin: 0;
    .translate(0, -50%);
    ul {
      li {
        margin: 20px 0;
        letter-spacing: 3px;

        .fp-tooltip {
          opacity: 1;
          width: auto;
          &.right {
            right: 45px;
          }
        }
        &:hover a, &:hover, &.active {
          span {
            height: 1px;
            width: 26px;
            .border-radius(0);
            margin: 0;
          }
          .fp-tooltip {
            color: #ed6a5a;
          }
        }
        a {
          span {
            height: 1px;
            width: 12px;
            background: @white;
            margin: 0;
            .border-radius(0);
            right: 0;
            left: auto;;
            .transition(all 0.3s ease-in-out 0s)
          }
        }
      }
    }
  }
}

//pagination
.pagination-list.pagination {
  margin: 0;
  li {
    a {
      background: #dfdfdf;
      color: #505050;
      height: 40px;
      width: 40px;
      line-height: 40px;
      padding: 0;
      float: none;
      border-color: #dfdfdf;
    }
    a:hover,
    &.active a {
      background: #ed6a5a;
      border-color: #ed6a5a;
      color: @white;
    }
  }
}

div.k2Pagination {
  margin: 0;
  padding: 0;
}

//tz-main-body

.blog-zic-zac {
  .tz-main-body {
    padding: 100px 0;
  }
}

.tz-view-video,
.tz-view-album,
.blog-full-width {
  .tz-main-body {
    padding: 120px 0;
  }
}

.com_jevents {
  .tz-main-body {
    padding: 120px 0;
  }
}

.width-auto {
  width: auto;
  display: inline-block;
}

.text-page {
  color: @white;
  font-size: 48px;
  padding-left: 20px;
  letter-spacing: 2px;
}

//k2 search
.genericView {
  .componentheading {
    font-size: 30px;
    margin: 30px 0;
  }
}

div.tagItemView,
div.userItemView,
div.genericItemView {
  margin-bottom: 85px;
  .ItemBody {
    margin: -80px 100px 0;
    background: @white;
    position: relative;
    display: block;
    padding: 50px 30px 50px;
    .box-shadow(0 0 6px rgba(0, 0, 0, 0.09));
  }
  .tagItemDateCreated,
  .userItemDateCreated,
  .genericItemDateCreated {
    color: rgba(18, 13, 20, 0.4);
    font-size: 18px;
    letter-spacing: 1px;
  }
  .tagItemTitle,
  .userItemTitle,
  .genericItemTitle {
    font-size: 24px;
    color: #120d14;
    a {
      color: #120d14;
    }
  }
  .tagItemIntroText,
  .userItemIntroText,
  .genericItemIntroText {
    padding: 20px 0 35px;
  }
  .tagItemCategory,
  .userItemCategory,
  .genericItemCategory {
    display: block;
    padding: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 47px 28px 0;
    border-color: #ed6a5a transparent transparent transparent;
    position: relative;
    margin: auto;
    a {
      background: #ffffff;
      left: -160px;
      min-width: 320px;
      padding: 3px 0;
      position: absolute;
      text-align: center;
      top: -39px;
      color: #ed6a5a;
      font-size: 14px;
      letter-spacing: 1px;
    }
  }
  .tagItemReadMore,
  .userItemReadMore,
  .genericItemReadMore {
    bottom: -40px;
    left: 50%;
    position: absolute;
    .translate(-50%, -50%);
    background: #ed6a5a;
    border: 5px solid @white;
    .box-shadow(0 2px 6px rgba(0, 0, 0, 0.1));
    .border-radius(30px);
    padding: 4px 20px;
    letter-spacing: 3px;
    a {
      color: @white;
    }
  }
}

.tz-slide-down {
  bottom: 26px;
  left: 50%;
  position: fixed;
  .translate(-50%, 0px);
  cursor: pointer;
  visibility: visible;
  opacity: 1;
  &:hover {
    i {
      color: #ed6a5a;
    }
  }
  &.tz-hidden {
    opacity: 0;
    visibility: hidden;
  }
  i {
    color: @white;
    display: block;
    line-height: 10px;
    .transition(all 0.3s ease 0s);
    &.tz-top {
      opacity: 0.2;
    }
    &.tz-middle {
      opacity: 0.4;
    }
  }
}

.tz-main-body-wrapper {
  padding: 60px 0;
}

/* Footer Home 4 */
.tz-footer-home-4 {
  padding: 30px 0 28px;
  > .container > .row {
    display: flex;
    align-items: center;
  }
  .term_footer,
  .copyright_footer {
    font-size: 16px;
    font-weight: 300;
    color: rgba(18, 13, 20, 0.7);
    padding: 0 !important;
    a {
      color: rgba(18, 13, 20, 0.7);
    }
  }
}

/* End Footer Home 4 */