@media (max-width: 991px) {

  /* Menu Style 1 */
  .tz_menu_style1 {
    .tz-header {
      padding: 0 5px;
      &.tz-fixed {
        #plazart-mainnav .plazart-megamenu ul.level0 > li > a {
          padding: 15px 0 !important;
        }
      }
    }
    #plazart-mainnav {
      .level0 > li > a {
        padding-left: 15px;
        padding-right: 15px;
      }
      .navbar-toggle {
        margin: 20px 0 17px;
        padding: 0;
        display: block;
        text-align: left;
      }
      .navbar-collapse {
        position: fixed;
        left: 0;
        right: 0;
        background: #120d14;
        .plazart-megamenu {
          background: #120d14;
          padding: 0;
          margin: 0 20px;
        }
      }
    }
  }

  /* End Menu Style 1 */
  /* Home 3 */
  .ei-slider .tz-info .text-style-1 {
    font-size: 52px;
  }

  /* End Home 3 */
  /* Tour */
  #jevents_body #jev_maincal {
    padding-bottom: 30px;
  }

  /* End Tour */
  /* Contact */
  .view-contact .contact .tz-right,
  .view-contact .contact .tz-left {
    display: block;
    height: auto !important;
  }

  .view-contact .contact .tz-left {
    padding-top: 15%;
    padding-bottom: 35px;
  }

  .view-contact .contact .tz-right {
    padding-bottom: 15%;
    padding-top: 35px;
  }

  #jevents_body .new-navigation, #jevents_body .month_date {
    display: block;
  }

  #jevents_body .jev_toprow div.previousmonth, #jevents_body .jev_toprow div.nextmonth {
    min-width: 100px;
  }

  /* End Contact */
  /* Menu Style 2 */
  .tz_menu_style2 {
    #plazart-mainnav {
      .btn-menu-custom.btn-action-fixed {
        display: none;
      }
      .navbar-inner {
        .btn-menu-custom {
          position: relative;
          top: auto;
          .translate(0, 0);
          padding: 20px 0;
          display: block;
        }
      }
      .plazart-megamenu ul.level0 > li > a {
        padding: 15px;
      }
    }
  }

  /* End Menu Style 2 */
  /* menu style 3 */
  .menu {
    padding: 0 30px;
  }

  .tz-menu-fixed.tz-full {
    .menu__level {
      li {
        width: 50%;
      }
    }
  }

  /* end menu style 3 */
  /* blog  991 */
  .itemListView .itemList div.catItemBody {
    margin: -80px 50px 0;
  }

  /* end blog */
  /* blog item 991*/
  .itemView div.itemBody {
    margin: -80px 30px 0;
  }

  div.itemNavigation {
    margin: 40px 30px;
  }

  div.itemNavigation .tz-table-cell h5 {
    width: auto;
  }

  div.itemAuthorBlock {
    margin: 20px 30px 35px;
    div.itemAuthorDetails {
      padding: 30px;
    }
  }

  div.itemAuthorLatest, div.itemRelated {
    margin: 20px 30px 0;
    .tz-title {
      font-size: 72px;
      margin-bottom: 5px;
    }
    .items {
      column-count: 1;
    }
  }

  div.itemComments {
    margin: 0 30px;
  }

  div.itemCommentsForm form input.inputbox:last-child {
    margin-bottom: 21px;
  }

  /* blog item end */
  .parallax.top .title {
    margin-bottom: -55px;
  }

  .tz-ticket-event {
    padding-bottom: 50px;
  }

  div.k2ItemsBlock.tz_ziczac ul li {
    width: 50%;
    float: left;
    background: transparent;
    margin-bottom: 40px;
    &.even {
      padding-right: 20px;
    }
    &.odd {
      padding-left: 20px;
    }
    .tz-table-cell {
      width: 100%;
    }
    .tz-content {
      background: @white;
      .box-shadow(0 0 6px rgba(0, 0, 0, 0.09));
      padding: 30px !important;
    }
  }

  .tz-module.tz_ziczac .module-title {
    text-align: center;
  }

  div.k2ItemsBlock.tz_ziczac .tz-table-cell.tz-content {
    position: relative;
    top: 0;
    .translate(0, 0);
  }

  .parallax .title {
    font-size: 100px;
    margin-top: -50px;
  }

  .footer_page_home .tz-footer-title {
    color: @white;
    font-size: 60px;
    text-align: left;
    letter-spacing: 5px;
  }

  .tz-plus-gallery-margin {
    padding: 0;
  }

  .tz-padding-sm-plus-gallery {
    .module-ct {
      padding: 0 !important;
    }
    .tz-plus-gallery-musika {
      padding-top: 0;
      padding-right: 0;
      padding-left: 0;
    }
  }

  .tz-block-plus {
    .container {
      width: 100%;
    }
  }

  .tz-plus-gallery-musika .plusgallery .musika {
    border: none;
  }

  .text-center-sm {
    text-align: center;
    .term_footer {
      text-align: center;
    }
  }

  .tz-block-footer {
    padding-bottom: 43px;
  }

  .social_footer_home_page {
    &:before, &:after {
      content: none;
    }
  }

  .copyright_footer.homepage {
    padding-top: 40px;
    padding-bottom: 5px;
  }

  .tz-next-show .mod_events_latest_content a {
    font-size: 36px;
  }

  .tz-next-show .tz-right {
    font-size: 12px;
  }

  .tz-ticket-event .tz-table-cell {
    width: auto;
    display: block;
    padding: 0 100px !important;
    margin: auto auto 35px;
    text-align: center;
  }

  //menuuuu
  .tz-menu-fixed.tz-fixed {
    #plazart-mainnav .navbar-toggle {
      margin: 0;
    }
    #plazart-mainnav .plazart-megamenu ul.level0 > li > a {
      padding: 15px 0;
    }
  }

  #plazart-mainnav {
    .navbar-header {
      text-align: left;

    }
    .tz-close-menu {
      top: 25px;
      .translate(0, 0);
    }
    .navbar-toggle {
      float: none;
      font-weight: 300;
      padding: 19px 24px;
      border: none;
      .btn-menu-custom {
        position: relative;
        display: block;
        .translate(0, 0);
        top: 0;
        left: 0;
      }
    }
    .nav-collapse {
      border: none;
      .box-shadow(none);
      padding-bottom: 30px;
    }
    .btn-menu-custom {
      display: none;
    }
    .plazart-megamenu {
      overflow: auto;
      margin: 0 20px;
      border-top: 1px solid rgba(255, 255, 255, 0.3);
      ul.level0 {
        margin: 0;
        width: auto;
        display: block;
        > li {
          text-align: left;
          width: 100%;
          > a {
            display: block;
            padding: 15px 0;
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
          }
        }
      }
      .dropdown-menu {
        text-align: left;
        a {
          display: block;
          padding: 15px 20px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        }
        .row > div {
          padding: 0;
        }
      }
      .mega-inner {
        padding: 0;
      }
    }
  }

  .btn-menu-custom {
    padding: 26px 10px;
  }

  .block-intro {
    padding: 0 20px;
  }

  .tz-slide-home-2 {
    &.owl-carousel .owl-item img {
      max-width: 300px;
    }
    .tz-table-cell {
      display: block;
      &:last-child {
        width: 100%;
        padding-left: 0;
        padding-right: 50px;
      }
    }
    .tz-info {
      padding: 20px 0;
    }
    .tz-image {
      padding: 0;
    }
    .tz-title {
      font-size: 30px;
    }
    .owl-controls .owl-nav {
      bottom: -40px;
    }
    .tz-date {
      right: 50px;
      font-size: 250px;
      span {
        line-height: 250px;
      }
    }
  }

  .tz-album-featured-padding {
    .tz-category-fixed {
      font-size: 150px;
      right: 0;
      max-width: 100%;
    }
    .tz-albums {
      .item-album {
        display: block;
        padding: 90px 0;
        .tz-media {
          width: 100%;
          .tz_portfolio_plus_image {
            margin: 0;
          }
          .tz_image_hover {
            max-width: 100%;
            right: -70px;
          }
          a {
            position: relative;
            margin: 0;
            width: 50%;
          }
        }
      }
      .tz-content {
        padding: 15px 0 0 0;
        width: 100%;
      }
      .tz-navigation {
        margin-top: 30px;
      }
      .tz-description {
        margin-bottom: 30px;
      }
    }
  }

  .tz_slide_new {
    padding-left: 50px;
  }

  .tz-section-contact {
    padding: 0 150px 0 30px;
    .TzContact .contact-address > div {
      float: left;
      width: 33.33%;
      padding: 0 5px 30px 0;
      h4 {
        margin-top: 0 !important;
      }
    }
  }

  .tz-live-video {
    .module-title {
      margin: 0;
    }
    .owl-carousel {
      padding: 0 45px;
    }
    .active.center .item-video .tz_portfolio_plus_video {
      .scale(1);
    }
    .owl-carousel .owl-stage-outer {
      padding: 0 0 85px;
    }
  }

  .tz-slide-video .module-title {
    font-size: 150px;
    position: relative;
    top: auto;
    margin-left: 30px;
    line-height: 110px;
  }

  .tz_slide .module-title {
    line-height: 70px;
  }

  .tz_slide .module-title,
  .tz-section-contact .module-title {
    font-size: 100px;
  }

  /*----- List Product -----*/
  .hikashop_products_listing_main {
    padding-bottom: 30px;
  }

  .hikashop_product.img:first-child .hikashop_listing_img {
    padding-top: 20px;
  }

  /* HOme 4*/
  .tz_trending_article .display-flex .tz_label {
    width: 12%;
  }

  .tz_trending_article .display-flex .owl-carousel {
    width: 88%;
  }

  .tz-artist-home-4 .tz-artists .tz-content {
    width: 60%;
    padding-left: 50px;
  }

  .tz-artist-home-4 .tz-artists .tz-media {
    width: 40%;
  }

  .tz-shop .parallax .title {
    margin-top: -215px;
  }

  .tz-padding-featured-album-home4 .tz-albums .tz-media a {
    margin-right: 40px;
  }

  .tz-padding-featured-album-home4 .parallax.top .title {
    margin-top: 0;
    margin-bottom: -190px;
  }

  .tz-padding-featured-album-home4 {
    padding-top: 60px;
  }

  .rev_slider_wrapper .tz-button-style-1,
  .rev_slider_wrapper .tz-button-style-2 {
    width: 130px;
  }

  /*Revo Silder*/
  .rev_slider_wrapper .tz-layer-block {
    width: 350px;
    height: 350px;
  }

  /* Home 5 */
  .tz_newsletter .tz-form .tz-size-lagre {
    font-size: 150px;
  }

  .tz_newsletter .tz-form .tz-size-medium {
    font-size: 50px;
    letter-spacing: 15px;
  }

  .tz_newsletter .tz-form .text-header {
    margin: -120px 0 -60px;
  }

  .tz-block-footer-home5 {
    padding: 25px;
    .term_footer.homepage,
    .copyright_footer {
      font-size: 14px;
    }
    .term_footer a {
      margin: 0 5px;
    }
    .display-flex .social_footer_home_page a {
      margin: 0 5px;
    }
  }

  .tz-album-feature-home-5 {
    padding: 0 100px;
    .tz_portfolio_plus_article .tz-content {
      width: 100%;
    }
  }

  /* End Home 5 */

}