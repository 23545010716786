@media (max-width: 320px) {
  .tz-slide-home-2 {
    &.owl-carousel .owl-item img {
      max-width: 150px;
      margin: 0;
    }
    .tz-date {
      font-size: 100px;
      right: 0;
      span {
        line-height: 100px;
      }
    }
    .tz-navigation {
      .tz-actions {
        margin-left: 20px;
      }
    }
  }

  .k2SearchBlock.tz_search_top .k2SearchBlockForm {
    min-width: 280px;
  }

  .text-page {
    font-size: 24px;
    top: -15px;
    margin-right: 0;
    padding-left: 0;
  }

  /*Home 5*/
  .tz-album-feature-home-5 {
    .tz_portfolio_plus_article {
      .tz-description {
        margin-bottom: 20px;
      }
      .tz-button-custom,
      .tz-read-more {
        padding-left: 15px;
        padding-right: 15px;
      }
      .tz-button-custom {
        margin-left: 10px;
      }
      .tz-additional .muted {
        font-size: 14px;
      }
      .tz-title {
        font-size: 30px;
      }
    }
  }

  .tz_newsletter .tz-form .tz-size-lagre {
    display: none;
  }

  .tz-block-footer-home5 {
    padding: 15px;
    .display-flex .social_footer_home_page i {
      font-size: 20px;
    }
  }

  /* Home 4 */
  .tz-artist-home-4 .tz-artists .item-artists .tz-title {
    font-size: 50px;
  }

  .tz-footer-home-4 .term_footer, .tz-footer-home-4 .copyright_footer {
    font-size: 15px;
  }

  .tz-footer-home-4 .term_footer a, .tz-footer-home-4 .copyright_footer a {
    margin: 0 5px;
    font-size: 15px;
  }

  .tz_trending_article{
    font-size: 16px;
  }
}