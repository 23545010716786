
.module-title {
  letter-spacing: 5px;
  color: #888888;
  em {
    font-style: normal;
    color: #ed6a5a;
  }
}

.tz-module {
  //Contact Home Page
  .TzContact {
    color: #cecece;
    a {
      color: #cecece;
    }
    .contact-icon {
      min-width: 25px;
    }
    .info,
    .contact-misc {
      letter-spacing: 0.3px;
    }
    .contact-address {
      h4 {
        font-size: 20px;
        letter-spacing: 1px;
        margin-top: 45px;
        line-height: 1.4em;

      }
      > div {
        > div {
          margin-bottom: 8px;
        }
        &:first-child {
          h4 {
            &:first-child {
              margin-top: 0;
            }
          }
        }
      }
    }
    .contact-miscinfo {
      margin-bottom: 50px;
    }
    #contact-form {
      input, textarea {
        background: rgba(255, 255, 255, 0.15);
        border: none;
        .border-radius(30px);
        color: @white;
        padding: 10px 20px;
        width: 100%;
        line-height: 1.7em;
        margin-bottom: 35px;
        letter-spacing: 0.3px;
      }
      textarea {
        padding-top: 20px;
        margin-bottom: 40px;
        max-height: 185px;
      }
      .emailCopy {
        input {
          width: auto;
          position: relative;
          margin: 0 10px 0 0;
        }
        label {
          font-weight: normal;
        }
      }
    }
    .tz-btn-contact {
      border: medium none;
      .border-radius(30px);
      color: #120d14;
      padding: 10px 25px 9px;
      font-size: 17px;
      text-transform: uppercase;
      letter-spacing: 2px;
      background: #eed5b2;
      &:hover {
        background: #ed6a5a;
        color: @white;
      }
      i {
        margin-right: 5px;
        position: relative;
        top: 2px;
      }
    }
    #message-sent-false, #message-sent {
      display: none;
    }
  }
}

//tz Latest
div.k2ItemsBlock.tz_ziczac {
  .box-shadow(0 0 6px rgba(0, 0, 0, 0.09));
  ul {
    padding: 0;
    li {
      margin: 0;
      background: @white;
      position: relative;
      &:hover {
        .moduleItemImage {
          &:after {
            width: 10px;
          }
          &:before {
            height: 10px;
          }
        }
        .tz-line {
          &:after {
            width: 10px;
          }
          &:before {
            height: 10px;
          }
        }
      }
      &:after {
        content: '';
        clear: both;
        display: block;
      }
      &.even {
        .tz-table-cell {
          float: left;
        }
      }
      &.odd {
        .tz-table-cell {
          float: right;
        }
      }
    }
  }
  .tz-table-cell {
    vertical-align: middle;
    width: 50%;
    display: inline-block;
    &.tz-content {
      position: absolute;
      top: 50%;
      .translate(0, -50%);
    }
    &.tz-media {
      position: relative;
    }
    .tz-line {
      &:after, &:before {
        background: #d09e6e;
        content: "";
        display: block;
        right: 0;
        position: absolute;
        z-index: 1;
        .transition(all 0.3s ease 0s);
      }
      &:after {
        height: 100%;
        width: 0;
        top: 0;
      }
      &:before {
        height: 0;
        bottom: 0;
        width: 100%;
      }
    }
    .moduleItemImage {
      position: relative;
      display: block;
      img {
        width: 100%;
      }
      &:after, &:before {
        background: #d09e6e;
        content: "";
        display: block;
        left: 0;
        position: absolute;
        top: 0;
        .transition(all 0.3s ease 0s);
      }
      &:after {
        height: 100%;
        width: 0;
      }
      &:before {
        height: 0;
        width: 100%;
      }
    }
    &.tz-content {
      padding: 30px 45px;
      text-align: center;
    }
    .moduleItemTitle {
      margin: 0 0 12px;
      color: #120d14;
      letter-spacing: 1px;
      line-height: 1.3em;
      display: block;
      font-size: 36px;
      text-transform: uppercase;
    }
    .moduleItemReadMore {
      display: inline-block;
      margin-top: 37px;
      color: @white;
      padding: 13px 45px;
      background: #daae81;
      letter-spacing: 3px;
      .border-radius(30px);
      text-transform: uppercase;
      font-size: 16px;
      &:hover {
        background: #ed6a5a;
      }
    }
    .moduleItemIntrotext {
      margin-top: 29px;
      padding: 0;
      p {
        color: #505050;
        font-size: 13px;
        line-height: 1.85em;
      }
    }
    .tz-information {
      * {
        font-size: 20px;
        color: #888888;
        text-transform: uppercase;
      }
      > * {
        display: inline-block;
        border: none;
        margin: 0;
        padding: 0;
        letter-spacing: 1px;
        &:after {
          content: '/';
          margin: 0 5px;
        }
        &:last-child::after {
          content: none;
        }
      }
    }
  }
}

//live Video
.tz-slide-video {
  padding: 0;
  .module-title {
    color: rgba(255, 255, 255, 0.07);
    font-size: 250px;
    line-height: 150px;
    margin: 0 0 -106px -175px;
    letter-spacing: 20px;
  }
}

.tz-live-video {
  overflow: hidden;
  .owl-carousel {
    padding: 0 290px;
  }
  .tz-link-btn {
    background: rgba(0, 0, 0, 0.6);
    height: 100px;
    width: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    .translate(-50%, -50%);
    z-index: 1;
    font-size: 48px;
    color: @white;
    text-align: center;
    line-height: 100px;
    .border-radius(50%);
    opacity: 0;
    visibility: hidden;
    .transition(all 0.3s ease 0s);
    i {
      margin-left: 10px;
    }
  }
  .item-video {
    .transition(all 0.3s ease 0s);
    position: relative;
    opacity: 0.5;
    .tz-info,
    .tz_portfolio_plus_video {
      //.transition(all 0.3s ease 0s);
    }
    .owl-video-tn {
      display: none;
    }
    h3 {
      bottom: -60px;
      left: 0;
      margin: 0;
      position: absolute;
      right: 0;
      text-align: center;
      top: auto;
      z-index: 1;
      .transition(all 0.3s linear 0s);
      color: @white;
      letter-spacing: 1px;
    }
    .tz_portfolio_plus_video {
      z-index: 1;
      overflow: hidden;
      img {
        position: relative;
      }
      a {
        display: block;
      }
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
      }
    }
  }
  .tz-navigation {
    color: @white;
    .tz-count {
      display: inline-block;
    }
    .btn-action {
      color: @white;
      cursor: pointer;
      display: inline-block;
      font-size: 8px;
      letter-spacing: 1px;
      margin: 0 40px;
      top: 15px;
      position: relative;
      &.prev {
        text-align: right;
      }
      &.next {
        text-align: left;
      }
      i {
        display: block;
        font-size: 36px;
        &:before {
          display: block;
          line-height: 10px;
        }
      }
    }
  }
  .active.center {
    .item-video {
      opacity: 1;
      .owl-video-wrapper {
        .owl-video-play-icon {
          opacity: 1;
          visibility: visible;
        }
      }
      .tz_portfolio_plus_video {
        .scale(1.5);
        .box-shadow(0 0 10px rgba(0, 0, 0, 0.35));
      }
      .tz-link-btn {
        visibility: visible;
        opacity: 1;
      }
    }
    .tz_portfolio_plus_video::after {
      background: transparent;
    }
  }
  .owl-carousel .owl-stage-outer {
    padding: 120px 0 120px;
    overflow: visible;
  }
}

/* LightBox Video*/
#easy-opener-section {
  display: none;
  right: 0;
  left: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 99999;
}

.easy-opener-bg {
  background: #0d0d0d none repeat scroll 0 0;
  height: 100%;
  left: 0;
  opacity: 0.97;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.easy-opener-content {
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 50%;
  margin: auto;
  width: 1000px;
  position: absolute;
  text-align: center;

}

.easy-opener-content iframe {
  border: none;
}

.easy-opener-content img {
  max-height: 100%;
  max-width: 100%;
}

.easy-opener-close {
  display: inline-block;
  text-align: center;
  position: relative;
  color: #ffffff;
  font-size: 46px;
  margin: 0 auto 40px;
  z-index: 999999;
}

.easy-opener-close:hover {
  color: #e0006c;
  opacity: 1;
}

/* End LightBox Video*/

//tz-social
.tz-social {
  margin: 0;
  .tz-item {
    padding: 0;
    //min-height: 250px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      background: rgba(0, 0, 0, 0.3);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      .transition(all 0.3s ease 0s);
    }
    &:hover {
      &:before {
        background: rgba(0, 0, 0, 0.7);
      }
      .tz-info {
        .tz-description {
          visibility: visible;
          opacity: 1;
          bottom: -28px;
        }
        .tz-title {
          color: #eed5b2;
          margin-top: -10px;
          a {
            color: #eed5b2;
          }
        }
      }
      img {
        -webkit-filter: none;
        -moz-filter: none;
        -ms-filter: none;
        -o-filter: none;
        filter: none;
      }
    }
    img {
      max-width: 100%;
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      -o-filter: grayscale(100%);
      filter: grayscale(100%);
    }
    .tz-info {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      text-align: center;
      .translate(0, -50%);
      z-index: 1;
      margin: 0;
      .tz-title {
        .transition(all 0.3s ease 0s);
        font-size: 48px;
        color: @white;
        a {
          color: @white;
        }
      }
      .tz-description {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
        bottom: 0;
        .transition(all 0.3s ease 0s);
        color: #eed5b2;
      }
    }
  }
}

//text parallax
//
.parallax {
  &.top {
    .title {
      margin-bottom: -115px;
      margin-top: 0;
    }
  }
  .title {
    font-size: 200px;
    color: #ed6a5a;
    font-weight: normal;
    letter-spacing: 15px;
    line-height: 1;
    margin-top: -85px;
    position: relative;
    z-index: 1;
    em {
      color: #daae81;
      font-style: normal;
    }
  }
}

//next-show
.tz-event {
  //padding: 50px 0 220px;
}

.tz-next-show {
  color: @white;
  .tz-right {
    min-width: 250px;
    font-size: 18px;
    letter-spacing: 2px;
  }
  .tz-left {
    width: 100%;
  }
  .mod_events_latest_content {
    a {
      color: @white;
      font-size: 48px;
      display: block;
      letter-spacing: 10px;
    }
  }
  .mod_events_latest_table {
    td {
      border-width: 1px 0 0;
      border-style: solid;
      border-color: rgba(255, 255, 255, 0.1) !important;
      padding: 14px 0;
      &.mod_events_latest_first {
        border-top-width: 1px;
      }
      &:hover {
        background: rgba(0, 0, 0, 0.2);
      }
      .mod_events_latest_content {
        display: block;
      }
    }
  }
}

.tzcountdown {
  text-align: center;
  .tzdivide,
  .tzcountdownitem {
    display: inline-block;
    span {
      font-size: 65px;
      color: @white;
    }
    p {
      color: @white;
      letter-spacing: 2px;
    }
  }
  .tzdivide {
    background: #e2bc93;
    width: 1px;
    height: 25px;
    margin: 0 35px;
    position: relative;
    top: -50px;
  }
}

//tz-ticket-event
.tz-ticket-event {
  padding: 90px 0 65px;
  margin: 0;
  list-style: none;
  .tz-btn {
    color: @white;
    background: #daae81;
    border-color: #daae81;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-size: 16px;
    line-height: 1.4em;
    &:hover {
      background: #ed6a5a;
      border-color: #ed6a5a;
    }
  }
  p {
    font-weight: 400;
    color: #505050;
    line-height: 2em;
    margin-bottom: 30px;
    font-size: 13px;
  }
  .tz-table-cell {
    width: 50%;
    vertical-align: middle;
    &:first-child {
      padding-right: 15px;
    }
    &:last-child {
      padding-left: 85px;
    }
    &.tz-image {
      img {
        width: 100%;
        border: 7px solid #daae81;
        .box-shadow(10px 10px 0 #e5e5e5);
      }
    }
  }
  .tz_event {
    span {
      margin-right: 2px;
      text-transform: uppercase;
    }
  }
  .tz-location {
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  .tz-title {
    font-size: 60px;
    letter-spacing: 5px;
    margin: 0 0 18px;
    line-height: 1.05em;
    em {
      font-style: normal;
      color: #daae81;
    }
    a {
      color: #888888;
      span {
        color: #ed6a5a;
      }
    }
  }
  .tz-addition-info {
    letter-spacing: 2px;
    margin-bottom: 15px;
    a {
      color: #888888;
      &:hover {
        color: #daae81;
      }
    }
    > div {
      display: inline-block;
      color: #888888;
      &:after {
        content: '/';
        margin: 0 5px;
      }
      &:last-child::after {
        content: none;
      }
    }
  }
  .tz_event {
    font-size: 18px;
    color: #888888;
    letter-spacing: 2px;
  }
}

///block 2 home 2
.tz-slide-live-show {
  padding: 0 150px;
}

.tz-slide-home-2 {
  .tz-info {
    padding-bottom: 125px;
    padding-top: 125px;
  }
  &.owl-carousel .owl-item {
    img {
      width: auto;
    }
  }
  .tz-table-cell {
    vertical-align: middle;
    width: 50%;
    &:last-child {
      padding-right: 210px;
      padding-left: 10px;
    }
  }
  .tz-image {
    img {
      margin: auto;
    }
  }

  .tz-location {
    color: @white;
    font-size: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  .tz-title {
    font-size: 60px;
    line-height: 1em;
    margin-top: 5px;
    margin-bottom: 30px;
    letter-spacing: 4px;
    a {
      color: #ed6a5a;
    }
  }
  .tz-description {
    margin-bottom: 37px;
    p {
      color: @white;
    }
  }
  .tz-btn {
    background: #daae81;
    color: #100913;
    font-size: 16px;
    text-transform: uppercase;
    line-height: 1.6em;
    letter-spacing: 4px;
    &:hover {
      background: #ed6a5a;
      color: @white;
    }
  }

  .tz-date {
    font-size: 250px;
    color: rgba(255, 255, 255, 0.1);
    position: absolute;
    top: 50%;
    .translate(0, -50%);
    text-align: right;
    right: 220px;
    span {
      display: block;
      line-height: 260px;
      margin-top: 30px;
    }
  }
  .owl-controls {
    .owl-nav {
      text-align: center;
      position: absolute;
      bottom: 15px;
      left: 50%;
      .translate(-50%, 0);
    }
  }
}

.tz-albums,
.tz-artists,
.tz-slide-home-2 {
  .owl-controls {
    .owl-nav {
      > div {
        color: @white;
        display: inline-block;
        font-size: 8px;
        position: relative;
        padding-bottom: 15px;
        margin: 0 15px;
        &:after, &:before {
          font-family: linea-arrows-10;
          font-size: 36px;
          position: absolute;
          top: 0;
          line-height: 1em;
        }
        &.owl-prev::before {
          content: '\e066';
          right: 0;
        }
        &.owl-next::after {
          content: '\e068';
          left: 0;
        }
      }
    }
  }
}

///

.tz-artists-padding {
  padding: 0 180px 0 150px;
  .module-title {
    color: rgba(255, 255, 255, 0.07);
    font-size: 250px;
    line-height: 177px;
    margin: 0;
    letter-spacing: 20px;
  }
}

.tz-artists {
  .tz-link-detail {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &.owl-carousel .owl-item img {
    position: relative;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
  }
  .item-artists {
    overflow: hidden;
    background: #daae81;
    position: relative;
    .transition(all 0.3s ease 0s);
    &:hover {
      background: #ed6a5a;
      .tz-content {
        background: rgba(0, 0, 0, 0.5);
      }
    }
  }
  .tz-content {
    cursor: pointer;
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1;
    .transition(all 0.3s ease 0s);
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .tz-media {
    height: 100%;
    padding: 15px;
    > div {
      height: 100%;
      overflow: hidden;
    }
  }
  .tz-title {
    font-size: 30px;
    letter-spacing: 3px;
    line-height: 1.3em;
    margin-top: 5px;
    a {
      color: @white;
    }
  }
  .tz-tag {
    color: #eed5b2;
    font-size: 30px;
    letter-spacing: 3px;
    text-transform: uppercase;
    a {
      color: #eed5b2;

    }
  }
  .muted {
    font-weight: 300;
    color: @white;
    margin: 5px 0;
    a {
      color: @white;
    }
  }
  .tz-read-more {
    display: inline-block;
    margin-top: 15px;
  }
  .owl-controls {
    .owl-nav {
      text-align: right;
      margin-top: 40px;
      position: absolute;
      right: 10px;
      > div {
        letter-spacing: 1px;
      }
    }
  }

}

.tz-custom-btn-mod {
  position: relative;
  margin: 45px 155px 0 0;
  float: right;
  font-size: 14px;
  padding: 3px 25px;
  background-color: #daae81 !important;
  border-color: #daae81 !important;
  text-transform: uppercase;
  line-height: 1.6em;
  &:hover {
    background: #ed6a5a !important;
    border-color: #ed6a5a !important;
    color: @white;
  }

}

.tz-album-featured-padding {
  padding: 0 150px;
}

.tz-albums {
  .tz-category-fixed {
    font-size: 250px;
    position: absolute;
    right: 120px;
    text-align: right;
    top: 50%;
    z-index: -1;
    color: rgba(255, 255, 255, 0.1);
    max-width: 70%;
    letter-spacing: 15px;
    line-height: 1.2em;
    .translate(0, -50%);
  }
  .item-album {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    .tz_portfolio_plus_image {
      img {
        &.tz-image {
          z-index: 1;
          position: relative;
          .box-shadow(5px 5px 10px rgba(0, 0, 0, 0.35));
        }
        &.tz_image_hover {
          position: absolute;
          width: auto;
          left: auto;
          right: -30px;
          opacity: 1;
          z-index: 0;
          bottom: auto;
          top: 50%;
          .translate(0, -50%);
        }
      }
    }
  }
  .tz-content {
    width: 50%;
    padding-left: 100px;
    padding-right: 145px;
  }
  .tz-media {
    width: 50%;
  }
  .tz-media a {
    margin-right: 150px;
    display: block;
    .box-shadow(0 0 7px rgba(0, 0, 0, 0.35));
  }
  .tz-title {
    color: #ed6a5a;
    font-size: 60px;
    letter-spacing: 5px;
    line-height: 1em;
    margin-bottom: 10px;
    margin-top: 3px;
    a {
      color: #ed6a5a;
    }
  }
  .tz-additional {
    margin-bottom: 15px;
    text-transform: uppercase;
    .muted {
      display: inline-block;
      color: @white;
      font-size: 20px;
      letter-spacing: 2px;
      &:after {
        content: ' - ';
      }
      &:last-child::after {
        content: none;
      }
      a {
        color: @white;
      }
    }
  }
  .tz-description {
    margin-bottom: 55px;
    p {
      color: @white;
    }
  }
  .tz-read-more {
    background: #daae81;
    border-color: #daae81;
    font-size: 16px;
    letter-spacing: 4px;
    line-height: 1.3em;
    text-transform: uppercase;
    &:hover {
      background: #ed6a5a;
      border-color: #ed6a5a;
      color: @white;
    }
  }
  .tz_categories {
    a {
      color: @white;
      font-size: 20px;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
  }
  .owl-controls {
    .owl-nav {
      bottom: 25px;
      //left: 0;
      margin-left: 60px;
      margin-top: 40px;
      position: absolute;
      right: 0;
      text-align: center;
      > div {
        letter-spacing: 1px;
      }
    }
  }

}

.tz-slide-home-2,
.tz-albums {
  .tz-navigation {
    margin-top: 55px;
    display: flex;
    justify-content: inherit;
    align-items: center;
    align-content: center;
    .tz-actions {
      margin-left: 40px;
      z-index: 1;
      position: relative;
    }
  }
  .btn-action {
    display: inline-block;
    color: @white;
    font-size: 8px;
    cursor: pointer;
    margin: 0 15px;
    letter-spacing: 1px;
    i {
      font-size: 36px;
      display: block;
      &:before {
        line-height: 10px;
        display: block;
      }
    }
    &.prev {
      text-align: right;
    }
    &.next {
      text-align: left;
    }
  }
}

.tz-slide-home-2 {
  .tz-navigation {
    margin-top: 0;
  }
}

.tz-padding-featured-album-home4 {
  padding: 110px 0 145px;
  .parallax.top .title {
    margin-bottom: -260px;
    margin-top: 60px;
  }
  .owl-carousel .owl-item {
    overflow: hidden;
  }
  .tz-albums {
    .tz-media {
      width: 45%;
      a {
        margin-right: 105px;
      }
      .tz_portfolio_plus_image img.tz_image_hover {
        max-width: 90%;
      }
    }
    .tz-content {
      width: 55%;
      padding-right: 0;
    }
    .tz-description p {
      color: #505050;
    }
    .tz-category-fixed {
      color: rgba(18, 13, 20, 0.05);
      font-size: 150px;
      right: 0;
      line-height: 1.2em;
    }
    .tz-additional,
    .tz_categories {
      color: #100913;
      .muted,
      a {
        color: #100913;
      }
    }
    .tz-read-more {
      color: @white;
    }
    .btn-action {
      color: #100913;
    }
  }
}

.tz_slide_new {
  padding-left: 150px;
  .module-title {
    font-size: 250px;
    color: rgba(255, 255, 255, 0.07);
    margin: 0 0 0 -10px;
    line-height: 175px;
    letter-spacing: 15px;
  }
  .owl-item {
    img {
      width: auto;
      max-width: 480px;
    }
    &.last {
      .item-new {
        &:after {
          opacity: 1;
          visibility: visible;
        }
        &:hover {
          background: rgba(0, 0, 0, 0.8);
        }
      }
    }
  }

  .item-new {
    background: #120d14 none repeat scroll 0 0;
    padding-bottom: 65px;
    position: relative;
    .transition(all 0.3s ease 0s);
    &:hover {
      background: #daae81;
    }
    &:after {
      opacity: 0;
      visibility: hidden;
      content: '';
      background: rgba(0, 0, 0, 0.8);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .transition(all 0.3s ease 0s);
    }
  }
  .tz-content {
    background: #ffffff none repeat scroll 0 0;
    bottom: 15px;
    left: 15px;
    position: absolute;
    right: 15px;
    padding: 16px 20px 10px;
  }
  .tz-media {
    overflow: hidden;
    a {
      height: 100%;
      display: block;
    }
    img {
      position: relative;
    }
  }
  .tz-title {
    font-size: 18px;
    color: #120d14;
    letter-spacing: 1px;
    display: block;
    line-height: 1.2em;
    margin-bottom: 6px;
  }
  .tz-muted {
    display: inline-block;
    color: rgba(18, 13, 20, 0.4);
    letter-spacing: 1px;
    font-size: 16px;
    line-height: 1.6em;
    text-transform: uppercase;
    a {
      color: rgba(18, 13, 20, 0.4);
    }
    &:after {
      content: ' / ';
    }
    &:last-child:after {
      content: none;
    }
  }
  .btn-action {
    display: inline-block;
    color: @white;
    font-size: 8px;
    cursor: pointer;
    i {
      font-size: 36px;
      display: block;
      &:before {
        line-height: 10px;
        display: block;
      }
    }
    &.prev {
      text-align: right;
    }
    &.next {
      text-align: left;
    }
  }
  .moduleCustomLink {
    display: inline-block;
    color: @white;
  }
  .tz-navigation {
    margin-left: -150px;
    margin-top: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    .moduleCustomLink {
      padding: 0 20px;
      letter-spacing: 2px;
      font-size: 12px;
      font-weight: 300;
    }
  }
}

.tz-section-contact {
  padding-left: 150px;
  padding-right: 300px;
  .module-title {
    font-size: 250px;
    color: rgba(255, 255, 255, 0.07);
    margin: 0 0 15px;
  }
  .TzContact {
    #contact-form {
      input, textarea {
        color: @white;
        background: rgba(255, 255, 255, 0.1);
      }
      ::-ms-input-placeholder,
      ::-moz-placeholder,
      :-moz-placeholder,
      :-ms-input-placeholder,
      ::-webkit-input-placeholder,
      ::-moz-placeholder {
        color: @white;
      }

    }
  }
}

.tz-section-contact .TzContact ::-webkit-input-placeholder {
  color: @white;
}

.tz-section-contact .TzContact :-moz-placeholder { /* Firefox 18- */
  color: @white;
}

.tz-section-contact .TzContact ::-moz-placeholder { /* Firefox 19+ */
  color: @white;
}

.tz-section-contact .TzContact :-ms-input-placeholder { /* IE 10+ */
  color: @white;
}

.tz-section-contact .TzContact ::-ms-input-placeholder { /* Edge */
  color: @white;
}

.height-fullscreen {
  top: 50%;
  .translate(0, -50%);
  width: 100%;
  left: 0;
  z-index: 1;
  position: absolute;
  .mod_events_latest_table {
    height: 100vh;
  }
}

.intro-block {
  position: absolute;
  top: 50%;
  .translate(0, -50%);
  .tz-title {
    color: @white;
    font-size: 72px;
    letter-spacing: 15px;
    margin: 0 0 20px;
    span {
      color: #ed6a5a;
    }
    a {
      color: #ed6a5a;
    }
  }
  .tz-location {
    font-size: 18px;
    color: @white;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}

.tz-event-static-style-1 {
  .tz-title {
    color: @white;
    font-size: 72px;
    letter-spacing: 15px;
    margin: 0 0 20px;
    span {
      color: #ed6a5a;
    }
    a {
      color: #ed6a5a;
    }
  }
  .tz-location {
    font-size: 20px;
    color: @white;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  .time-countdown {
    &:last-child {
      padding-right: 0;
    }
  }
}

.time_number {
  margin-bottom: 15px;
  line-height: 0;
  &:after {
    clear: both;
    display: block;
    content: '';
  }
}

.digit {
  display: inline-block;
  height: 60px;
  line-height: 60px;
  overflow: hidden;
  float: left;

}

.time-countdown {
  display: inline-block;
  position: relative;
  padding-right: 65px;
  font-size: 65px;
  color: @white;
  text-align: center;
  &:after {
    content: '';
    height: 25px;
    width: 1px;
    background: #e2bc93;
    display: block;
    position: absolute;
    top: 17px;
    right: 30px;;
  }
  .text {
    display: block;
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  &:last-child {
    &:after {
      content: none;
    }
  }
}

//plus gallery
#pgzoomview a:hover {
  background-color: #ed6a5a !important;
}

.tz-plus-gallery-musika {
  padding: 30px 30px 25px;
  background: @white;
  &.style-1 {
    padding: 50px 0 0;
    .box-shadow(none);
    .plusgallery {
      margin: 0;
      .musika {
        border: none;
      }
    }
  }
  .box-shadow(0 0 6px rgba(0, 0, 0, 0.09));
  .plusgallery {
    margin-bottom: 25px;
    .musika {
      border-width: 0 1px 1px 0;
      border-style: solid;
      border-color: @white;
      .effect-musika {
        overflow: hidden;
        &:hover {
          .tz_info {
            opacity: 1;
            background: rgba(0, 0, 0, 0.3);
          }
        }
        img {
          position: relative;
        }
        .musika-btn a,
        .tz_info {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
        }
        .tz_info {
          border: 10px solid #ed6a5a;
          opacity: 0;
          background: rgba(0, 0, 0, 0);
          .transition(all 0.3s linear 0s);
        }
        .musika-info {
          display: flex;
          justify-content: center;
          align-items: center;
          align-content: center;
          height: 100%;
          .title {
            margin: 0;
            color: @white;
            letter-spacing: 2px;
            text-align: center;
            padding: 0 15px;
          }
        }
      }
    }
  }
  .tz_title_album,
  .tz_desc_album {
    text-align: center;
  }
  .tz-link-follow {
    color: #ed6a5a;
    font-size: 24px;
    letter-spacing: 2px;
  }
}

.tz-plus-gallery-margin {
  margin-top: -175px;
}

/* Tz Artist */
.tz-artist-home-4 {
  padding: 110px 0 125px;

  .tz-artists {
    .tz-media {
      width: 30%;
      background: #e2bc93;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 15px;
        left: 15px;
        bottom: -15px;
        right: -15px;
        background: #e5e5e5;
        z-index: -1;
      }
    }
    .tz-content {
      width: 70%;
      position: relative;
      display: inline-block;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
      background: transparent;
      padding-left: 100px;
    }
    .item-artists {
      display: flex;
      align-items: center;
      background: transparent;
      padding: 0 0 20px;
      &:hover {
        .tz-content {
          background: transparent;
        }
      }
      .tz-tag {
        font-size: 18px;
        color: #505050;
        a {
          color: #505050;
        }
      }
      p {
        line-height: 1.9em;
        color: #505050;
        font-size: 13px;
      }
      .tz-title {
        font-size: 60px;
        margin-top: 0;
        line-height: 1em;
        margin-bottom: 20px;
        a {
          color: #888888;
        }
      }
      .muted {
        color: #505050;
      }
      .tz-read-more {
        background: #daae81;
        border-color: #daae81;
        color: @white;
        text-transform: uppercase;
        font-size: 16px;
        letter-spacing: 4px;
        margin-top: 40px;
        &:hover {
          background: #ed6a5a;
          border-color: #ed6a5a;
        }
      }
    }
    .tz_portfolio_plus_image {
      a {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
  .tz-actions {
    position: absolute;
    right: 0;
    z-index: 2;
  }
  .btn-action {
    display: inline-block;
    color: #100913;
    font-size: 8px;
    cursor: pointer;
    margin: 0 15px;
    letter-spacing: 1px;
    i {
      font-size: 36px;
      display: block;
      &:before {
        line-height: 10px;
        display: block;
      }
    }
    &.prev {
      text-align: right;
    }
    &.next {
      text-align: left;
    }
  }
}

/* End Tz Artist */
/*  Tz Shop */
.position-static {
  position: static;
}

.tz-shop-custom-link {
  position: absolute;
  top: 40%;
  right: 0;
  .rotate(-90deg);
  -webkit-transform-origin: 77% 23% 0;
  -moz-transform-origin: 77% 23% 0;
  -ms-transform-origin: 77% 23% 0;
  -o-transform-origin: 77% 23% 0;
  transform-origin: 77% 23% 0;
  color: @white;
  z-index: 1;
  a {
    color: @white;
    background: #daae81;
    text-transform: uppercase;
    .border-radius(20px 20px 0 0);
    padding: 16px 38px 13px;
    letter-spacing: 4px;
    display: block;

  }
}

.tz-shop {
  padding: 170px 0 0;
  position: relative;
  .plazart-animate {
    position: static !important;
  }
  .hikashop_products_listing_main .img_title .hikashop_subcontainer .hikashop_product_name a {
    text-transform: uppercase;
    font-size: 20px;
  }
  .hikashop_products_listing_main .img_title .hikashop_subcontainer .tz_header_product {
    margin-bottom: 33px;
  }
  .hikashop_products_listing .hikashop_product.img_title .hikashop_subcontainer .hk-rating {
    margin: 0;
  }
  .hikashop_products_listing_main .img_title .hikashop_product_price_full {
    font-size: 24px;
  }
  .hikashop_products_listing_main .img_title .hikashop_product_vote {
    margin: 3px 0 6px;
  }
  .parallax .title {
    margin-bottom: 55px;
    margin-top: -255px;
  }
}

/* End Tz Shop */
/* Tz Event Home 4 */
.tz-event-home-4 {
  padding-bottom: 105px;
}

/* End Tz Event Home 4 */
/* Tz Twitter */
.tz-twitter {

  .tztwd-tweet-container {
    background: @white;
    color: #505050;
    font-weight: 300;
    font-style: italic;
    font-size: 16px;
    padding: 0 15%;
    line-height: 1.6em;
    a {
      color: #ed6a5a;
    }
    .tztwd-avatar {
      float: none;
      margin: auto;
    }
    .avata-info {
      font-size: 36px;
      margin-bottom: 15px;
      a {
        color: #888888;
      }
    }
  }
}

.tztwd-container {
  margin-top: 40px;
}

.tztwd-header {
  .tztwd-avatar {
    float: none;
    margin: 0;
  }
  .tztwd-twitter-icon a {
    float: none;
    display: inline-block;
  }
}

/* End Tz Twitter */

/* Tz Slide Article Home 4*/
.tz-article-home-4 {
  background: #101010;
}

.tz_article_slide {
  position: relative;
  &:hover {
    .btn-action {
      opacity: 1;
    }
  }
  .btn-action {
    position: absolute;
    top: 50%;
    margin-top: -22px;
    z-index: 1;
    font-size: 30px;
    color: @white;
    cursor: pointer;
    opacity: 0;
    .transition(all 0.3s ease 0.1s);
    &:hover {
      color: #ed6a5a;
    }
    &.prev {
      left: 30px;
    }
    &.next {
      right: 30px;
    }
  }
  .tz_item {
    overflow: hidden;
    position: relative;
    &:hover {
      &:after {
        border-color: #daae81;
      }

    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 5px solid transparent;
      .transition(all 0.3s linear 0.1s);
      background: rgba(0, 0, 0, 0.58);
    }
    .tz-category {
      a {
        text-transform: uppercase;
        color: @white;
        padding: 6px 10px 4px;
        letter-spacing: 2px;
        background: #ed6a5a;
        .border-radius(4px);
        display: inline-block;
        font-size: 15px;
        line-height: 1em;
      }
    }
    .tz-title {
      margin-top: 15px;
      a {
        color: @white;
        font-size: 18px;
        letter-spacing: 3px;
      }
    }
    .tzDateCreated {
      font-weight: 300;
      font-style: italic;
      color: @white;
      font-size: 12px;
      margin-top: 8px;
    }
    > img {
      width: auto;
      position: relative;
    }
    .tz-content {
      position: absolute;
      bottom: 30px;
      right: 0;
      left: 0;
      z-index: 1;
      padding: 0 30px;
    }
    &.tz-item-full-height {
      height: 100%;
    }
    &.tz-item-height-50 {
      height: 50%;
    }
    &.tz-item-height-60 {
      height: 66.67%
    }
    &.tz-item-height-30 {
      height: 33.33%;
    }
  }
}

/* End Tz Slide Article Home 4*/
.tz_trending_article {
  padding: 30px 0;
  text-transform: uppercase;
  font-size: 18px;
  color: @white;
  letter-spacing: 3px;
  .tz_trending_article {
    padding: 0;
  }
  a {
    color: #daae81;
  }
  .display-flex {
    .tz_label {
      width: 7%;
    }
    .owl-carousel {
      width: 93%;
      padding-left: 5px;
    }
  }
}

/**/
.tz_portfolio_plus_article {
  &:after {
    clear: both;
    content: "";
    display: block;
  }
  .tz-content {
    float: right;
    width: 42%;
    padding-right: 80px;
    z-index: 1;
    position: relative;
  }

  .tz-category-fixed {
    color: rgba(255, 255, 255, 0.1);
    font-size: 250px;
    letter-spacing: 15px;
    line-height: 1.2em;
    max-width: 70%;
    position: absolute;
    right: 70px;
    text-align: right;
    top: 50%;
    .translate(0px, -50%);
    z-index: 0;
  }
  .tz_categories a {
    color: #ffffff;
    font-size: 20px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  .tz-title {
    color: #ed6a5a;
    font-size: 60px;
    letter-spacing: 5px;
    line-height: 1em;
    margin-bottom: 10px;
    margin-top: 3px;
    a {
      color: #ed6a5a;
    }
  }
  .tz-description {
    margin-bottom: 55px;
    p {
      color: #ffffff;
    }
  }
  .tz-additional {
    margin-bottom: 15px;
    text-transform: uppercase;
    a {
      color: @white;
    }
    .muted {
      color: #ffffff;
      display: inline-block;
      font-size: 20px;
      letter-spacing: 2px;
    }
  }
  .tz-btn {
    font-size: 16px;
    letter-spacing: 4px;
    line-height: 1.3em;
    text-transform: uppercase;
    padding: 12px 40px 11px 40px;
    .transition(all 0.3s ease 0.05s);
  }
  .tz-read-more {
    background: #daae81 none repeat scroll 0 0;
    border-color: #daae81;
    &:hover {
      background: #ed6a5a;
      color: @white;
      border-color: #ed6a5a;
    }
  }
  .tz-button-custom {
    border-width: 1px;
    border-color: #eed5b2;
    color: #eed5b2;
    margin-left: 20px;
    &:hover {
      background: #eed5b2;
      color: #222222;
    }
  }
}

/*Tz Newsletter Home 5*/
.tz_newsletter {
  padding: 0;
  .tz-form {
    .text-header {
      margin: -145px 0 -95px;
    }
    .tz-size-lagre {
      color: rgba(255, 255, 255, 0.05);
      font-size: 250px;
      letter-spacing: 20px;
    }
    .tz-size-medium {
      color: #ed6a5a;
      font-size: 72px;
      letter-spacing: 20px;
      margin-bottom: 17px;
      margin-top: 0;
    }
    .tz-size-small {
      color: @white;
      font-style: italic;
      font-weight: 300;
    }
    .mc-field-group {
      width: 35%;
      position: relative;
      margin: 70px auto 0;
      .tz-button {
        color: #120d14;
        position: absolute;
        top: 0;
        right: 0;
        .border-radius(0 30px 30px 0);
        background: #eed5b2;
        bottom: 0;
        padding: 17px 40px;
        line-height: 1em;
        text-transform: uppercase;
        letter-spacing: 4px;
        font-size: 16px;
      }
    }
    .email {
      background: transparent;
      border: 1px solid #eed5b2;
      width: 100%;
      .border-radius(30px);
      padding: 13px 30px;
      line-height: 1.5em;
      color: rgba(255, 255, 255, 0.4);
      font-style: italic;
      font-weight: 300;
    }
  }
}

/*End Tz Newsletter Home 5*/

.tz-block-footer-home5 {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 33px 150px;
  background: #2c2c2c;
  .display-flex {
    > div {
      width: 33.33%;
      margin: 0;
      padding: 0;
    }
    .social_footer_home_page {
      a {
        display: inline-block;
        margin: 0 13px;
      }
      &:after, &:before {
        content: none;
      }
      i {
        font-size: 30px;
        color: #eed5b2;
        display: block;
      }
    }
  }
}

/* Tz Video Home 1 */
.tz-video-fullscreen {
  .TzStatistic {
    video {
      left: 50%;
      min-height: 100%;
      min-width: 100%;
      position: absolute;
      top: 50%;
      .translate(-50%, -50%);
      z-index: 0;
    }
  }
}

/* End Tz Video Home 1 */

/*Musika*/

.tz-playlist {
  z-index: 3;
}

/* Tz Full Height */
.tz-full-height {
  height: 100vh;
  > div > .row > div,
  > div > .row,
  > div {
    height: 100vh;
  }
}

/* Revo Slider */
.rev_slider_wrapper {
  .tz-text-style-1 {
    font-size: 72px;
    color: @white;
    letter-spacing: 15px;
    span {
      color: #ed6a5a;
    }
  }
  .tz-block {
    width: 100%;
    a {
      display: inline-block;
      background: @white;
      color: #150918;
      text-transform: uppercase;
      font-size: 16px;
      letter-spacing: 4px;
      .border-radius(30px);
      margin-top: 45px;
      padding: 15px 35px;
    }
  }
  .tz-text-style-2 {
    color: @white;
    font-size: 60px;
    letter-spacing: 15px;
    line-height: 1.3em;
    em {
      font-size: 62px;
      display: block;
      font-style: normal;
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 4px;
      //margin-top: 20px;
    }
    span {
      color: #ed6a5a;
    }
  }
  .tz-text-style-3 {
    font-size: 20px;
    text-transform: uppercase;
    color: @white;
    letter-spacing: 3px;
    margin-top: 17px;
  }
  .tz-text-style-4 {
    font-size: 24px;
    color: @white;
    text-transform: uppercase;
    letter-spacing: 3px;
  }
  .tz-layer-block {
    display: flex;
    align-items: center;
    height: 456px;
    width: 456px;
    .border-radius(50%);
    background: #ed6a5a;
    text-align: center;
  }
  .tz-icon {
    font-size: 80px;
    color: #ed6a5a;
  }
  .tz-button-style-1 {
    min-width: 130px;
    padding: 13px 30px;
    text-transform: uppercase;
    background: #ed6a5a;
    color: @white;
    .border-radius(30px);
    letter-spacing: 4px;
    font-size: 18px;
    width: 190px;
    text-align: center;
    display: block;
  }
  .tz-button-style-2 {
    min-width: 130px;
    padding: 13px 30px;
    text-transform: uppercase;
    background: #d9ae84;
    color: #000000;
    .border-radius(30px);
    letter-spacing: 4px;
    font-size: 18px;
    width: 190px;
    text-align: center;
    display: block;
  }
  .tp-rightarrow.custom {
    background: none;
    height: auto;
    width: auto;
    &:after {
      content: '\f105';
      font-size: 60px;
      color: @white;
      font-family: FontAwesome;
    }
  }
  .tp-leftarrow.default {
    background: none;
    height: auto;
    width: auto;
    &:after {
      content: '\f104';
      font-size: 60px;
      color: @white;
      font-family: FontAwesome;
    }
  }
}