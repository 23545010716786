//animation-------------------------------------------------------------------------------------------------------------

@-webkit-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

@-moz-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -moz-transform: scale(0.5);
  }

  60% {
    opacity: 1;
    -moz-transform: scale(1.2);
  }

  100% {
    -moz-transform: scale(1);
  }
}

@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

/****************
* bounceInLeft *
****************/
@-webkit-keyframes bounceInLeft {
  0% {
    opacity: 0;

    -webkit-transform: translateX(-400px);
  }
  60% {

    -webkit-transform: translateX(30px);
  }
  80% {
    -webkit-transform: translateX(-10px);
  }
  100% {
    opacity: 1;

    -webkit-transform: translateX(0);
  }
}

@keyframes bounceInLeft {
  0% {
    opacity: 0;

    transform: translateX(-400px);
  }
  60% {

    transform: translateX(30px);
  }
  80% {
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;

    transform: translateX(0);
  }
}

/****************
* bounceInRight *
****************/

@-webkit-keyframes bounceInRight {
  0% {
    opacity: 0;

    -webkit-transform: translateX(400px);
  }
  60% {

    -webkit-transform: translateX(-30px);
  }
  80% {
    -webkit-transform: translateX(10px);
  }
  100% {
    opacity: 1;

    -webkit-transform: translateX(0);
  }
}

@keyframes bounceInRight {
  0% {
    opacity: 0;

    transform: translateX(400px);
  }
  60% {

    transform: translateX(-30px);
  }
  80% {
    transform: translateX(10px);
  }
  100% {
    opacity: 1;

    transform: translateX(0);
  }
}

// about----------------------------------------------------------------------------------------------------------------
a:hover {
  text-decoration: none;
}

.about_page {
  background-size: initial;
  background-position: center 50%;
  padding-top: 148px;
  height: 100vh;
  z-index: 1;
  position: relative;
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(14, 14, 14, 0.8);
    top: 0;
    left: 0;
    z-index: -1;
  }
  &:before {
    content: "";
    z-index: -1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    background: -moz-linear-gradient(top, rgba(14, 14, 14, 0) 0%, rgba(14, 14, 14, 0) 17%, rgba(14, 14, 14, 0.18) 34%, rgba(14, 14, 14, 0.8) 89%, rgba(14, 14, 14, 1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(14, 14, 14, 0) 0%, rgba(14, 14, 14, 0) 17%, rgba(14, 14, 14, 0.18) 34%, rgba(14, 14, 14, 0.8) 89%, rgba(14, 14, 14, 1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(14, 14, 14, 0) 0%, rgba(14, 14, 14, 0) 17%, rgba(14, 14, 14, 0.18) 34%, rgba(14, 14, 14, 0.8) 89%, rgba(14, 14, 14, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=0); /* IE6-9 */
  }
  .description-about {
    display: block;
    text-align: center;
    color: #bcbcbc;
    font-size: 16px;
    p {
      margin: 0 auto;
      padding: 0 20%;
      line-height: 26px;
      color: rgba(255, 255, 255, 0.7);
      font-weight: 300;
      font-size: 15px;
    }
    h3 {
      color: #ffffff;
      text-transform: uppercase;
      font-size: 60px;
      font-weight: 400;
      position: relative;
      margin-bottom: 23px;
      padding-bottom: 10px;
      letter-spacing: 5px;

      display: inline-block;
      &:before {
        content: '';
        height: 5px;
        width: 100%;
        max-width: 98%;
        bottom: 0;
        background: #ed6a5a;
        position: absolute;
      }
    }
  }
}

.tz-mod-social {
  ul {
    clear: both;
    display: block;
    text-align: center;
    margin-top: 31px;
    padding-left: 0;
    li {
      clear: both;
      display: inline-block;
      list-style-type: none;
      margin: 0 auto;
      padding: 0 10px;
      a {
        color: #ffffff;
        font-size: 16px;
      }
    }
  }
}

div.info_about {
  margin-top: 120px;
  .tz-introtext {
    p {
      color: rgba(255, 255, 255, 0.7);
      font-weight: 300;
      line-height: 24px;
      margin-bottom: 24px;
    }
  }

  .tz-content-k22 {
    margin-left: -25px;
  }
  &.k2ItemsBlock {
    ul {
      li {
        a.moduleItemComments, span.moduleItemHits {
          border-right: 0;
        }
        div.moduleItemIntrotext {
          margin-bottom: 34px;
        }
      }
      li div.moduleItemIntrotext a.moduleItemImage img {
        float: left;
      }
    }
  }
  .tz-left {
    span {

      position: relative;
      z-index: 10;
      display: inline-block;
      clear: both;
      margin-top: 54px;
      margin-left: 100px;
      a {
        display: block;
        border: 1px solid #eed5b2;
      }
      &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border: 1px solid #eed5b2;
        z-index: -1;
        margin-left: -33px;
        margin-top: -33px;
      }
      &:after {
        background: #ed6a5a none repeat scroll 0 0;
        content: "";
        height: 100%;
        left: 0;
        margin-left: -54px;
        margin-top: -54px;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -2;
      }
    }
  }
}

.title-about {
  .tz-category {
    font-size: 12px;
    margin-top: 0;
    a {
      color: #ed6a5a;
      letter-spacing: 3px;
    }
  }
  .tz-title {
    margin-top: 16px;
    margin-bottom: 33px;
    a {
      color: #ffffff;
      font-size: 30px;
      letter-spacing: 7px;
    }
  }
  .moduleItemAuthor {
    color: #ffffff;
    a {
      color: #ffffff;
      text-transform: uppercase;
      font-weight: 400;
      padding-left: 5px;
      &:hover {
        text-decoration: none;
      }
    }
  }

}

.moduleinfo_about {
  margin-top: 110px;
}

.ab_read_more {
  position: relative;
  display: inline-block;
  margin-top: 23px;
  a {
    background: #daae81;
    color: #100913;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    display: inline-block;
    font-size: 15px;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: normal;
    padding: 12px 16px 11px 20px;
    letter-spacing: 2px;
    text-decoration: none;
    line-height: 1em;
    &:active {
      outline: none;
    }
    &:hover {
      background: #ed6a5a;
      color: @white;
    }
  }
  i {
    font-size: 12px;
  }
}

.cd-timeline {
  margin-bottom: 3em;
  margin-top: 3em;
  padding: 2em 0;
  position: relative;
  .tz-timeline-block.lastItem {
    margin-top: 0;
    z-index: 1;
    .tz-timeline-imager {
      text-align: center;
      padding-bottom: 50px;
    }
    .tz-timeline-imager {
      text-align: center;
      padding-bottom: 0;
      .moduleItemImage {
        display: block;
        margin: auto auto 21px auto;
        text-align: center;
      }
    }
    .tz-timeline-content .tz-date {
      top: 0;
      margin-left: 0;
      left: 0;
      position: relative;
      display: inline-block;
      .translate(0, 0);
    }
    .title-timeline {
      h3 {
        margin-top: 30px;
        margin-bottom: 18px;
      }
    }
    .tz-timeline-wow {
      &:after {
        height: 100px;
      }
      &:before {
        content: none;
      }
    }
    .tz-timeline-content {
      width: 50%;
      margin: 90px auto 0;
      text-align: center;
      float: none;
      .tz-date {
        &:before {
          content: none;
        }
      }
    }
  }
}

.cd-container {
  &:after {
    clear: both;
    content: "";
    display: table;
  }
}

.tz-timeline-block {
  position: relative;
  margin: 0;
  overflow: hidden;
  &:first-child {
    margin-top: 0 !important;
    padding-bottom: 120px;
    .moduleItemImage {
      width: auto;
      height: auto;
      border: 30px solid #daae81;
      .border-radius(0);
      max-width: 100%;
    }
    .tz-timeline-imager {
      text-align: center !important;
      img {
        height: auto;
        width: auto;
      }
    }
    .introduce-about, .moduleItemIntrotext, .moduleItemIntrotext, .moduleItemIntrotextmoduleItemIntrotext, .linetime-comments, .moduleItemHits, .tz-date, .tz-line {
      display: none;
    }
    .tz-timeline-content {
      width: 100%;
      text-align: center;
    }
    .tz-timeline-wow::after {
      height: 100%;
      top: 9px;
    }
  }
  &:after {
    clear: both;
    content: "";
    display: table;
  }
  &:last-child,
  &:first-child {
    margin-top: 0;
    .tz-timeline-imager {
      &:after {
        content: none;
      }
    }
  }
  &:nth-child(2n+1) {
    margin-top: -120px;
    text-align: right;
    .tz-timeline-content {
      float: right;
      &:before {
        border-color: rgba(0, 0, 0, 0) #ffffff rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
        left: auto;
        right: 100%;
        top: 24px;
      }
      .tz-date {
        right: auto;
        left: 0;
      }
    }
    .tz-timeline-imager {
      text-align: right;
    }
  }
}

.tz-timeline-wow {
  &:before {
    content: "";
    border: 2px solid #daae81;
    border-radius: 50%;
    height: 12px;
    left: 50%;
    margin-left: -5px;
    position: absolute;
    top: 70px;
    width: 12px;
    z-index: 2;
    background: #875250;
  }
  &:after {
    content: "";
    width: 2px;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 0;
    background: #daae81 none repeat scroll 0 0;
    z-index: 1;
  }
  &.tz-wow {
    background: #875250 none repeat scroll 0 0;
  }
}

.tz-timeline-content {
  margin-left: 0;
  padding: 0 30px;
  width: 50%;
  position: relative;
  z-index: 1;
  &:after {
    clear: both;
    content: "";
    display: table;
  }
  .moduleItemAuthor, .moduleItemHits {
    color: #b7b7b7;
  }
  .tz-timeline-imager {
    position: relative;
    z-index: 3;
    img {

      position: relative;
    }
    a {
      display: inline-block;
      height: 150px;
      width: 150px;
      border: 2px solid #daae81;
      border-radius: 50%;
      overflow: hidden;
    }
    &:after {
      content: '';
      height: 1px;
      width: 100%;
      left: 0;
      top: 50%;
      position: absolute;
      margin-top: 1px;
      z-index: -1;
      background: #eed5b2;
    }
  }
  .moduleItemIntrotext {
    p {
      color: rgba(255, 255, 255, 0.7);
      font-size: 14px;
      line-height: 24px;
    }
  }
  .tz-date {
    background: #eed5b2;
    border-radius: 21px;
    clear: both;
    display: inline-block;
    font-size: 14px;
    right: 0;
    padding: 4px 10px;
    position: absolute;
    text-transform: uppercase;
    top: 50%;
    .translate(0, -50%);
    min-width: 120px;
    text-align: center;
    color: #120d14;
    z-index: 2;
    letter-spacing: 2px;
  }

}

.title-timeline {
  h5, .moduleItemAuthor {
    a {
      color: #ffffff;
    }
  }
  h3 {
    margin-top: 30px;
    margin-bottom: 15px;
    line-height: 1.2em;
    a {
      color: #ed6a5a;
      letter-spacing: 5px;
    }
  }
}

.linetime-comments {
  a {
    color: #ffffff;
  }
}

.image-about {
  text-align: right;
  padding-right: 75px;
}

.tz-timeline-block.animated {
  &.odd {
    .introduce-about, .moduleItemIntrotext {
      -webkit-animation: bounceInLeft 1200ms;
      -moz-animation: bounceInLeft 1200ms;
      animation: bounceInLeft 1200ms;
    }
  }
  &.even {
    .introduce-about, .moduleItemIntrotext {
      -webkit-animation: bounceInRight 1200ms;
      -moz-animation: bounceInRight 1200ms;
      animation: bounceInRight 1200ms;
    }
  }
  &.lastItem {
    .introduce-about, .moduleItemIntrotext {
      visibility: visible;
      -webkit-animation: cd-bounce-1 1200ms;
      -moz-animation: cd-bounce-1 1200ms;
      animation: cd-bounce-1 1200ms;
    }
  }
}

.about-history {
  padding-top: 70px;
  h3.module-title {
    text-align: center;
    margin: 100px 0;
    span {
      position: relative;
      color: #bfbfbf;
      text-transform: uppercase;
      font-size: 30px;
      font-weight: normal;

      &:before {
        content: '';
        width: 1px;
        height: 70px;
        position: absolute;
        top: 45px;
        left: 50%;
        background: #25221f;
      }
      &:after {
        content: '';
        width: 1px;
        height: 72px;
        position: absolute;
        top: -84px;
        left: 50%;
        background: #25221f;
      }
    }

  }
}

.about-footer {
  padding: 24px 0 78px;
  text-align: center;
}

.tz-abfooter {
  //z-index: 10;
  font-size: 14px;
  color: @white;
  .tz-footer-copyright {
    margin-top: 30px;
    letter-spacing: 3px;
    line-height: 1.7em;
    font-size: 17px;
    text-transform: uppercase;
    font-weight: 300;
  }
}

.bg-abfooter {
  margin-top: -540px;
  padding-top: 575px;
  position: relative;
  z-index: 0;
  &:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 350px;
    top: 0;
    left: 0;
    background: rgba(14, 14, 14, 1);
    background: -moz-linear-gradient(top, rgba(14, 14, 14, 1) 0%, rgba(14, 14, 14, 0) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(14, 14, 14, 1)), color-stop(100%, rgba(14, 14, 14, 0)));
    background: -webkit-linear-gradient(top, rgba(14, 14, 14, 1) 0%, rgba(14, 14, 14, 0) 100%);
    background: -o-linear-gradient(top, rgba(14, 14, 14, 1) 0%, rgba(14, 14, 14, 0) 100%);
    background: -ms-linear-gradient(top, rgba(14, 14, 14, 1) 0%, rgba(14, 14, 14, 0) 100%);
    background: linear-gradient(to bottom, rgba(14, 14, 14, 1) 0%, rgba(14, 14, 14, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0e0e0e', endColorstr='#0e0e0e', GradientType=0);

  }
}

//resposiver
//@media (max-width: 1200px) {
//
//  .tz-timeline-block:nth-child(2n+1) .tz-timeline-content .tz-date:before {
//    z-index: -1;
//    left: 0;
//  }
//}
//
//@media (max-width: 1199px) {
//  .tz-timeline-wow:after {
//    left: 15px;
//    height: 100%;
//  }
//
//  .tz-timeline-imager {
//    text-align: right;
//  }
//
//  .tz-timeline-block:first-child .tz-timeline-wow::after {
//    height: 100%;
//    top: 0;
//  }
//
//  .tz-timeline-block {
//    &:first-child {
//      margin-top: 0 !important;
//      margin-bottom: 0;
//      .tz-timeline-imager {
//        text-align: right !important;
//        img {
//          height: 150px;
//          width: 150px;
//          border: 2px solid #daae81;
//          border-radius: 50%;
//        }
//      }
//      .tz-timeline-content {
//        text-align: left;
//      }
//    }
//  }
//
//  .tz-timeline-wow {
//    &:before {
//      left: 15px
//    }
//  }
//
//  .tz-timeline-block:first-child .introduce-about,
//  .tz-timeline-block:first-child .moduleItemIntrotext,
//  .tz-timeline-block:first-child .moduleItemIntrotext,
//  .tz-timeline-block:first-child .moduleItemIntrotextmoduleItemIntrotext,
//  .tz-timeline-block:first-child .linetime-comments,
//  .tz-timeline-block:first-child .moduleItemHits,
//  .tz-timeline-block:first-child .tz-date,
//  .tz-timeline-block:first-child .tz-line {
//    display: block;
//  }
//
//  .tz-timeline-content {
//    width: 100%;
//    z-index: 10;
//    position: relative;
//    .tz-date {
//      left: 40px;
//      &:before {
//        content: none;
//      }
//    }
//    &:before {
//      background: #eed5b2 none repeat scroll 0 0;
//      content: "";
//      height: 1px;
//      position: absolute;
//      width: 100%;
//      max-width: 90%;
//      z-index: 0;
//      left: 40px;
//      right: auto;
//      top: 73px;
//    }
//  }
//
//  .cd-timeline .tz-timeline-block.lastItem .tz-timeline-content {
//    width: 100%;
//    margin: 0;
//    text-align: left;
//    float: none;
//  }
//
//  .cd-timeline {
//    .tz-timeline-block {
//      &.lastItem {
//        margin-top: 0;
//        .tz-timeline-wow:after {
//          height: 100%;
//        }
//        .tz-timeline-imager {
//          text-align: right;
//          padding-bottom: 0;
//          margin-bottom: 35px;
//          .moduleItemImage {
//            display: block;
//            margin: auto;
//            text-align: center;
//          }
//        }
//        .tz-timeline-content .tz-date {
//          top: 0;
//          margin-left: 0;
//          left: 0;
//          position: relative;
//          display: inline-block;
//        }
//      }
//    }
//  }
//
//  .tz-timeline-content {
//    margin-bottom: 50px;
//  }
//
//  .introduce-about, .moduleItemIntrotext {
//    padding: 0 15px;
//  }
//
//}
//
@media (max-width: 991px) {
  .about_page {
    .description-about p {
      width: 100%;
    }
  }

  .image-about {
    text-align: center;
    padding-right: 0;
  }

  div.info_about .tz-left span {
    padding-left: 0;
    margin-left: 54px;
  }

  div.info_about .tz-content-k22 {
    padding: 15px 30px;
    margin: 0;
  }

  .odd .tz-timeline-content {
    padding-left: 15px;
  }

  .even .tz-timeline-content {
    padding-right: 15px;
  }

  .tz-timeline-block:first-child .tz-timeline-content {
    padding: 0;
  }

}

@media (max-width: 480px) {
  .tz-timeline-block:first-child .moduleItemImage {
    width: 100%;
    border-width: 10px;
  }

  .tz-timeline-wow:after,
  .tz-timeline-wow:before {
    content: none;
  }

  .tz-timeline-content {
    width: 100%;
    padding: 0 15px !important;
  }

  .tz-timeline-block {
    margin: 0 !important;
    padding: 0 0 30px 0;
    &:first-child {
      padding-bottom: 30px;
    }
  }

  .cd-timeline .tz-timeline-block.lastItem .tz-timeline-content {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .image-about {
    padding: 0 15px;
    img {
      max-width: 100%;
    }
  }

  div.info_about .tz-left span {
    margin-left: 55px;
    margin-top: 0;
  }

  .about_page .description-about p {
    padding: 0;
  }
}

@media (max-width: 320px) {
  .tz-timeline-content {
    text-align: center;
    .tz-timeline-imager {
      text-align: center !important;
      a {
        display: block;
        margin: auto;
      }
      &:after {
        content: none;
      }
    }
    .tz-date {
      display: inline-block;
      position: relative;
      .translate(0, 0);
      top: 0;
      margin-top: 15px;
      left: auto !important;
      right: auto !important;
    }
  }
}

//
//@media (max-width: 676px) {
//  .tz-timeline-content:before {
//    content: none;
//  }
//
//  .tz-timeline-imager {
//    text-align: center;
//    padding-bottom: 20px !important;
//  }
//
//  .tz-timeline-wow {
//    &:after {
//      left: 5px;
//      height: 100%;
//    }
//    &::before {
//      left: 5px;
//    }
//  }
//
//  .tz-timeline-content .tz-date {
//    text-align: center;
//    position: static;
//    margin: 0 auto;
//    display: block;
//  }
//
//  .tz-timeline-block:nth-child(2n+1) .tz-timeline-imager, .cd-timeline .tz-timeline-block.lastItem .tz-timeline-imager, .cd-timeline .tz-timeline-block.lastItem .tz-timeline-content .tz-date {
//    text-align: center;
//    margin: 0 auto;
//  }
//
//  .tz-timeline-block:first-child .tz-timeline-imager {
//    text-align: center !important;
//  }
//
//}