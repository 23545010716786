@media (max-width: 480px) {

  /* Menu Style 1 */
  .tz_menu_style1 {
    .tz-header {
      &.tz-fixed {
        #plazart-mainnav .plazart-megamenu ul.level0 > li > a {
          padding: 5px 0 !important;
        }
      }
    }
  }

  /* End Menu Style 1 */
  /* Home 3 */
  .ei-slider .tz-info {
    padding: 0 30px;
    max-width: 100%;
    .text-style-1 {
      font-size: 30px;
    }
    .tz-btn {
      padding: 8px 30px;
    }
  }

  .tz-body-fixed .tz-thumb {
    margin-left: 30px !important;
  }

  .tz-footer-fixed .tz-copyright {
    bottom: 40px;
    right: 30px;
    text-align: center;
  }

  .tz-footer-fixed .tz-social-network {
    bottom: 10px;
    left: 30px;
    text-align: center;
  }

  /* End Home 3 */
  /* Tour */
  #jevents_body .tab-content ul.ev_ul li > .tz-table-cell,
  #jevents_body .jev_listview ul.ev_ul li > .tz-table-cell {
    display: block;
    padding: 15px;
  }

  #jevents_body .tab-content ul.ev_ul li.ev_td_li .time,
  #jevents_body .jev_listview ul.ev_ul li.ev_td_li .time {
    display: inline-block;
  }

  #jevents_body .new-navigation .nav-items > div {
    width: 48%;
    margin-bottom: 10px;
  }

  #jevents_body .new-navigation #nav-search {
    margin: auto;
    display: block;
    text-align: center;
    a {
      display: inline-block;
    }
  }

  #jevents_body .nav-tabs > li {
    width: 100% !important;
  }

  #jevents_body .nav-tabs > li > a h4 {
    display: inline-block;
    margin: 0 5px;
  }

  #jevents .tz-jev-header .ev_fieldset {
    font-size: 36px;
  }

  #jevents_body .jev_toprow div.previousmonth, #jevents_body .jev_toprow div.nextmonth {
    min-width: 0;
  }

  #jevents_body .jev_header2 > * {
    display: block;
    width: 100%;
  }

  #jevents_body .cal_top_day_names .cal_daysnames {
    display: block;
    width: 100%;
    height: auto;
  }

  #jevents_body .cal_top_day_names {
    min-width: 0;
  }

  #jevents_body .cal_table .cal_cell_rows td,
  .cal_cell_rows {
    display: block;
    width: 100%;
    height: auto;
  }

  /* End Tour */
  /* menu style 2 */
  .menu {
    //padding: 0 30px;
  }

  .tz-menu-fixed.tz-full {
    .menu__level {
      li {
        width: 100%;
        a {
          font-size: 28px;
        }
      }
    }
  }

  .menu__wrap {
    .menu__level {
      max-height: 350px;
      overflow-y: auto;
      top: 0;
      .translate(0, 0);
    }
  }

  /* end menu style 2 */
  /* blog 480 */
  .itemListView .itemList div.catItemBody {
    margin: -8px 0 0;
  }

  .itemListView .itemList div.catItemBody > .tz-bottom {
    padding-top: 40px;
    text-align: center;
  }

  .itemListView .itemList div.catItemRatingBlock .itemRatingForm {
    float: none;
  }

  .itemRatingList {
    float: none;
    margin: auto;
  }

  div.catItemRatingBlock span {
    display: none;
  }

  /* end blog */
  /* blog item  480*/
  .itemView div.itemBody {
    margin: -8px 0 0;
  }

  div.itemNavigation {
    margin: 40px 0;
  }

  div.itemNavigation .row > * {
    margin: 10px 0;
  }

  div.itemNavigation .tz-table-cell h5 {
    width: auto;
    a.itemPrevious, a.itemNext {
      font-size: 16px;
    }
  }

  div.itemAuthorBlock {
    margin: 20px 0 35px;
    .tz-table-cell {
      display: block;
    }
    img {
      width: 100%;
    }
  }

  div.itemComments,
  div.itemAuthorLatest, div.itemRelated {
    margin: 20px 0 0;
  }

  div.itemAuthorLatest .tz-title, div.itemRelated .tz-title {
    font-size: 36px;
    margin-bottom: 5px;
  }

  div.itemAuthorLatest .items, div.itemRelated .items {
    column-count: 1;
  }

  div.itemContentFooter .tz-information {

    margin: 0 0 10px;
    > * {
      font-size: 13px;
    }
    .itemRatingLog {
      display: none;
    }
  }

  div.itemContentFooter {
    padding: 30px 15px;
  }

  div.itemAttachmentsBlock ul.itemAttachments li span {
    display: none;
  }

  div.itemCommentsForm .tz-title {
    margin-top: -65px;
    font-size: 45px;
  }

  div.itemCommentsForm {
    padding: 40px 15px;
  }

  .itemView div.itemBody .tz-top {
    padding: 30px 20px;
  }

  .tz-breadcrumbs h2.tz-last-item {
    font-size: 30px;
  }

  /* end blog item */
  #plazart-mainnav {
    .tz-close-menu {
      top: 10px;
      right: 10px;
    }
    .plazart-megamenu {
      margin: 0 15px;
      ul.level0 {
        > li {
          a {
            padding-top: 5px;
            padding-bottom: 5px;
            font-size: 14px;
          }
          > a {
            font-size: 16px;
          }
        }
      }
    }
  }

  .time-countdown {
    font-size: 36px;
    padding-right: 40px;
    &:after {
      right: 20px;
      top: 30px;
    }
  }

  #plazart-mainnav .navbar-toggle {
    padding: 15px 0 15px 15px;
    margin: 0;
  }

  #fp-nav {
    display: none;
  }

  #fullpage {
    .section {
      padding: 0 15px;
    }
  }

  .block-intro {
    padding: 0;
    h3 {
      font-size: 40px;
    }
  }

  .tz-slide-home-2 {
    .tz-table-cell {
      &:last-child {
        padding-right: 0;
      }
    }
    .tz-location {
      font-size: 14px;
    }
    .tz-title {
      font-size: 18px;
      margin-bottom: 15px;
    }
    .tz-description {
      margin-bottom: 15px;
      display: none;
      p {
        font-size: 12px;
      }
    }
    .tz-info {
      padding-right: 0;
      padding-top: 10px;
      padding-bottom: 0;
    }
    .tz-btn {
      padding: 8px 20px;
      font-size: 12px;
    }
    &.owl-carousel .owl-item img {
      max-width: 250px;
      margin: auto;
    }
    .tz-date {
      font-size: 150px;
      span {
        line-height: 150px;
      }
    }
  }

  .tz-section-contact .module-title,
  .tz-slide-video .module-title,
  .tz_slide .module-title,
  .tz-artists-padding .module-title {
    display: none;
  }

  .tz-live-video {
    padding: 0 15px;
    &.owl-carousel .owl-stage-outer {
      padding: 0 0 20px;
    }
    .item-video h3 {
      bottom: 0 !important;
      position: relative;
      margin-top: 15px;
      font-size: 18px;
    }
    .item-video .owl-video-wrapper .owl-video-play-icon {
      width: 50px;
      height: 50px;
      margin: -25px 0 0 -25px;
      &:after {
        font-size: 20px;
        margin-top: 11px;
        margin-left: 6px;
      }
    }
  }

  .tz-logo-fixed {
    display: none;
  }

  .k2SearchBlock.tz_search_top {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .btn-menu-custom {
    padding: 15px 10px 15px 0;
  }

  .tz-artists {
    .item-artists {
      height: 250px !important;
    }
    .tz-media {
      padding: 10px;
    }
    .tz-content {
      top: 10px;
      left: 10px;
      right: 10px;
      bottom: 10px;
    }
    .owl-controls .owl-nav {
      margin-top: 10px;
    }
  }

  .tz-custom-btn-mod {
    margin: 14px 0 0 0;
    float: none;
    padding: 5px 15px;
  }

  .tz-albums {
    .tz-description {
      display: none;
    }
    .tz-title {
      font-size: 30px;
    }
    .tz-additional .muted,
    .tz_categories a {
      font-size: 14px;
    }
    .tz-navigation {
      margin-top: 0 !important;
      display: block;
      .tz-actions {
        display: inline-block;
        top: 15px;
      }
    }
    .tz-read-more {
      padding: 5px 15px;
      font-size: 12px;
    }
    .tz-category-fixed {
      font-size: 50px;
    }
  }

  .tz_slide_new {
    .tz-navigation {
      margin-left: 0;
      margin-top: 15px;
    }
    .owl-item.last .item-new:after {
      opacity: 0;
      visibility: hidden;
    }
    .tz-title {
      font-size: 16px;
    }
    .tz-muted {
      font-size: 13px;
    }
  }

  .tz-live-video {
    .owl-carousel {
      .owl-stage-outer {
        padding: 0;
      }
    }
    .item-video .tz_portfolio_plus_video {
      height: 250px !important;
    }
    .tz-link-btn {
      font-size: 32px;
      width: 80px;
      height: 80px;
      line-height: 80px;
    }
  }

  .tz-section-contact {
    .tz-module .TzContact {
      .form-validate {
        display: none;
      }
      .contact-address {
        h4 {
          margin-bottom: 5px;
        }
        > div {
          width: 100%;
          padding: 0 0 10px;
          > div {
            margin-bottom: 5px;
          }
        }
      }
      #contact-form {
        textarea {
          max-height: 100px;
        }
        input, textarea {
          padding: 5px 20px;
          margin-bottom: 25px;
          font-size: 13px;
        }
      }
      .tz-btn-contact {
        font-size: 12px;
      }
    }
  }

  .fullpage-footer {
    .tz_copyright {
      left: 15px;
      right: 15px;
      text-align: center;
      bottom: 35px;
      font-size: 12px;
    }
    .tz_social {
      left: 15px;
      right: 15px;
      bottom: 10px;
      font-size: 14px;
      text-align: center;
    }
  }

  .tz-next-show {
    tbody,
    tfoot,
    thead,
    table,
    tr, td {
      display: block;
    }
  }

  .tzcountdown .tzdivide span, .tzcountdown .tzcountdownitem span {
    font-size: 40px;
  }

  .tzcountdown .tzdivide {
    margin: 0 15px;
  }

  .parallax .title {
    font-size: 72px;
    margin-top: -70px;
  }

  .parallax.top .title {
    margin-bottom: -70px;
    margin-top: -50px;
  }

  div.itemContainer {
    width: 100% !important;
  }

  .text-page {
    font-size: 32px;
    position: relative;
    top: -5px;
  }

  .view-contact .contact .tz-left {
    padding-top: 20%;
    padding-bottom: 30px;
  }

  .rev_slider_wrapper .tz-layer-block {
    height: 150px;
    width: 150px;
  }

  .tz_trending_article {
    .display-flex {
      display: block;
      .owl-carousel,
      .tz_label {
        width: 100%;
        padding: 0;
      }
    }
  }

  .tz-padding-featured-album-home4 .tz-albums {
    .tz-content,
    .tz-media {
      width: 100%;
    }
    .tz-content {
      padding: 20px 0 0;
    }
    .tz-media {
      .tz_portfolio_plus_image img.tz_image_hover {
        right: -10px;
      }
    }
    .item-album {
      display: block;
    }
    .tz-description {
      display: block;
      margin-bottom: 30px;
    }
  }

  .tz-artist-home-4 {
    .tz-artists .item-artists {
      height: auto !important;
      display: block;
      .tz-media, .tz-content {
        width: 100%;
      }
      .tz-content {
        padding: 30px 0 0;
      }
    }
    .tz-actions {
      top: -40px;
    }

  }
  .rev_slider_wrapper .tp-rightarrow.custom:after,
  .rev_slider_wrapper .tp-leftarrow.default:after {
    font-size: 36px;
  }

  .rev_slider_wrapper .tz-button-style-1, .rev_slider_wrapper .tz-button-style-2 {
    width: 60px;
  }

  .tz-padding-featured-album-home4 .parallax.top .title {
    margin-bottom: -220px;
  }

  .tz-shop .parallax .title {
    margin-top: -240px;
  }

  .tz-footer-home-4 > .container > .row {
    display: block;
  }

  /*Home 5 */
  .tz-event-static-style-1 {
    .tz-title {
      font-size: 36px;
      letter-spacing: 10px;
    }
    .time-countdown {
      font-size: 33px;
      padding-right: 25px;
      &:after {
        right: 12px;
      }
    }
  }

  .tz_newsletter .tz-form .tz-size-medium {
    font-size: 30px;
    letter-spacing: 5px;
  }

  .tz_newsletter .tz-form .mc-field-group {
    width: 100%;
    margin: 35px 0 0;
  }

  .tz-block-footer-home5 {
    padding: 25px;
    .display-flex {
      display: block;
      > div {
        width: 100%;
        text-align: center;
      }
      .social_footer_home_page {
        padding: 5px 0 7px;
      }
    }
  }

  .tz-album-feature-home-5 {
    .tz_portfolio_plus_article {
      .tz-content {
        width: 100%;
        padding: 0;
      }
      .tz-button-custom,
      .tz-read-more {
        padding-left: 20px;
        padding-right: 20px;
        font-size: 14px;
      }
      .tz-button-custom {
        margin-left: 10px;
      }
      .tz-additional .muted {
        font-size: 16px;
      }
    }
  }
}