@crt_global_cl: #000000;
@crt_title_cl: #000000;
@color_666: #666;
.hikashop_products {
  ul.thumbnails {
    margin: 0;
  }
}

.hikashop_products_listing_main {
  .tz-top-shop {
    border-bottom: 1px solid #ededed;
    margin-bottom: 40px;
    padding-bottom: 25px;
    display: flex;
    align-items: center;
    .tz-choose-style {
      width: 20%;
    }
    .hikashop_filter_main_div {
      width: 80%;
    }
  }
  .img_title {
    .hikashop_subcontainer {
      padding: 60px 20px 23px;
      overflow: hidden;
      position: relative;
      height: auto !important;
      &.hikashop_subcontainer_border {
        border: 1px solid rgba(229, 174, 73, 0.2);
      }
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(229, 174, 73, 0);
        border: 1px solid rgba(229, 174, 73, 0.5);
        opacity: 0;
        visibility: hidden;
        .transition(all 0.3s ease 0s);
      }
      &:hover {
        &:before {
          background: rgba(229, 174, 73, 0.1);
          opacity: 1;
          visibility: visible;
          border-width: 10px;
        }
      }
      > div {
        position: relative;
        z-index: 1;
      }
      .tz_header_product {
        margin-bottom: 40px;
      }
      .hikashop_product_name {
        a {
          font-size: 18px;
          color: #222222;
          letter-spacing: 2px;
        }
      }
      .hikashop_product_code_list {
        color: #cecece;
        a {
          color: #cecece;
          letter-spacing: 1px;
        }
      }
      .hikashop_product_image {
        margin-bottom: 5px;
        .tz-view_detail {
          position: absolute;
          left: 0;
          right: 0;
          .transition(all 0.3s ease-in-out 0.3s);
          bottom: -40px;
          z-index: 2;
        }
      }
    }
    .hikashop_product_image {
      img {
        max-width: 100%;
        margin: auto;
      }
    }
    .hikashop_product_price_full {
      font-size: 22px;
      .hikashop_product_price_before_discount {
        text-decoration: line-through;
        color: #cecece;
      }
      .hikashop_product_price {
        color: #ed6a5a;
      }
    }
    .tz-view-detail {
      background: #ed6a5a;
      color: @white;
      .border-radius(30px);
      max-width: 100%;
      overflow: hidden;
      text-transform: uppercase;
      font-size: 13px;
      padding: 10px 30px;
      font-weight: normal;
    }

    .hikashop_add_wishlist,
    .hikashop_product_quantity_div {
      display: inline-block;
    }
    .hikashop_product_vote {
      margin: 6px 0;
    }
  }
  .tz-choose-style {
    label {
      font-weight: 300;
      color: #222222;
      position: relative;
      top: -3px;
      padding-right: 5px;
    }
    .switchToGrid {
      margin-left: 5px;
    }
    .switchToGrid, .switchToList {
      font-size: 18px;
      color: #222222;
      margin-right: 9px;
      display: inline-block !important;
      .transition(all 0.3s ease 0.05s);
      &:hover {
        color: #ed6a5a;
      }
    }
    .tooltip {
      .tooltip-inner {
        background: #ed6a5a;
        .border-radius(2px);
        padding: 5px 15px;
        min-width: 80px;
      }
      &.top {
        .tooltip-arrow {
          border-top-color: #ed6a5a;
        }
      }
    }
  }
  .hikashop_product {
    padding-bottom: 40px;
  }

  .list-footer {
    display: flex;
    align-items: center;

    .limit {
      width: 40%;
    }
    > .pagination {
      width: 60%;
      text-align: right;
      margin: 0;
      li a {
        height: 40px;
        width: 40px;
        line-height: 40px;
        text-align: center;
        display: inline-block;
        background: transparent;
        border: 1px solid #dedede;
        color: #666666;
        font-weight: 300;
        margin: 0 5px;
        .border-radius(2px);
        &:hover {
          background: #ed6a5a;
          border-color: #ed6a5a;
          color: @white;
        }
      }
    }
  }
  .hikashop_products_pagination {
    border-top: 1px solid #ededed;
    padding-top: 40px;
    .hikashop_results_counter {
      display: none;
    }
  }
}

.hikashop_products_listing {
  .hikashop_product {
    .tooltip.top {
      margin-bottom: 10px;
      .tooltip-inner {
        line-height: 24px;
      }
    }
    &.img_title {
      padding-top: 0;
      padding-bottom: 0;
      cursor: pointer;
      .hikashop_container {
        padding-bottom: 40px;
      }
      .hikashop_product_quantity_div {
        display: inline-block;
      }
      .hikashop_product_desc {
        display: none;
      }
      .hikashop_subcontainer {
        .hikashop_product_image {
          position: relative;
          overflow: hidden;
        }
        .tz_shop_link_item,
        .add_to_cart_listing,
        .tz_shop_item_info {
          z-index: 1;
          position: static;
          > * {
            z-index: 2;
            position: relative;
          }
        }
        .view_detail {
          position: absolute;
          left: 0;
          right: 0;
          .transition(all 0.3s ease-in-out 0.3s);
          bottom: -40px;
          z-index: 2;
        }
        .tz_shop_link_item {
          background-color: @crt_global_cl;
          border-radius: 20px;
          color: @white;
          display: inline-block;
          font-size: 13px;
          font-weight: 400;
          letter-spacing: 2px;
          line-height: 24px;
          padding: 7px 26px;
          text-transform: uppercase;
        }
        .title_product {
          font-weight: 400;
          letter-spacing: 1px;
          line-height: 24px;
          text-transform: uppercase;
          font-size: 14px;
          margin: 0;
        }
        .hk-rating {
          margin-top: 12px;
          line-height: 1em;
          a {
            font-size: 15px;
          }
        }
        .hikashop_product_price_full {
          margin-top: 10px;
        }
        .hikashop_product_price_before_discount {
          margin-right: 18px;
        }

      }
    }
    &.img_pane {
      padding-top: 0;
      padding-bottom: 0;
      .hikashop_container {
        padding-bottom: 20px;
        margin-bottom: 20px !important;
        border-bottom: 1px solid #ededed;
      }
      .hikashop_img_pane_panel {
        width: 100%;
        padding-left: 15px;
        .hikashop_product_name {
          color: @crt_title_cl;
          font-size: 13px;
          font-weight: 400;
          line-height: 24px;
          margin: 0 0 2px;
          padding: 0;
          a {
            color: @crt_title_cl;
            padding: 0;
          }
        }
        .hikashop_product_price_full {
          font-size: 13px;
        }
        .hikashop_product_price {
          color: @crt_global_cl;
        }
        .hikashop_product_price_before_discount {
          text-decoration: line-through;
          margin-right: 3px;
        }
        .state-empty, .state-full {
          font-size: 14px;
          padding: 0;
        }
      }
    }
  }

  &.layout_grid {
    .tz-title-button {
      display: none;
    }
    .tz-button {
      background: #ed6a5a;
      display: block;
      color: @white;
      height: 40px;
      width: 40px;
      line-height: 40px;
      text-align: center;
      .border-radius(50%);
      margin: 0 6px;
      &:hover {
        background: #daae81;
        color: #222222;
      }
    }
  }
  &.layout_list {
    .tooltip {
      display: none !important;
    }

    .hikashop_listing_img_title {
      display: flex;
    }

    .img_title {
      .hikashop_subcontainer {
        padding: 23px 20px;
        .effect-zoe .tz-body {
          bottom: -50%;
        }
        &:hover {
          .effect-zoe .tz-body {
            bottom: 50%;
            -webkit-transform: translate3d(0px, 25px, 0px);
            -moz-transform: translate3d(0px, 25px, 0px);
            -ms-transform: translate3d(0px, 25px, 0px);
            -o-transform: translate3d(0px, 25px, 0px);
            transform: translate3d(0px, 25px, 0px);
          }
        }
      }
      .hikashop_product_vote {
        margin: -2px 0 8px;
        .hk-rating {
          margin: 0;
        }
      }
      .hikashop_product_desc {
        margin-top: 10px;
        color: #666666;
        font-weight: 300;
        line-height: 1.8em;
      }
      .hikashop_product_image {
        max-width: 245px;
        padding: 15px;
      }
      .state-empty, .state-full {
        font-size: 14px;
        padding-right: 3px;
      }
      .hikashop_subcontainer {
        .tz_header_product {
          margin: 0;
        }
        .add_to_cart_listing {
          margin: 22px 0 0;
        }
      }

    }
    .hikashop_product {
      width: 100%;
      .hikashop_subcontainer {
        &:hover {
          .view_detail {
            opacity: 1;
            visibility: visible;
            -webkit-transform: translateY(-50%) rotateX(0deg);
            -moz-transform: translateY(-50%) rotateX(0deg);
            -ms-transform: translateY(-50%) rotateX(0deg);
            -o-transform: translateY(-50%) rotateX(0deg);
            transform: translateY(-50%) rotateX(0deg);
          }
        }
      }
      .tz_img_title {
        padding: 24px 20px 28px;
      }
      .hikashop_product_desc {
        display: block;
      }
      .tz_shop_item_info,
      .hikashop_product_image {
        //display: table-cell;
        //vertical-align: top;
      }

      .tz_shop_item_info {
        width: 70%;
        text-align: left;
        padding: 0 0 0 30px !important;
      }
      .hk-rating {
        //margin: 0 !important;
      }

      .hikashop_product_price_full {
        margin-top: 5px;
      }

      .hikashop_product_price_before_discount,
      .hikashop_product_price {
        //font-size: 15px !important;
      }
      .add_to_cart_listing {
        position: relative !important;
        top: auto;
        margin: 23px 0 7px;

        .hikashop_cart_button {
          //.scale(1);
          opacity: 1;
          width: auto;
          height: auto;
          border-radius: 20px;
          padding: 10px 18px 9px;
          letter-spacing: 0.5px;
          background: #c7c7c7;
          border-color: #c7c7c7;
          font-weight: 300;
          .transition(all 0.3s ease 0.1s);
          line-height: 1.5em;
          color: @white;
          margin-right: 9px;
          &:hover {
            background: @crt_global_cl;
            border-color: @crt_global_cl;
          }
          .tz_layout_list {
            display: inline-block;
            color: @white;
          }
          i {
            line-height: 1em;
            margin-right: 10px;
          }
          &.tz-add-to-cart {
            background: #ed6a5a;
            &:hover {
              background: #daae81;
              color: #222222;
            }
          }
        }
      }
      .view_detail {
        top: 50%;
        bottom: auto !important;
        visibility: hidden;
        opacity: 0;
        -webkit-transform: translateY(-50%) rotateX(90deg);
        -moz-transform: translateY(-50%) rotateX(90deg);
        -ms-transform: translateY(-50%) rotateX(90deg);
        -o-transform: translateY(-50%) rotateX(90deg);
        transform: translateY(-50%) rotateX(90deg);
      }
    }
  }

}

.hikashop_products_listing {
  &.layout_grid {
    .img_title {
      .hikashop_subcontainer {
        &:hover {
          .add_to_cart_listing {
            opacity: 1;
            .hikashop_cart_button {
              .scale(1);

            }
          }
          .view_detail {
            bottom: 0;
          }
        }
      }
      .add_to_cart_listing {
        position: absolute !important;
        top: 50%;
        margin-top: -50%;
        .translate(0, -50%);
        left: 0;
        right: 0;
        opacity: 0;
        .transition(all 0.3s ease-in-out 0s);
        .hikashop_product_stock_count {
          display: block;
          padding-bottom: 10px;
        }
        .hikashop_cart_button {
          height: 40px;
          width: 40px;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          margin: 0 5px;
          display: inline-block;
          position: relative;
          text-align: center;
          .scale(0);

          .transition(all 0.3s ease-in-out 0s);
          i {
            color: @white;
            line-height: 40px;
            margin: 0;
            padding: 0;
          }
        }
        .tz_layout_list {
          display: none;
        }
      }
    }
  }
}

.hikashop_products_pagination,
.hikashop_products_listing_main .hikashop_filter_main {
  .chzn-container {
    &.chzn-container-active.chzn-with-drop .chzn-single > div > b {
      background-position: -18px 8px;
    }
    .chzn-single {
      padding: 4px 11px;
      line-height: 28px;
      border-radius: 1px;
      border: 1px solid #dedede;
      height: auto;
      background: @white;
      color: @color_666;
      font-weight: 300;
      .box-shadow(none);
      > div {
        width: 15px;
        right: 10px;
        > b {
          background-position: 0 8px;
        }
      }

    }
    .chzn-drop {
      border-color: #dedede;
      color: @color_666;
      .box-shadow(none);
    }
  }
}

.hikashop_module .tz-choose-style {
  display: none;
}

.hikashop_products_listing_main {
  .tz_top {
    border-color: #ededed;
    border-style: solid;
    border-width: 0 0 1px;
    margin-bottom: 40px;
    padding: 0 0 25px;
    &:after {
      clear: both;
      display: block;
      content: '';
    }
    .hikashop_filter_main_div {
      margin: 0 20px 0 0;
    }
    .hikashop_results_counter {
      border: 1px solid #dedede;
      border-radius: 1px;
      color: @color_666;
      line-height: 20px;
      padding: 9px 15px;
      font-weight: 300;
    }
    .view_list {
      margin-top: 10px;
      label {
        font-weight: 300;
      }
      a {
        font-size: 18px;
        line-height: 1em;
        margin-left: 7px;
        &:hover {
          color: @crt_global_cl;
        }
      }
    }
  }
}

.view-category {
  .tz_top {
    display: none;
  }
}

/* Vote */

div.hk-rating {
  position: relative;
  display: inline-block;
}

.hika_comment_listing_empty_stars,
.hika_comment_listing_full_stars,
.state-empty,
.state-full {
  background: none !important;
  font-size: 14px;
  display: inline-block;
  padding-right: 4px;
  cursor: pointer;
  font-family: FontAwesome;
  color: #cecece;
}

.hika_comment_listing_empty_stars:before,
.state-empty:before {
  content: "\f006";
}

.hk-rate-star.state-hover:before,
.hika_comment_listing_full_stars:before,
.state-full:before,
.state-full.state-hover:before {
  color: #f5d608;
  content: "\f005";
}

/* Product Detail */
.hikashop_product_page {

  .one_image {
    .hikashop_product_main_image_thumb {
      padding: 0;
    }
  }
  .hikashop_child_image {
    display: block;
    margin: auto;
  }
  .hikashop_submodules {
    .tz-choose-style {
      display: none;
    }
    h2 {
      color: #222222;
      font-size: 36px;
      letter-spacing: 3px;
      line-height: 25px;
      margin-bottom: 45px;
      margin-top: 48px;
    }
  }
  .hikashop_product_name_main {
    color: #222222;
    font-size: 48px;
    letter-spacing: 5px;
    margin: -8px 0 6px;
    text-decoration: none;
  }
  .state-empty, .state-full {
    font-size: 15px;
  }
  .state-empty {
    color: #b5b5b5;
    &:before {
      content: '\f005';
    }
  }
  .state-full {
    color: #f2c667;
  }
  .hikashop_product_main_image_thumb {
    padding-left: 100px;
    .hikashop_product_main_image_subdiv {
      border: 5px solid rgba(229, 174, 73, 0.5);
      position: relative;
      &:after {
        background: rgba(229, 174, 73, 0.1) none repeat scroll 0 0;
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
  #hikashop_product_right_part {
    .hikashop_product_description_main_mini {
      border-bottom: 1px solid #ededed;
      margin-bottom: 30px;
      padding-bottom: 49px;
      color: #666666;
      font-weight: 300;
      line-height: 1.8em;
      a {
        color: #ed6a5a;
      }
    }
    .hikashop_product_price_full {
      color: #ed6a5a;
      font-size: 36px;
      margin-bottom: 16px;
      display: block;
      line-height: 1em;
      .hikashop_product_price_before_discount {
        color: #b5b5b5;
        text-decoration: line-through;
        margin-right: 15px;
      }
    }
    .tz_info {
      margin-top: 30px;
    }
    .hikashop_product_vote_mini {
      margin-bottom: 15px;
    }
    .hikashop_add_wishlist,
    .hikashop_product_quantity_div {
      display: inline-block;
      i {
        padding-right: 10px;
      }
      .hikashop_cart_button {
        background: #c7c7c7;
        color: @white;
        border-radius: 20px;
        padding: 10px 20px;
        margin: 0 5px;
        display: block;
        font-weight: 300;
        &:hover {
          background: @crt_global_cl;
        }
        &.tz-add-to-cart {
          background: #ed6a5a;
          &:hover {
            background: #daae81;
            color: #222222;
          }
        }
      }
    }
    .hikashop_product_stock_count {
      font-size: 24px;
      margin-bottom: 20px;
      color: #ff0000;
      display: block;
    }
    .hikashop_product_characteristics {
      margin: 0 0 39px 0;
      color: #666666;
      tr {
        td:first-child {
          padding-right: 35px;
        }
      }

    }
    .chzn-container {
      min-width: 60px;
      &.chzn-container-active.chzn-with-drop .chzn-single > div > b {
        background-position: -18px 8px;
        &:before {
          content: '\f106';
        }
      }
      .chzn-single {
        padding: 4px 11px;
        line-height: 27px;
        border-radius: 1px;
        border: 1px solid #ededed;

        height: auto;
        background: @white;
        color: @color_666;
        font-weight: 300;
        .box-shadow(none);
        span {
          margin: 0;
        }
        > div {
          width: 18px;
          > b {
            background-position: 0 8px;
            background: none !important;
            &:before {
              content: '\f107';
              font-family: FontAwesome;
              line-height: 35px;
              font-weight: normal;
            }
          }
        }

      }
      .chzn-drop {
        border-color: #dedede;
        color: @color_666;
        .box-shadow(none);
      }
    }
    .tz_info {
      > * {
        display: inline-block;
        margin-right: 10px;
      }
      .hikashop_product_tags {
        ul {
          display: inline-block;
          margin: 0;
          li {
            padding: 0;
            a {
              background: transparent;
              color: @color_666;
              font-weight: 300;
              font-size: 14px;
              padding: 0;
              &:hover {
                color: @crt_global_cl;
              }
            }
          }
        }
      }
    }

    .hikashop_product_quantity_main {
      margin-bottom: 30px;
      .tooltip {
        display: none !important;
      }
      .hikashop_product_quantity_input_div_regrouped,
      .hikashop_product_quantity_input_div_simplified,
      .hikashop_product_quantity_change_div_leftright,
      .hikashop_product_quantity_input_div_default {
        input {
          border: 1px solid #ededed;
          border-radius: 2px;
          color: #666;
          font-weight: 300;
          line-height: 24px;
          padding: 7px 10px;
          width: 70px;
        }
      }
      .hikashop_product_quantity_input_div_regrouped {
        position: relative;
        .hikashop_product_quantity_change_div_plus_default,
        .add-on {
          position: absolute;
          right: 0;
          top: 0;
          a {
            background: #c7c7c7;
            color: @white;
            border: medium none;
            display: block;
            height: 20px;
            line-height: 20px;
            padding: 2px;
            text-align: center;
            width: 15px;
            &:hover {
              background: #ed6a5a;
            }
          }
        }
      }
      .hikashop_product_quantity_change_div_leftright {
        position: relative;
        input {
          display: inline-block;
          padding: 7px 20px;
          text-align: center;
        }
        .add-on {
          a {
            position: absolute;
            background: #c7c7c7;
            color: @white;
            border: medium none;
            display: block;
            line-height: 35px;
            padding: 2px;
            text-align: center;
            width: 15px;
            height: 100%;
            top: 0;
            &:hover {
              background: #ed6a5a;
            }
            &.hikashop_product_quantity_field_change_minus {
              left: 0;
            }
            &.hikashop_product_quantity_field_change_plus {
              right: 0;
            }
          }
        }
      }
    }
  }
  .hikashop_product_description_main {
    font-weight: 300;
    color: #666666;
    line-height: 1.78em;
  }
  #hikashop_show_tabular_specification {
    font-weight: 300;
    > * {
      padding-bottom: 5px;
    }
    * {
      color: #666666;
    }
    a {
      color: #ed6a5a;
    }
  }
  .hikashop_product_bottom_part.show_tabular {
    border-bottom: 2px solid rgba(237, 106, 90, 0.2);
    display: block;
    margin-bottom: 44px;
    margin-top: 59px;
    padding: 0 0 52px;

    .hikashop_tabs_ul {
      margin: 0;
      padding: 0 5px;
      li {
        padding: 0;
        margin: 0 2px -1px 0;
        display: inline-block;
        float: none;
        border-width: 3px 1px 0;
        border-style: solid;
        border-color: transparent rgba(229, 174, 73, 0.2) transparent rgba(229, 174, 73, 0.2);
        .transition(all 0.3s ease-in-out 0s);
        &.hikashop_tabs_li_selected,
        &:hover,
        &.active {
          border-top-color: #ed6a5a;
          border-bottom-color: @white;
          a {
            background: @white;
            z-index: 2;
            color: @crt_global_cl;
          }
        }
        a {
          color: @white;
          cursor: pointer;
          display: inline-block;
          margin: 0;
          padding: 13px 29px;
          line-height: 24px;
          font-size: 18px;
          background: #ed6a5a;
          border-radius: 0;
          .transition(all 0.3s ease-in-out 0s);
          letter-spacing: 1px;
        }
      }
    }
    .hikashop_tabs_content {
      background: @white;
      border: 1px solid rgba(229, 174, 73, 0.2);
      border-radius: 0;
      box-shadow: none;
      margin: 0;
      padding: 20px 28px 23px;
      display: none;
    }
  }
  #hikashop_comment_form {
    .hikashop_listing_comment {
      color: @crt_title_cl;
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 10px;
    }
    .hika_comment_listing_stars {
      float: right;
      &:after {
        clear: both;
        content: '';
        display: block;
      }
    }
    .hikashop_comment_textarea,
    .hikashop_comment_form_mail input,
    .hikashop_comment_form_name input {
      border-style: solid;
      border-width: 0 0 1px 0;
      border-color: #e3e3e3;
      width: 100%;
      padding: 15px 0;
      margin-bottom: 30px;
    }
    .btn-send-commnet {
      background: #c7c7c7;
      border-radius: 20px;
      color: #ffffff;
      display: inline-block;
      font-weight: 400;
      line-height: 24px;
      padding: 7px 20px;
      text-decoration: none;
      .transition(all 0.3s ease-in-out 0s);
      border: none;
      .box-shadow(none);
      margin-bottom: 10px;
      &:hover {
        background: @crt_global_cl;
      }
    }
    .hika_comment_listing {
      padding: 10px 15px;
    }
    .hika_vote_listing_username {
      color: @crt_title_cl;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
    }
    .hika_comment_date {
      color: #b5b5b5;
      font-size: 14px;
      font-style: italic;
      font-weight: 300;
    }
    .hika_comment_listing_content {
      color: @color_666;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0.5px;
      line-height: 25px;
      margin-top: 5px;
    }
    .hikashop_form_comment_let_comment {
      color: @crt_title_cl;
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 10px;
    }
    .hika_comment_listing_empty_stars, .hika_comment_listing_full_stars {
      font-size: 14px;
    }
  }
  .lSSlideWrapper {
    border: 5px solid rgba(229, 174, 73, 0.5);
  }
  .lightSlider {
    li {
      padding: 0;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background: rgba(229, 174, 73, 0.1);
      }
    }
  }
  .lSPager {
    li {
      border: 1px solid rgba(229, 174, 73, 0.2);
      border-radius: 0 !important;
      padding: 0;
      background: @white;
      position: relative;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        .translate(-50%, -50%);
        max-width: 90% !important;
      }
      &:hover,
      &.active {
        border: 1px solid rgba(229, 174, 73, 0.5);
      }
    }
  }
}

#hikashop_social {
  > span {
    display: inline-block;
    margin-right: 5px;
    max-width: 70px;
    overflow: hidden;
    &.hikashop_social_fb {
      iframe {
        height: 20px !important;
      }
    }
  }
}

.com_hikashop.view-cart,
.com_hikashop.view-product {
  .tz-main-body-wrapper {
    padding: 120px 0;
  }
}

/* Cart */
#hikashop_show_cart_form {
  .tz-actions {
    display: flex;
    align-items: center;
    .hikashop_header_buttons,
    .hikashop_header_title {
      width: 50%;
    }
    .hikashop_header_title {
      h1 {
        margin: 0;
      }
    }
    .hikashop_header_buttons {
      text-align: right;
    }
    .tz-btn {
      letter-spacing: 0.3px;
      font-size: 16px;
      padding: 3px 15px;
      text-transform: uppercase;
      background: #daae81;
      color: #222222;
      .transition(all 0.3s ease 0.1s);
      .border-radius(5px);
      margin-left: 5px;
      &:hover {
        background: #ed6a5a;
        color: @white;
      }
    }
  }
  #cart_name {
    border: 1px solid #dedede;
    padding: 12px 15px;
  }
  .key {
    font-weight: 300;
    padding: 25px 0;
  }
  .content {
    padding: 25px 0;
  }
  .hikashop_showcart_infos {
    .display-flex {
      border-bottom: 1px solid #dedede;
    }
  }
}

.display-flex {
  display: flex;
  align-items: center;
  .key {
    width: 40%;
  }
  .content {
    width: 60%;
  }
}

#hikashop_carts_listing {
  #hikashop_cart_listing {
    th {
      font-size: 20px;
      text-transform: uppercase;
      font-weight: 400;
    }
    td {
      padding: 10px;
      a {
        color: #ed6a5a;
      }
    }
  }
  .tz-btn {
    letter-spacing: 0.3px;
    font-size: 16px;
    padding: 3px 15px;
    text-transform: uppercase;
    background: #daae81;
    color: #222222;
    .transition(all 0.3s ease 0.1s);
    .border-radius(5px);
    margin-left: 5px;
    .box-shadow(none);
    border: none;
    &:hover {
      background: #ed6a5a;
      color: @white;
    }
  }
  #hikashop_search {
    padding: 7px 20px;
    width: 80%;
    border: 1px solid #dedede;
    margin-left: 45px;
  }
  .tz-actions {
    display: flex;
    align-items: center;
    padding: 0 0 30px 0;
    color: #666666;
    .key {
      width: 75%;
      span {
        font-size: 18px;
        letter-spacing: 0.3px;
        text-transform: uppercase;
      }
    }
    .content {
      width: 25%;
      text-align: right;
    }
    .hikashop_header_buttons,
    .hikashop_header_title {
      width: 50%;
    }
    .hikashop_header_title {
      h1 {
        margin: 0;
      }
    }
    .hikashop_header_buttons {
      text-align: right;
    }
  }
}

#hikashop_cart_listing {
  .hikashop_showcart_infos {
    margin: 0;
    .inputbox {
      border: 1px solid #d3ced2;
      box-sizing: border-box;
      line-height: 1;
      outline: 0 none;
      padding: 6px 6px 5px;
      width: 100%;
    }
  }
  .hikashop_showcart_infos,
  .hikashop_cart_products {
    th {
      &.hikashop_cart_name_title {
        text-align: left;
      }
      a {
        color: #222222;
      }
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      text-align: center;
      color: #222222;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      padding: 14px 20px;
      text-transform: uppercase;
    }
    td {
      vertical-align: middle;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding: 20px;
      a {
        color: #ed6a5a;
      }
      &.hikashop_show_cart_quantity_td {
        input {
          width: 50px;
          display: inline-block;
          text-align: center;
          border: 1px solid #e0e0e0;
          padding: 9px 15px;
          border-radius: 2px;
        }
        .hikashop_cart_product_quantity_refresh {
          display: inline-block;
        }
      }
      .hikashop_product_price_before_discount {
        text-decoration: line-through;
        color: #b5b5b5;
        display: block;
      }
      .hikashop_product_price {
        color: @crt_title_cl;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        display: block;
      }
      &.total_price {
        .hikashop_product_price {
          color: @crt_global_cl;
        }
      }
      .hikashop_no_print {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-decoration: none;
      }
      &.hika_show_cart_total_price {
        color: @crt_global_cl;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        font-family: "Montserrat", sans-serif;
      }
    }
    .hika_show_cart_total .hikashop_cart_button {
      padding: 10px 15px;
      margin: 0;
    }

  }
  .hikashop_cart_products {
    margin-bottom: 30px;
  }
  .hikashop_cart_button {
    letter-spacing: 0.3px;
    font-size: 16px;
    padding: 3px 15px;
    text-transform: uppercase;
    background: #daae81;
    color: #222222 !important;
    .transition(all 0.3s ease 0.1s);
    .border-radius(5px);
    margin-left: 5px;
    &:hover {
      background: #ed6a5a;
      color: @white !important;
    }
  }
}

#hikashop_cart_module {
  position: relative;
  display: inline-block;
  .tz-icon-cart {
    color: @white;
    font-size: 24px;
    padding: 0 30px;
    position: relative;
    em {
      font-weight: normal;
      font-style: normal;
      position: absolute;
      top: 0;
      right: 31px;
      font-size: 8px;
      background: #ed6a5a;
      .border-radius(50%);
      width: 16px;
      height: 16px;
      line-height: 16px;
      text-align: center;
    }
  }
  .hikashop_small_cart_checkout_link {
    //display: block;
    color: #868686;
  }
  .tz-info {
    margin-bottom: 20px;
  }
  .hikashop_small_cart_clean_link {
    float: right;
  }
  .hikashop_cart_module_product_image {
    width: 80px;
    img {
      max-width: 100%;
    }
  }
  tr:first-child {
    .hikashop_cart_value {
      padding-top: 0;
    }
  }
  .hikashop_cart_value {
    padding: 20px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);

  }
  .hikashop_product_quantity_field {
    background: transparent;
    border: medium none;
    color: #868686;
    text-align: center;
    width: 30px;
  }
  #hikashop_cart {
    background: @white;
    border-top: 3px solid #ed6a5a;
    padding: 30px 30px 0;
    max-width: 430px;
    min-width: 430px;
    text-align: left;
    .box-shadow(0 0 5px rgba(0, 0, 0, 0.1));
    position: absolute;
    right: 15px;
    top: 150%;
    opacity: 0;
    visibility: hidden;
    .transition(all 0.3s linear  0.1s);
    &.tz-small-cart {
      padding-bottom: 30px;
      min-width: 320px;
    }
    &.active {
      top: 120%;
      opacity: 1;
      visibility: visible;
    }
  }
  .hikashop_cart_module_product_name_value {
    font-size: 17px;
    color: #868686;
    padding-left: 20px;
    a {
      display: block;
      color: #1a1a1a;
      font-weight: 300;
      font-size: 14px;
      margin-bottom: 7px;
    }
    .hikashop_product_price_full {
      margin-left: 5px;
      letter-spacing: 1px;
      .hikashop_product_price {
        color: #ed6a5a;
      }
    }
  }
  .hikashop_cart_module_product_delete_value {
    width: 10%;
  }
  .tz-button-action-checkout {
    background: #565656;
    display: inline-block;
    padding: 8px 20px 6px;
    .border-radius(30px);
    color: @white;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 1.1em;
  }
  .display-flex {
    padding: 20px 0 22px;
  }
  .hikashop_display_cart_show_convert_button {
    width: 70%;
  }
  .tz_total_price {
    color: #111111;
    width: 30%;
    .hikashop_cart_module_product_total_value {
      color: #ed6a5a;
    }
  }

}

/*Check Out*/
#hikashop_checkout_coupon {
  padding: 30px 0;
  .key {
    width: 100%;
    input {
      width: 100%;
    }
  }
  .content {
    width: auto;
    text-align: right;
  }
  .tz-btn {
    padding: 11px 40px;
  }

  input {
    border: 1px solid #dadada;
    border-radius: 2px;
    color: @color_666;
    font-weight: 300;
    line-height: 24px !important;
    margin: 0;
    padding: 10px 15px;
  }
}

.hikashop_checkout_page {
  .tz-btn {
    letter-spacing: 0.3px;
    font-size: 16px;
    padding: 3px 15px;
    text-transform: uppercase;
    background: #daae81;
    color: #222222;
    .transition(all 0.3s ease 0.1s);
    .border-radius(5px);

    .box-shadow(none);
    border: none;
    &:hover {
      background: #ed6a5a;
      color: @white;
    }
  }
  #hikashop_checkout_next_button {
    padding: 7px 30px;
    font-size: 18px;
    margin: 0;
  }
  .hikashop_checkout_address {

  }
  .hikashop_checkout_cart {
    .tz-btn {
      padding: 10px 25px;
      font-size: 18px;
      margin: 0;
    }
    th {
      &.hikashop_cart_name_title {
        text-align: left;
      }
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      color: #222222;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      padding: 14px;
      text-transform: uppercase;
    }
    td {
      vertical-align: middle;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding: 20px;

      .hikashop_checkout_cart_subtotal {
        color: @crt_title_cl;
      }

      &.hikashop_cart_subtotal_value,
      &.hikashop_cart_total_value {
        color: @crt_title_cl;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
      }
      .hikashop_checkout_cart_final_total {
        color: @crt_global_cl;

      }
      &.hikashop_cart_product_quantity_value {
        input {
          width: 50px;
          display: inline-block;
          text-align: center;
          border: 1px solid #e0e0e0;
          padding: 9px 15px;
          border-radius: 2px;
        }
        .hikashop_cart_product_quantity_delete,
        .hikashop_cart_product_quantity_refresh {
          display: inline-block;
        }
      }
      .hikashop_product_price_before_discount {
        text-decoration: line-through;
        color: #b5b5b5;
        display: block;
      }
      .hikashop_product_price {
        color: @crt_title_cl;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        display: block;
      }
      &.total_price {
        .hikashop_product_price {
          color: @crt_global_cl;
        }
      }
      .hikashop_no_print {
        font-size: 15px;
        line-height: 24px;
        text-decoration: none;
        color: #ed6a5a;
      }
      &.hika_show_cart_total_price {
        color: @crt_global_cl;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
      }

    }
  }

  h3 {
    margin: 0 0 20px;
    letter-spacing: 1px;
    text-transform: uppercase;

  }
  .hikashop_checkout_login {
    .control-group {
      margin-bottom: 30px;
      input[type="checkbox"] {
        margin-left: 0;
        position: relative;
      }
    }
    label {
      color: @crt_title_cl;
      font-size: 15px;
      font-weight: 300;
      line-height: 24px;
      padding: 5px 0;
    }
    input[type='text'], input[type='password'], input[type='email'] {
      border: 1px solid #dadada;
      border-radius: 2px;
      color: @color_666;
      font-weight: 300;
      line-height: 24px !important;
      padding: 5px 15px;
      width: 100%;
    }
    .hikashop_field_required {
      display: none;
    }
  }

  .chzn-container {
    width: 100% !important;
    .chzn-single {
      background: @white;
      border-radius: 2px;
      color: @color_666;
      font-weight: 300;
      line-height: 24px !important;
      //margin-bottom: 20px;
      padding: 5px 15px;
      border: 1px solid #dadada;
      .box-shadow(none);
      display: block;
      height: auto;
      &.chzn-container-active.chzn-with-drop .chzn-single > div > b {
        background-position: -18px 8px;
        &:before {
          content: '\f106';
        }
      }
      div b {
        background: none !important;
        &:before {
          content: '\f107';
          font-family: FontAwesome;
          line-height: 35px;
          font-weight: normal;
        }
      }
    }
  }

  .chzn-container-active.chzn-with-drop .chzn-single div b {
    background-position: -16px 12px;
  }

  .hikashop_payment_methods,
  .hikashop_shipping_methods {
    margin-top: 30px;
    .hikashop_shipping_group,
    .hikashop_payment_group {
      padding: 20px 40px;
      background: #ededed;
    }
    input[type="radio"] {
      margin-right: 10px;
    }
    label {
      color: @crt_title_cl;
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      padding: 13px 0;
    }
  }
}

.hikashop_wizardbar {
  ul {
    margin-bottom: 30px;
    padding: 0;
    li {
      display: inline-block;
      padding: 5px 16px 2px;
      background: #daae81;
      position: relative;
      margin-right: 30px;
      .border-radius(3px);
      .transition(all 0.3s ease 0s);
      font-size: 16px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      a {
        color: @crt_global_cl;
      }
      &:hover,
      &.hikashop_cart_step_current {
        color: @white;
        background: #ed6a5a;
        a,
        .badge {
          color: @white;
        }
      }
      .badge {
        color: #222222;
        background: transparent;
        font-size: 100%;
        font-weight: normal;
        line-height: 1.3em;
        padding: 0;
      }
      &:last-child:after {
        content: none;
      }
      &:after {
        content: '\f178';
        font-family: FontAwesome;
        position: absolute;
        top: 4px;
        right: -25px;
        color: #222222;
      }
    }
  }
}

#form-login-password,
#form-login-username {
  .controls {
    position: relative;
  }
  .tz_help,
  .add-on {
    position: absolute;
    top: 9px;
  }
  .add-on {
    left: 10px;
  }
  .tz_help {
    right: 10px;
  }
  input {
    width: 100% !important;
    padding-left: 30px;
    padding-right: 20px;
  }
}

#form-login-remember {
  margin: 0 0 15px 0;
  label {
    padding: 0;
  }
}

/*-- Filter ---*/
.recent-product,
.filter-brands,
.filter-tags,
.filter-categories,
.filter-search,
.filter-price {
  margin-bottom: 45px;
  .module-title {
    color: #222222;
    font-size: 18px;
    letter-spacing: 2px;
  }
}

.filter-search {
  .filter_values_container {
    display: block;
    position: relative;
    &:after {
      content: '\f002';
      font-family: FontAwesome;
      background: #ed6a5a;
      color: @white;
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 45px;
      line-height: 45px;
      text-align: center;
    }
    input {
      border: 1px solid #dedede;
      font-size: 13px;
      font-weight: 300;
      font-style: italic;
      color: #b5b5b5;
      padding: 12px 55px 12px 12px;
      width: 100% !important;
      line-height: 1.5em;
    }
  }
}

.filter-price {
  .ui-slider-horizontal {
    height: 6px;
    background: #ededed;
    position: relative;
    width: 100% !important;
    .border-radius(2px);
    margin-bottom: 35px !important;
    margin-top: 37px !important;
  }
  .ui-slider-handle {
    height: 16px;
    width: 16px;
    .border-radius(50%);
    background: @white;
    border: 3px solid #ed6a5a;
    position: absolute;
    top: -5px;
    margin-left: -4px;
  }
  .ui-slider-range {
    background: #ed6a5a;
    display: block;
    height: 100%;
    position: absolute;
  }
  .hikashop_filter_main {
    color: #666666;
    font-weight: 300;
    margin-bottom: 4px;
  }
  .hikashop_filter_button_outside {
    position: relative;
    top: -25px;
    a {
      display: inline-block;
      background: #222222;
      color: @white;
      padding: 7px 28px;
      line-height: 1.3em;
      .border-radius(30px);
    }
  }
}

.filter-brands,
.filter-categories {
  .hikashop_filter_main {
    margin-top: -4px;
    margin-bottom: 10px;
  }
  br {
    display: none;
  }
  .hikashop_filter_title,
  .hikashop_filter_checkbox {
    display: block;
    border-bottom: 1px solid #ededed;
    padding: 15px 0;
    font-weight: 300;
    color: #666666;
    label {
      font-weight: 300;
      color: #666666;
      margin: 0;
      padding-left: 5px;
    }
    input[type="checkbox"] {
      margin: 0;
      top: 2px;
      position: relative;
    }
  }
}

.module-ct {
  .tz-top-shop {
    display: none;
  }
  .hikashop_product_price_before_discount {
    text-decoration: line-through;
    padding-right: 5px;
    color: #cecece;
  }
  .hikashop_product_price {
    color: #ed6a5a;
  }
  .hikashop_product_price_full {
    font-size: 16px;
  }
  .recent-product {
    padding-top: 5px;
  }
}

.recent-product {
  .hikashop_product {
    padding-bottom: 0;
  }
}

.hikashop_listing_img {
  display: flex;
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: 1px solid #ededed;
  .tz-content {
    width: 100%;
    padding-left: 10px;
  }
  .hikashop_product_name a {
    color: #222222;
    letter-spacing: 2px;
    display: block;
    margin-bottom: 6px;
  }
  .hikashop_product_stock_count {
    display: block;
    text-align: left !important;
  }
  .hikashop_add_wishlist,
  .hikashop_product_quantity_div {
    display: inline-block;
  }
}

.hikashop_product.img:first-child {
  .hikashop_listing_img {
    padding-top: 3px;
  }
}

.hikashop_categories_listing_main {
  .hikashop_subcategories {
    ul {
      list-style: none;
      padding: 0;
    }
  }
}

/* -- Category Listing -- */
.hikashop_subcategories_listing {
  .hikashop_container {
    padding-bottom: 40px;
    .hikashop_subcontainer {
      height: auto !important;
      padding: 20px;
    }
  }
  .hikashop_subcontainer_border {
    border: 1px solid rgba(229, 174, 73, 0.2);
  }
  .hikashop_category_list,
  .hikashop_category_name {
    a {
      color: #222222;
      font-size: 16px;
    }
  }
}