/* Blog */
.itemListView {
  &.tz-default {
    .itemList {
      .catItemLinks {
        margin-bottom: 5px;
      }
      .catItemIntroText {
        padding-bottom: 33px;
      }

    }
    .catItemIntroText p {
      font-size: 15px;
      font-weight: 300;
      color: #505050;
      line-height: 1.9em;
    }
    .catItemCategory {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 47px 28px 0;
      border-color: #daae81 transparent transparent transparent;
      position: relative;
      margin: auto;
      a {
        background: @white;
        left: -160px;
        min-width: 320px;
        padding: 3px 0;
        position: absolute;
        text-align: center;
        top: -39px;
        color: #daae81;
        font-size: 14px;
        letter-spacing: 1px;
      }
    }
    div.catItemReadMore {
      bottom: -40px;
      left: 50%;
      position: absolute;
      .translate(-50%, -50%);
      background: #ed6a5a;
      border: 5px solid @white;
      .box-shadow(0 2px 6px rgba(0, 0, 0, 0.1));
      .border-radius(30px);
      padding: 4px 20px;
      letter-spacing: 3px;
      > a {
        font-size: 15px;
        color: @white;
      }
    }
    .catItemAuthor {
      position: absolute;
      top: -64px;
      left: 50%;
      .translate(-50%, 0);
      .tz-image {
        position: relative;
      }
      img {
        height: 128px;
        border: 7px solid @white;
        width: 128px;
      }
      a {
        position: absolute;
        top: 7px;
        left: 7px;
        bottom: 7px;
        right: 7px;
        .border-radius(50%);
        //.translate(0, -50%);
        color: @white;
        text-transform: uppercase;
        font-size: 18px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.5);
      }
    }
  }
  &.blog-zic-zac {
    .even,
    .odd {
      padding: 0;
      position: relative;
    }
    .even {
      .catItemView {
        > * {
          float: left;
        }
      }
    }
    .odd {
      .catItemView {
        > * {
          float: right;
        }
      }
    }
  }
  .itemList {
    div.catItemView {
      padding: 0;
      margin: 0 0 85px 0;
      &.blog-zic-zac {
        margin: 0;
        background: @white;
        .box-shadow(0 0 6px rgba(0, 0, 0, 0.09));
        &:after {
          content: '';
          clear: both;
          display: block;
        }
        .catItemBody-ziczac,
        .catItemHeader-ziczac {
          display: inline-block;
          width: 50%;
        }
        .catItemBody-ziczac {
          position: absolute;
          top: 50%;
          .translate(0px, -50%);
        }
        .catItemIntroText {
          color: #696969;
          font-size: 14px;
        }
        .catItemBody-ziczac {
          text-align: center;
          padding: 30px 45px;
        }
        .catItemReadMore {
          display: inline-block;
          margin-top: 10px;
          a {
            color: @white;
            padding: 15px 45px;
            background: #daae81;
            letter-spacing: 5px;
            .border-radius(30px);
            display: inline-block;
            &:hover {
              background: #ed6a5a;
            }
          }
        }
        .catItemTitle {
          margin: 0 0 12px;
          letter-spacing: 0;
          a {
            color: #120d14;
            letter-spacing: 3px;
            line-height: 1.3em;
            font-size: 36px;
          }
        }
        div.catItemLinks {
          margin-bottom: 13px;
        }
        span.catItemImage {
          display: block;
          margin: 0;
          position: relative;
          &:hover {
            &:after {
              width: 10px;
            }
            &:before {
              height: 10px;
            }
          }
          &:after, &:before {
            background: #d09e6e;
            content: "";
            display: block;
            position: absolute;
            z-index: 1;
            .transition(all 0.3s ease 0s);
            right: 0;
          }
          &:after {
            height: 100%;
            top: 0;
            width: 0;
          }
          &:before {
            height: 0;
            bottom: 0;
            width: 100%;
          }
          a {
            display: block;
            position: relative;
            &:hover {
              &:after {
                width: 10px;
              }
              &:before {
                height: 10px;
              }
            }
            &:after, &:before {
              background: #d09e6e;
              content: "";
              display: block;
              .transition(all 0.3s ease 0s);
              position: absolute;
              left: 0;
            }
            &:after {
              height: 100%;
              top: 0;
              width: 0;
            }
            &:before {
              height: 0;
              top: 0;
              width: 100%;
            }
          }
        }

        .catItemLinks.tz-top * {
          color: rgba(117, 117, 117, 0.8);
        }
      }

    }
    div.catItemBody {
      padding: 0;
      margin: -80px 100px 0;
      position: relative;
      z-index: 1;
      .box-shadow(0 0 6px rgba(0, 0, 0, 0.09));
      > .tz-top {
        padding: 78px 30px 50px;
        background: @white;
        border-bottom: 1px solid rgba(37, 37, 37, 0.07);
        position: relative;
      }
      > .tz-bottom {
        background: #f9f9f9;
        padding: 25px 30px;
        .tz-item-info {
          font-weight: 300;
          color: #9a9a9a;
          font-size: 13px;
          display: inline-block;
          a {
            color: #494949;
            font-size: 13px;
          }
          &:last-child {
            &:after {
              content: none;
            }
          }
          &:after {
            content: '|';
            margin: 0 10px;
            font-weight: 300;
          }
        }
      }
    }
    div.catItemImageBlock {
      padding: 0;
      margin: 0;
    }

    .catItemTitle {
      margin-bottom: 12px;
      margin-top: 24px;
      letter-spacing: 1px;
      a {
        font-size: 24px;
        color: #120d14;
        text-transform: uppercase;
      }
    }
    .catItemLinks {
      margin-bottom: 4px;
      &.tz-top {
        * {
          color: rgba(18, 13, 20, 0.4);
          font-size: 20px;
          letter-spacing: 1px;
          text-transform: uppercase;
        }
        > * {
          border: none;
          padding-right: 0;
          padding-left: 0;
          margin: 0;
        }
      }
      > div {
        display: inline-block;
        &:after {
          content: '/';
        }
        &:last-child::after {
          content: none;
        }
      }
    }
    .catItemIntroText {
      font-size: 16px;
      font-weight: 300;
      padding: 20px 0 35px;
      color: #7c7c7c;
      line-height: 1.8em;
    }

    div.catItemRatingBlock {
      padding: 0;
      .itemRatingForm {
        float: right;
      }
      &:after {
        clear: both;
        display: block;
        content: '';
      }
    }
    div.catItemAttachmentsBlock {
      border: none;
      padding: 0;
      span {
        color: #9a9a9a;
        padding: 0;
        font-weight: 300;
      }
    }
  }
}

/* End Blog */

/* Blog Detail */
.itemView {
  div.itemImageBlock {
    margin: 0;
    padding: 0;
  }
  div.itemBody {
    margin: -80px 100px 0;
    position: relative;
    padding: 0;
    z-index: 1;
    .box-shadow(0 0 6px rgba(0, 0, 0, 0.09));
    .tz-top {
      padding: 55px 35px 60px;
      background: @white;
    }
    .itemTitle {
      margin: 0;
      letter-spacing: 1px;
      color: #120d14;
    }
    .tz-top-information {
      padding-top: 16px;
      > * {
        display: inline-block;
        letter-spacing: 1px;
        &:after {
          content: '/';
        }
        &:last-child::after {
          content: none;
        }
      }
      * {
        color: rgba(18, 13, 20, 0.4);
        text-transform: uppercase;
        font-size: 18px;
      }
    }
    .itemIntroText {
      font-size: 16px;
      p {
        font-size: 16px;
      }
    }
    div.itemFullText p {
      margin-bottom: 30px;
    }
  }
  .itemDateModified {
    color: #7c7c7c;
    padding: 15px 0;
  }

}

div.itemAttachmentsBlock ul.itemAttachments li {
  padding: 0;
}

div.itemNavigation {
  margin: 40px 100px 40px;
  padding: 0;
  .row > * {
    border-right: 1px solid rgba(37, 37, 37, 0.07);
    &:last-child {
      border: none;
    }
  }
  .tz-table-cell {
    vertical-align: top;
    &.tz-width-100 {
      width: 100%;
    }
    &.tz-previous-link {
      padding-left: 15px;
    }
    &.tz-next-link {
      padding-right: 15px;
    }
    img {
      width: 65px;
      height: 65px;
    }
    p {
      color: #9a9a9a;
      font-size: 13px;
      margin-bottom: 3px;
    }
    h5 {
      width: 60%;
      margin: 0;
      a.itemPrevious, a.itemNext {
        padding: 0;
        font-size: 18px;
        letter-spacing: 2px;
        color: #252525;
        line-height: 1.5em;
        &:hover {
          color: #daae81;
        }
      }
    }
  }
}

.task-user {
  div.itemAuthorBlock {
    margin: 0 0 30px 0;
  }
}

div.itemAuthorBlock {
  padding: 0;
  margin: 20px 100px 35px;
  border: none;
  img {
    .box-shadow(2px 0 7px rgba(0, 0, 0, 0.3));
  }
  div.itemAuthorDetails {
    background: @white;
    .box-shadow(0 0 6px rgba(0, 0, 0, 0.09));
    padding: 40px;
    h3.itemAuthorName {
      margin-top: -10px;
      margin-bottom: 15px;
      display: block;
      a {
        font-size: 17px;
        letter-spacing: 3px;
      }
    }
    a {
      color: #494949;
    }
    p {
      color: #9d9d9d;
    }
  }
}

div.itemAuthorLatest,
div.itemRelated {
  margin: 16px 100px 0;
  .tz-title {
    font-size: 100px;
    color: #dedede;
    margin-bottom: 0;
    display: block;
    margin-top: 0;
  }
  .items {
    margin-left: -10px;
    margin-right: -10px;
    > .item {
      padding: 0 0 35px 0;
      padding-left: 10px;
      padding-right: 10px;
    }
    .itemAuthorImg,
    .itemRelImg {
      max-width: 100%;
    }
    h4 {
      margin-top: 0;
      margin-bottom: 5px;
    }
    .itemAuthorTitle,
    .itemRelTitle {
      font-size: 17px;
      color: #252525;
      letter-spacing: 2px;
      line-height: 1.5em;
    }
    .itemRelDate {
      color: #9a9a9a;
      font-size: 12px;
      font-weight: 300;
      line-height: 1.8em;
    }
    .tz-info {
      margin: -35px 25px 0;
      background: @white;
      position: relative;
      z-index: 1;
      padding: 25px;
      .box-shadow(0 0 6px rgba(0, 0, 0, 0.09));
    }
  }
}

div.itemContentFooter {
  text-align: left;
  background: #f9f9f9;
  padding: 30px;
  border-top: 1px solid rgba(37, 37, 37, 0.07);
  margin: 0;
  span.itemHits {
    float: none;
  }
  .tz-information {
    overflow: hidden;
    > * {
      display: inline-block;
      margin: auto;
      width: auto;
      padding: 0;
      border: none;
      * {
        color: #9a9a9a;
        font-weight: 300;
      }
      a {
        color: #494949;
      }
      &:after {
        content: '|';
        margin: 0 10px;
        color: #9a9a9a;
        font-weight: 300;
      }
      &:last-child {
        &:after {
          content: none;
        }
      }
    }
    &.tz-right {
      > * {
        &:first-child {
          &:after {
            content: none;
          }
        }
        &:last-child {
          &:after {
            content: '|';
          }
        }
      }
    }
  }
  .itemRatingBlock {
    float: right;
  }
  .itemSocialSharing {
    float: right;
    > * {
      width: auto;
      display: inline-block;
      margin: auto 5px auto auto;
      iframe {
        width: auto !important;
      }
    }
    .itemGooglePlusOneButton,
    .itemTwitterButton {
      width: 60px
    }
  }
}

.itemView .itemCategory,
.itemListView.tz-default .catItemCategory {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 47px 28px 0;
  border-color: #ed6a5a transparent transparent transparent;
  position: relative;
  margin: auto;
  a {
    background: @white;
    left: -160px;
    min-width: 320px;
    padding: 3px 0;
    position: absolute;
    text-align: center;
    top: -39px;
    color: #ed6a5a;
    font-size: 14px;
    letter-spacing: 1px;
  }
}

div.itemComments {
  margin: 0 100px;
  border: none;
  padding: 0;
  background: transparent;
  ul.itemCommentsList li {
    border: none;
    padding: 30px 30px 15px;
    .box-shadow(0 0 6px rgba(0, 0, 0, 0.09));
    margin-bottom: 28px;
    img {
      padding: 0;
      margin: 0 20px 0 0;
    }
    .commentAuthorName {
      font-size: 17px;
      color: #494949;
      margin: -3px 0 10px;
      letter-spacing: 3px;
    }
    .commentDate {
      font-weight: 300;
      font-style: italic;
      font-size: 12px;
      color: #9a9a9a;
      margin-bottom: 8px;
    }
    p {
      padding: 0;
      color: #9d9d9d;
      font-weight: 300;
    }
    span.commentLink {
      a, a:hover {
        padding: 7px;
        font-size: 12px;
        font-weight: 300;
        color: @white;
        background: #ed6a5a;
        text-transform: uppercase;
        text-decoration: none;
      }
    }
  }
}

div.itemCommentsForm {
  background: @white;
  .box-shadow(0 0 6px rgba(0, 0, 0, 0.09));
  padding: 40px;
  margin-top: 100px;
  form {
    textarea.inputbox,
    input.inputbox {
      margin-bottom: 15px;
      border: 1px solid rgba(37, 37, 37, 0.1);
      .box-shadow(none);
      color: #9a9a9a;

    }
    textarea.inputbox {
      height: auto;
      padding: 10px 15px;
    }
    input.inputbox {
      line-height: 1.5em;
      margin-bottom: 21px;
      padding: 8px 15px;
      &:last-child {
        margin: 0;
      }
    }
    input#submitCommentButton {
      width: 100%;
      text-transform: uppercase;
      color: @white;
      letter-spacing: 2px;
      font-weight: normal;
      .border-radius(0);
      font-size: 14px;
      background: #ed6a5a;
      &:hover {
        background: #ed6a5a;
        color: @white;
      }
    }
  }
  .tz-title {
    font-size: 100px;
    color: #dedede;
    margin-top: -88px;
    padding: 0;
  }
}

/* End Blog Detail */